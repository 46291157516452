import { combineReducers } from 'redux';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import clientsReducer from './clientsReducer';
import productsReducer from './productsReducer';
import layersReducer from './layersReducer';
import printLayoutReducer from './printLayoutReducer';
import modulesReducer from './modulesReducer';

const entitiesReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  clients: clientsReducer,
  products: productsReducer,
  layers: layersReducer,
  printLayouts: printLayoutReducer,
  modules: modulesReducer
});

export default entitiesReducer;
