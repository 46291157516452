import initialState from '../../constants/initialState';

import { FetchAllPrintLayoutsAction, PrintLayoutAction } from '../../@types/Actions/PrintLayout.d';
import { FETCH_ALL_PRINT_LAYOUTS } from '../../constants/actionNames/printLayouts';
import { getDict, getIndicies } from '../../util/utils';
import { DictState, PrintLayout } from '../../@types/Common.d';

export default function printLayoutReducer(
  state = initialState.entities.printLayouts,
  action: PrintLayoutAction
): DictState<PrintLayout> {
  const { type } = action;

  if (type === FETCH_ALL_PRINT_LAYOUTS) {
    const castedAction = action as FetchAllPrintLayoutsAction;

    const byId = getDict<PrintLayout>(castedAction.printLayouts);
    const allIds = getIndicies<PrintLayout>(byId);

    return { ...state, ...{ allIds, byId, selectedItem: byId[allIds[0]] } };
  }

  return state;
}
