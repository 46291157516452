import {
  FETCH_CLIENT_LAYERS,
  ADD_CLIENT_LAYER,
  DELETE_CLIENT_LAYER
} from '../../constants/actionNames/clients/clientActions';

import { Layer } from '../../@types/Model/Map.d';
import {
  FetchClientLayersAction,
  AddClientLayerAction,
  DeleteClientLayerAction
} from '../../@types/Actions/Client/ClientLayer.d';

export const fetchClientLayersAction = (layers: Layer[]): FetchClientLayersAction => ({
  type: FETCH_CLIENT_LAYERS,
  layers
});

export const addClientLayerAction = (layer: Layer): AddClientLayerAction => ({
  type: ADD_CLIENT_LAYER,
  layer
});

export const deleteClientLayerAction = (layer: Layer): DeleteClientLayerAction => ({
  type: DELETE_CLIENT_LAYER,
  layer
});
