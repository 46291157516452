import initialState from '../../../constants/initialState';
import { REQUEST_FAILURE, HIDE_ERROR_MODAL } from '../../../constants/actionNames/ui';

import { RequestFailure } from '../../../@types/Actions/UI.d';
import { Action } from '../../../@types/Actions/Common.d';
import { RequestErrorModal } from '../../../@types/State.d';

export default function requestErrorModalReducer(
  state = initialState.ui.modals.requestError,
  action: Action
): RequestErrorModal {
  let payload;

  switch (action.type) {
    case REQUEST_FAILURE:
      payload = (action as RequestFailure).errorMessage;
      return { ...state, ...{ show: true, title: payload.title, content: payload.content } };
    case HIDE_ERROR_MODAL:
      return { ...state, ...{ show: false, title: undefined, content: undefined } };
    default:
      return state;
  }
}
