import {
  CREATE_CLIENT_DISTRIBUTION_ACTION,
  DELETE_CLIENT_DISTRIBUTION_ACTION,
  FETCH_ALL_CLIENT_DISTRIBUTION_ACTIONS,
  FETCH_CLIENT_DISTRIBUTION_ACTION,
  SELECT_CLIENT_DISTRIBUTION_ACTION,
  UPDATE_CLIENT_DISTRIBUTION_ACTION
} from '../../constants/actionNames/clients/clientDistributionAppointments';

import {
  AddClientDistributionAppointmentAction,
  FetchAllClientDistributionAppointmentsAction,
  FetchClientDistributionAppointmentAction,
  UpdateClientDistributionAppointmentAction,
  DeleteClientDistributionAppointmentAction,
  SelectClientDistributionAppointmentAction
} from '../../@types/Actions/Client/ClientDistributionAppointments.d';
import { DistributionAppointment } from '../../@types/Model/Distribution.d';

export const fetchAllClientDistributionAppointmentsAction = (
  distributionAppointments: DistributionAppointment[]
): FetchAllClientDistributionAppointmentsAction => ({
  type: FETCH_ALL_CLIENT_DISTRIBUTION_ACTIONS,
  distributionAppointments
});

export const fetchClientDistributionAppointmentAction = (
  distributionAppointment: DistributionAppointment
): FetchClientDistributionAppointmentAction => ({
  type: FETCH_CLIENT_DISTRIBUTION_ACTION,
  distributionAppointment
});

export const addClientDistributionAppointmentAction = (
  distributionAppointment: DistributionAppointment
): AddClientDistributionAppointmentAction => ({
  type: CREATE_CLIENT_DISTRIBUTION_ACTION,
  distributionAppointment
});

export const deleteClientDistributionAppointmentAction = (
  distributionAppointmentId: number
): DeleteClientDistributionAppointmentAction => ({
  type: DELETE_CLIENT_DISTRIBUTION_ACTION,
  distributionAppointmentId
});

export const updateClientDistributionAppointmentAction = (
  distributionAppointment: DistributionAppointment
): UpdateClientDistributionAppointmentAction => ({
  type: UPDATE_CLIENT_DISTRIBUTION_ACTION,
  distributionAppointment
});
export const selectClientDistributionAppointmentAction = (
  distributionAppointment: DistributionAppointment
): SelectClientDistributionAppointmentAction => ({
  type: SELECT_CLIENT_DISTRIBUTION_ACTION,
  distributionAppointment
});
