import * as React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import ClientModulesList from './ClientModulesList';

import { getClientModules, getModules, updateClientModule } from '../../../../util/api/clientsApi';

import { ClientModulesActions } from '../../../../@types/Actions/Client/ClientModules.d';
import { RequestAction } from '../../../../@types/Actions/UI.d';
import { ClientModulesProps, ClientModulesState } from '../../../../@types/Clients.d';
import { Module } from '../../../../@types/Common.d';
import { GlobalState } from '../../../../@types/State.d';
import { Client } from '../../../../@types/Model/Client.d';

class ClientDetailsModules extends React.Component<ClientModulesProps, ClientModulesState> {
  constructor(props: ClientModulesProps) {
    super(props);

    this.updateModule = this.updateModule.bind(this);
  }

  componentDidMount(): void {
    const { fetchAllClientModules, fetchAllModules, client } = this.props;

    if (client) {
      fetchAllClientModules();
      fetchAllModules();
    }
  }

  componentDidUpdate(prevProps: ClientModulesProps): void {
    const { client, fetchAllClientModules } = this.props;

    if (!client) return;

    if (client.id !== prevProps.client?.id) fetchAllClientModules();
  }

  updateModule(module: Module): void {
    const { client, clientModuleUpdate } = this.props;

    clientModuleUpdate(client, module);
  }

  render(): JSX.Element {
    const { client, modules } = this.props;

    return (
      <div className="client-modules-container">
        <ClientModulesList
          modules={modules?.allIds.map((id) =>
            client?.modules?.byId[id]?.enabled ? client?.modules?.byId[id] : modules.byId[id]
          )}
          updateClientModule={this.updateModule}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): Pick<ClientModulesProps, 'client' | 'modules'> => ({
  client: state.entities.clients.selectedItem,
  modules: state.entities.modules
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, RequestAction | ClientModulesActions>
): Pick<
  ClientModulesProps,
  'fetchAllClientModules' | 'clientModuleUpdate' | 'fetchAllModules'
> => ({
  fetchAllClientModules: () => dispatch(getClientModules()),
  fetchAllModules: () => dispatch(getModules()),
  clientModuleUpdate: (client: Client, module: Module) =>
    dispatch(updateClientModule(client, module))
});

const ClientModulesContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientDetailsModules)
);
export default ClientModulesContainer;
