import * as React from 'react';

import { map } from 'lodash';
import { ListGroup } from 'react-bootstrap';

import AddRemoveListItem from './AddRemoveListItem';
import ListSearch from '../ListSearch';

import { AddRemoveListPorps, AddRemoveListState } from '../../../@types/App.d';

export default class AddRemoveList extends React.Component<AddRemoveListPorps, AddRemoveListState> {
  constructor(props: AddRemoveListPorps) {
    super(props);

    const { items } = this.props;

    this.state = {
      filteredItems: items,
      filterString: ''
    };

    this.filterList = this.filterList.bind(this);
  }

  componentDidUpdate(prevProps: AddRemoveListPorps): void {
    const { items, hasChanged } = this.props;

    if (prevProps.hasChanged !== hasChanged) {
      if (hasChanged) {
        const { filterString } = this.state;
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ filteredItems: items }, () => this.filterList(filterString));
      }
    }
  }

  filterList(pFilterString: string): void {
    this.setState(
      {
        filterString: pFilterString
      },
      () => {
        const { items } = this.props;
        const { filterString } = this.state;
        let filteredItems;
        if (filterString.length <= 0) filteredItems = items;
        else {
          const searchStringRegex = map(filterString.split(''), (s) => `[${s}]\\w*`).join('');
          const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
          filteredItems = items.filter(
            ({ title, subtitle }) => title.match(regex) || subtitle?.match(regex)
          );
        }

        this.setState({ filteredItems });
      }
    );
  }

  render(): JSX.Element {
    const { notAssigned, searchHint, itemAdd, itemDelete } = this.props;
    const { filteredItems, filterString } = this.state;

    return (
      <>
        <ListSearch
          filterList={this.filterList}
          searchHint={searchHint}
          searchString={filterString}
        />
        <ListGroup className="add-remove-list selectable">
          {filteredItems.length > 0 ? (
            map(filteredItems, (item) => (
              <AddRemoveListItem
                key={item.id}
                item={item}
                notAssigned={notAssigned}
                itemAdd={itemAdd}
                itemDelete={itemDelete}
              />
            ))
          ) : (
            <div />
          )}
        </ListGroup>
      </>
    );
  }
}
