import * as React from 'react';

import { Link } from 'react-router-dom';

import { DASHBOARD_GOTO_TEXT } from '../../constants/labels';

import { DashboardButtonProps } from '../../@types/Dashboad.d';

const DashboardButton: React.FC<DashboardButtonProps> = (props: DashboardButtonProps) => {
  const { availableFunctionality } = props;

  return (
    <div className="dashboard-button">
      <div className="title">{availableFunctionality.title}</div>
      <div className="description">{availableFunctionality.description}</div>
      <div className="separator" />
      <div className="footer">
        <Link to={`/${availableFunctionality.path}`}>
          {DASHBOARD_GOTO_TEXT(availableFunctionality.title)}
        </Link>
      </div>
    </div>
  );
};

export default DashboardButton;
