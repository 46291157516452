import { map, reduce, sortBy, values, forEach } from 'lodash';
import { RGBColor } from 'react-color';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { EXPORT_CLIENT_LOCATIONS_HEADER_ARRAY, DATA_FORMAT_CSV } from '../constants/constants';

import { Dict, DictObject, AreaSendFormat } from '../@types/Common.d';
import { ClientLocation, OpeningHours, OpeningHoursSend } from '../@types/Model/Client.d';
import { DistributionTemplateLocation } from '../@types/Model/Distribution.d';
import { POI } from '../@types/Model/Map.d';

export const getDict = <T extends DictObject>(array: T[]): Dict<T> =>
  reduce(
    array,
    (acc: Dict<T>, entry: T) => {
      acc[entry.id] = entry;
      return acc as Dict<T>;
    },
    {} as Dict<T>
  );

export const getIndicies = <T extends DictObject>(dict: Dict<T>): number[] =>
  map(
    sortBy(values(dict), (entry: T) => entry.sortProperty.toLowerCase()),
    (value: T) => value.id
  );

export const insertSorted = <T extends DictObject>(
  dict: Dict<T>,
  ids: number[],
  newEntry: T
): number[] => {
  if (ids.length === 0) return [newEntry.id];
  forEach(ids, (id: number, index: number) => {
    if (dict[id].sortProperty.toLowerCase() > newEntry.sortProperty.toLowerCase()) {
      ids.splice(index, 0, newEntry.id);
      return false;
    }
    if (index === ids.length - 1) ids.push(newEntry.id);

    return true;
  });

  return ids;
};

export const insertSortedArray = <T extends DictObject>(
  dict: Dict<T>,
  ids: number[],
  newEntries: T[]
): number[] => {
  // eslint-disable-next-line no-return-assign, no-param-reassign
  forEach(newEntries, (newEntry) => (ids = insertSorted(dict, ids, newEntry)));

  return ids;
};

// rgb[0] = r, rgb[1] = g, rgb[2] = b, rgb[3] = a (if present)
export const rgbObjectTorgb = (colorObject: RGBColor, opacity?: number): string =>
  `rgba(${colorObject.r},${colorObject.g},${colorObject.b},${opacity || colorObject.a})`;

// rgb[0] = r, rgb[1] = g, rgb[2] = b, rgb[3] = a (if present)
export const rgbTorgbObject = (colorRGB: string, opacity: number = 1): RGBColor => {
  const rgb = colorRGB.match(/\d+|(\.\d*)/g);
  if (rgb !== null) return { r: +rgb[0], g: +rgb[1], b: +rgb[2], a: opacity } as RGBColor;
  return { r: 0, g: 0, b: 0, a: 0 } as RGBColor;
};

// rgb[0] = r, rgb[1] = g, rgb[2] = b, rgb[3] = a (if present)
export const rgbTorgba = (colorRGB: string, opacity: number = 1): string => {
  const rgb = colorRGB.match(/\d+|(\.\d*)/g);
  if (rgb !== null) return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;
  return colorRGB;
};

const generateDownload = (data: any, clientName = 'csv_export', format = DATA_FORMAT_CSV): void => {
  saveAs(data, `${clientName.replace(/ /g, '_')}_${moment().format('DDMMYYYY-HHmmss')}.${format}`);
};

const getClientLocationExportFormat = ({
  addressName,
  city,
  colorSelectedFill,
  housenumber,
  lat,
  lon,
  name,
  number,
  postcode,
  web,
  fax,
  street,
  planable,
  poi
}: ClientLocation): (string | boolean | POI | undefined)[] => [
  addressName,
  name,
  number,
  web,
  fax,
  postcode,
  city,
  street,
  housenumber,
  lat,
  lon,
  colorSelectedFill.replace(/,/g, ','),
  planable,
  poi
];

const getClientLocationsExportFormat = (
  clientLocations: ClientLocation[]
): (string | boolean | POI | undefined)[][] =>
  clientLocations.map((clientLocation) => getClientLocationExportFormat(clientLocation));

export const exportClientLocationsCSV = (
  clientLocations: ClientLocation[],
  clientName?: string
): void => {
  const items = getClientLocationsExportFormat(clientLocations);
  items.unshift(EXPORT_CLIENT_LOCATIONS_HEADER_ARRAY);

  const csvContent = `data:text/csv;charset=utf-8,${items
    .map((item) => item.join(';'))
    .join('\n')}`;
  generateDownload(csvContent, clientName);
};

export const getAreaCirculation = (area: AreaSendFormat): number =>
  area.localities.reduce((acc, locality) => acc + locality.circulation, 0);

export const getAreasCirculation = (areas: AreaSendFormat[]): number =>
  areas.reduce((acc, area) => acc + getAreaCirculation(area), 0);

export const getClientLocationCirculation = (
  clientLocation: DistributionTemplateLocation
): number => getAreasCirculation(clientLocation.areas);

export const getClientLocationsCirculation = (
  clientLocations: DistributionTemplateLocation[]
): number =>
  clientLocations.reduce(
    (acc, clientLocation) => acc + getClientLocationCirculation(clientLocation),
    0
  );

/**
 * Converts the opening hours object into an array
 *
 * @param openingHours
 */
export const getOpeningHoursSend = (openingHours: OpeningHours): OpeningHoursSend[] =>
  Object.keys(openingHours).reduce((acc, day) => {
    const openingHour = openingHours[day];

    if (!openingHour) return acc;

    const { morningFrom, morningTo, noonFrom, noonTo, continuouslyOpen } = openingHour;

    return [...acc, ...[{ morningFrom, morningTo, noonFrom, noonTo, continuouslyOpen, day }]];
  }, [] as OpeningHoursSend[]);

export const getOpeningHours = (openingHoursSend?: OpeningHoursSend[]): OpeningHours =>
  openingHoursSend?.reduce((acc, openingHourSend) => {
    const { id, morningFrom, morningTo, noonFrom, noonTo, day, continuouslyOpen } = openingHourSend;
    acc[day] = {
      id,
      morningFrom,
      morningTo,
      noonFrom,
      noonTo,
      continuouslyOpen
    };

    return acc;
  }, {} as OpeningHours) ?? {};
