import { ACTION_STARTED, ACTION_SUCCESS, ACTION_FAILURE } from '../constants';

export const REQUEST_STARTED = `REQUEST_${ACTION_STARTED}`;
export const REQUEST_SUCCESS = `REQUEST_${ACTION_SUCCESS}`;
export const REQUEST_FAILURE = `REQUEST_${ACTION_FAILURE}`;

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';

export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATIOn_MODAL';
export const HIDE_CONFIRMATION_MODAL = 'HIDE_CONFIRMATIOn_MODAL';

export const SHOW_CLIENT_LOCATION_MODAL = 'SHOW_CLIENT_LOCATION_MODAL';
export const HIDE_CLIENT_LOCATION_MODAL = 'HIDE_CLIENT_LOCATION_MODAL';

export const SHOW_CHANGE_PASSWORD_MODAL = 'SHOW_CHANGE_PASSWORD_MODAL';
export const HIDE_CHANGE_PASSWORD_MODAL = 'HIDE_CHANGE_PASSWORD_MODAL';
