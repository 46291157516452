import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, OverlayTrigger, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { deleteClientLocation, updateClientLocation } from '../../../../util/api/clientLocationApi';
import { rgbTorgba } from '../../../../util/utils';
import {
  DELETE_CONFIRMATION_MODAL_TITLE,
  DELETE_CONFIRMATION_MODAL_CONTENT,
  TOOLTIP_WARN_DATA_INCOMPLETE
} from '../../../../constants/labels';
import { showClientLocationModal, showConfirmationModal } from '../../../../actions/uiActions';

import { ClientLocationCellProps } from '../../../../@types/Clients.d';
import { GlobalState } from '../../../../@types/State.d';
import { ClientLocation, Client } from '../../../../@types/Model/Client.d';
import { ConfirmationMessage } from '../../../../@types/Model/Message.d';
import { ClientLocationModalAction } from '../../../../@types/Actions/UI.d';
import { ClientLocationsAction } from '../../../../@types/Actions/Client/ClientLocation.d';

import POI from '../../../../resources/img/POI.png';

const ClientLocationCell: React.FC<ClientLocationCellProps> = (props: ClientLocationCellProps) => {
  const {
    client,
    clientLocation,
    selected,
    changeBillingDefault,
    clientLocationModalShow,
    clientLocationDelete,
    showConfirmation,
    addToMultiSelect,
    removeFromMultiSelect,
    clientLocationUpdate
  } = props;

  const {
    lat,
    lon,
    name,
    number,
    addressName,
    city,
    street,
    housenumber,
    postcode,
    colorSelectedFill,
    planable,
    billingDefault,
    poi
  } = client?.clientLocations.byId[clientLocation.id] ?? clientLocation;

  const rowValid =
    (lat.length &&
      lon.length &&
      name &&
      addressName &&
      city &&
      street &&
      housenumber &&
      postcode) !== 0;

  const removeClientLocation = (): void => {
    clientLocationDelete(client, clientLocation);
  };

  const onClickEdit = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    clientLocationModalShow(client?.clientLocations.byId[clientLocation.id] ?? clientLocation);
  };

  const onKeyPressEdit = (event: React.KeyboardEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (event.key === 'Enter') clientLocationModalShow(clientLocation);
  };
  const onClickRemove = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    showConfirmation({
      title: DELETE_CONFIRMATION_MODAL_TITLE,
      content: DELETE_CONFIRMATION_MODAL_CONTENT,
      confirmationCallback: removeClientLocation
    });
  };

  const onKeyPressRemove = (event: React.KeyboardEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (event.keyCode === 13)
      showConfirmation({
        title: DELETE_CONFIRMATION_MODAL_TITLE,
        content: DELETE_CONFIRMATION_MODAL_CONTENT,
        confirmationCallback: removeClientLocation
      });
  };

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.currentTarget.checked) removeFromMultiSelect(clientLocation.id);
    else addToMultiSelect(clientLocation.id);
  };

  const onChangePlanable = (event: React.ChangeEvent<HTMLInputElement>): void =>
    clientLocationUpdate(client, {
      ...clientLocation,
      ...{ planable: event.currentTarget.checked }
    });

  const onChangeDefaultLocation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    changeBillingDefault(clientLocation);
  };

  return (
    <tr>
      <td>
        <Form.Check
          custom
          type="checkbox"
          checked={selected}
          id={`clientlocation-${clientLocation.id}`}
          label=""
          onChange={onChangeSelect}
        />
      </td>
      <td>
        {!rowValid && (
          <OverlayTrigger
            delay={{ show: 500, hide: 500 }}
            placement="top"
            overlay={<div className="custom-tooltip">{TOOLTIP_WARN_DATA_INCOMPLETE(lat, lon)}</div>}
          >
            <div key={clientLocation.id} className="icon-warn">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
          </OverlayTrigger>
        )}
      </td>
      <td>
        {poi.active ? (
          <img src={poi.active} alt={poi.name} />
        ) : (
          // <img src={require('../../../../resources/img/POI.png')} alt="icon" />
          <img src={POI} alt="icon" />
        )}
      </td>
      <td>{name}</td>
      <td>{`${postcode} ${city}, ${street} ${housenumber}`}</td>
      <td>{number}</td>
      <td>
        <div
          style={{
            background: rgbTorgba(colorSelectedFill, 1),
            height: 30,
            width: '50%'
          }}
        />
      </td>
      {client?.billingType !== 'CLIENT' && (
        <td>
          <Form.Check
            custom
            type="checkbox"
            checked={billingDefault}
            id={`clientlocation-default-location-check-${clientLocation.id}`}
            label=""
            onChange={onChangeDefaultLocation}
            readOnly
          />
        </td>
      )}
      <td>
        <Form.Check
          custom
          type="checkbox"
          checked={planable}
          id={`clientlocation-planable-check-${clientLocation.id}`}
          label=""
          onChange={onChangePlanable}
          readOnly
        />
      </td>
      <td>
        <Row className="no-gutters justify-content-center">
          <Col className="icon-container">
            <div
              role="button"
              className="hover-icon"
              tabIndex={0}
              onClick={onClickEdit}
              onKeyUp={onKeyPressEdit}
            >
              <FontAwesomeIcon icon={faEdit} />
            </div>
          </Col>
          <Col className="icon-container">
            <div
              role="button"
              className="hover-icon remove"
              tabIndex={0}
              onClick={onClickRemove}
              onKeyUp={onKeyPressRemove}
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </Col>
        </Row>
      </td>
    </tr>
  );
};

const mapStateToProps = (state: GlobalState): Pick<ClientLocationCellProps, 'client'> => ({
  client: state.entities.clients.selectedItem
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, ClientLocationsAction | ClientLocationModalAction>
): Pick<
  ClientLocationCellProps,
  | 'clientLocationShow'
  | 'clientLocationDelete'
  | 'clientLocationModalShow'
  | 'showConfirmation'
  | 'clientLocationUpdate'
> => ({
  clientLocationShow: () => {},
  clientLocationDelete: (client: Client, clientLocation: ClientLocation) =>
    dispatch(deleteClientLocation(client, clientLocation)),
  clientLocationModalShow: (clientLocation: ClientLocation) =>
    dispatch(showClientLocationModal(clientLocation)),
  showConfirmation: (confirmationMessage: ConfirmationMessage) =>
    dispatch(showConfirmationModal(confirmationMessage)),
  clientLocationUpdate: (client: Client, clientLocation: ClientLocation) =>
    dispatch(updateClientLocation(client, clientLocation))
});

const ClientLocationCellContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientLocationCell)
);

export default ClientLocationCellContainer;
