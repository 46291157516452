import * as React from 'react';

import UsersDetailsUserContainer from './UsersDetails/UsersDetailsUser';

import { USERS_NEW } from '../../constants/labels';

import { UsersNewProps } from '../../@types/Users.d';

const UsersNew: React.FC<UsersNewProps> = () => (
  <div>
    <div className="title">{USERS_NEW}</div>
    <div className="tab-container">
      <UsersDetailsUserContainer />
    </div>
  </div>
);

export default UsersNew;
