import * as React from 'react';

import { Form, Row, Col, FormControl } from 'react-bootstrap';

import {
  PRODUCT_DETAILS_NAME_LABEL,
  PRODUCT_DETAILS_NAME_PLACEHOLDER,
  PRODUCT_DETAILS_NAME_INIVALID,
  PRODUCT_DETAILS_DESCRIPTION_LABEL,
  PRODUCT_DETAILS_DESCRIPTION_PLACEHOLDER,
  PRODUCT_DETAILS_PRINT_DOC_REQUIRED_LABEL
} from '../../../constants/labels';

import { ProductDetailsFormProps } from '../../../@types/Products.d';

const ProductDetailsForm: React.FC<ProductDetailsFormProps> = (props: ProductDetailsFormProps) => {
  const {
    name,
    descritpion,
    printDocRequired,
    changeName,
    changeDescription,
    changePrintDocRequired
  } = props;

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    changeName(event.currentTarget.value ?? '');
  };

  const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>): void => {
    changeDescription(event.currentTarget.value ?? '');
  };

  const onChangePrintDocRequired = (event: React.ChangeEvent<HTMLInputElement>): void => {
    changePrintDocRequired(event.currentTarget.checked);
  };

  return (
    <Row className="no-gutters">
      <Col>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{PRODUCT_DETAILS_NAME_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={name}
                onChange={onChangeName}
                placeholder={PRODUCT_DETAILS_NAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {PRODUCT_DETAILS_NAME_INIVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{PRODUCT_DETAILS_DESCRIPTION_LABEL}</Form.Label>
              <FormControl
                as="textarea"
                rows={4}
                maxLength={255}
                autoComplete="new-password"
                type="text"
                value={descritpion}
                onChange={onChangeDescription}
                placeholder={PRODUCT_DETAILS_DESCRIPTION_PLACEHOLDER}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Check
                custom
                type="checkbox"
                checked={printDocRequired}
                id="print-doc-required-check"
                label={PRODUCT_DETAILS_PRINT_DOC_REQUIRED_LABEL}
                onChange={onChangePrintDocRequired}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Col>
    </Row>
  );
};

export default ProductDetailsForm;
