import * as React from 'react';

import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { difference, map } from 'lodash';
import { withRouter } from 'react-router-dom';

import AddRemoveList from '../../../Common/AddRemoveList/AddRemoveList';

import { getAllProducts } from '../../../../util/api/productApi';
import {
  getClientProducts,
  addClientProduct,
  deleteClientProduct
} from '../../../../util/api/clientProductApi';

import {
  CLIENT_PRODUCTS_ASSIGNED_USERS,
  CLIENT_PRODUCTS_NOT_ASSIGNED_USERS,
  LIST_SEARCH_HINT_PRODUCTS
} from '../../../../constants/labels';

import { ClientProductsProps, ClientProductsState } from '../../../../@types/Clients.d';
import { ListItem } from '../../../../@types/Common.d';
import { GlobalState } from '../../../../@types/State.d';
import { Client } from '../../../../@types/Model/Client.d';
import { Product } from '../../../../@types/Model/Product.d';
import { RequestAction } from '../../../../@types/Actions/UI.d';
import { ProductAction } from '../../../../@types/Actions/Product.d';

class ClientsDetailsProducts extends React.Component<ClientProductsProps, ClientProductsState> {
  constructor(props: ClientProductsProps) {
    super(props);

    this.state = {
      hasChanged: false,
      notAssignedProducts: [],
      init: true
    };

    this.filterProducts = this.filterProducts.bind(this);
    this.addClientPorduct = this.addClientPorduct.bind(this);
    this.deleteClientPorduct = this.deleteClientPorduct.bind(this);
  }

  componentDidMount(): void {
    const { productsFetch, clientProductsFetch, client } = this.props;

    if (!client) return;

    productsFetch();
    clientProductsFetch(client);
  }

  componentDidUpdate(prevProps: ClientProductsProps): void {
    const { productsFetch, clientProductsFetch, client, products } = this.props;
    const { hasChanged, init } = this.state;

    if (!client) return;

    if (client?.id !== prevProps.client?.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ init: true }, () => {
        productsFetch(client);
        clientProductsFetch(client);
      });
    } else if (
      JSON.stringify(client?.products.allIds) !==
        JSON.stringify(prevProps.client?.products.allIds) ||
      JSON.stringify(products.allIds) !== JSON.stringify(prevProps.products.allIds) ||
      init
    ) {
      this.filterProducts();
    } else if (
      JSON.stringify(client?.products.allIds) ===
        JSON.stringify(prevProps.client?.products.allIds) &&
      JSON.stringify(products.allIds) === JSON.stringify(prevProps.products.allIds) &&
      client.id === prevProps.client?.id &&
      hasChanged
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasChanged: false,
        init: false
      });
    }
  }

  filterProducts(): void {
    const { client, products } = this.props;

    if (!client?.products) return;

    const notAssignedProductsIds = difference(products.allIds, client.products.allIds);
    const notAssignedProducts = map(
      notAssignedProductsIds,
      (id: number) => products.byId[id]
    ) as Product[];

    this.setState({ notAssignedProducts, hasChanged: true, init: false });
  }

  addClientPorduct(productId: number): void {
    const { client, products, clientProductAdd } = this.props;

    clientProductAdd(client, products.byId[productId]);
  }

  deleteClientPorduct(productId: number): void {
    const { client, products, clientProductDelete } = this.props;

    clientProductDelete(client, products.byId[productId]);
  }

  render(): JSX.Element {
    const { client } = this.props;
    const { notAssignedProducts, hasChanged } = this.state;

    return (
      <div className="h-100 d-flex flex-column">
        <Row className="client-relations-container">
          {client ? (
            <>
              <Col sm={6} className="client-relation-list-col">
                <Row className="no-gutters">
                  <Col className="section-header">{CLIENT_PRODUCTS_NOT_ASSIGNED_USERS}</Col>
                </Row>
                <Row className="client-relation-list-row">
                  <Col className="p-0 h-100 d-flex flex-column">
                    <AddRemoveList
                      items={map(notAssignedProducts, (product) => {
                        const { id, name, description } = product;
                        return { id, title: name, subtitle: description } as ListItem;
                      })}
                      notAssigned
                      hasChanged={hasChanged}
                      searchHint={LIST_SEARCH_HINT_PRODUCTS}
                      itemAdd={this.addClientPorduct}
                      itemDelete={this.deleteClientPorduct}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6} className="client-relation-list-col">
                <Row className="no-gutters">
                  <Col className="section-header">{CLIENT_PRODUCTS_ASSIGNED_USERS}</Col>
                </Row>
                <Row className="client-relation-list-row">
                  <Col className="p-0 h-100 d-flex flex-column">
                    <AddRemoveList
                      items={map(client?.products.allIds, (productId: number) => {
                        const { id, name, description } = client?.products.byId[productId];
                        return { id, title: name, subtitle: description } as ListItem;
                      })}
                      hasChanged={hasChanged}
                      searchHint={LIST_SEARCH_HINT_PRODUCTS}
                      itemAdd={this.addClientPorduct}
                      itemDelete={this.deleteClientPorduct}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Col />
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): Pick<ClientProductsProps, 'client' | 'products'> => ({
  client: state.entities.clients.selectedItem,
  products: state.entities.products
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, RequestAction | ProductAction>
): Pick<
  ClientProductsProps,
  'productsFetch' | 'clientProductsFetch' | 'clientProductAdd' | 'clientProductDelete'
> => ({
  productsFetch: () => dispatch(getAllProducts()),
  clientProductsFetch: (client: Client) => dispatch(getClientProducts(client)),
  clientProductAdd: (client: Client, product: Product) =>
    dispatch(addClientProduct(client, product)),
  clientProductDelete: (client: Client, product: Product) =>
    dispatch(deleteClientProduct(client, product))
});

const ClientsDetailsProductsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsDetailsProducts)
);
export default ClientsDetailsProductsContainer;
