import { Dispatch } from 'redux';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';
import {
  fetchClientLayersAction,
  addClientLayerAction,
  deleteClientLayerAction
} from '../../actions/client/clientLayerActions';

import { parseLayers } from '../responseUtil/layerResponseUtil';

import { axiosClient } from '../../constants/constants';
import {
  PATH_GET_CLIENT_LAYERS,
  PATH_ADD_CLIENT_LAYER,
  PATH_DELETE_CLIENT_LAYER
} from '../../constants/network';
import {
  FETCH_CLIENT_LAYERS,
  ADD_CLIENT_LAYER,
  DELETE_CLIENT_LAYER
} from '../../constants/actionNames/clients/clientActions';
import {
  fetchClientLayersErrorTitle,
  fetchClientLayersErrorContent,
  addClientLayerErrorTitle,
  addClientLayerErrorContent,
  deleteClientLayerErrorTitle,
  deleteClientLayerErrorContent
} from '../../constants/errorMessages';

import { Client } from '../../@types/Model/Client.d';
import { Layer } from '../../@types/Model/Map.d';
import {
  AddClientLayerAction,
  DeleteClientLayerAction,
  FetchClientLayersAction
} from '../../@types/Actions/Client/ClientLayer.d';
import { RequestFailure } from '../../@types/Actions/UI.d';

export const getClientLayers = (client: Client) => async (
  dispatch: Dispatch
): Promise<FetchClientLayersAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_CLIENT_LAYERS));

  const res = await axiosClient
    .get(PATH_GET_CLIENT_LAYERS(client.uuid), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_CLIENT_LAYERS));
    return dispatch(fetchClientLayersAction(parseLayers(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_CLIENT_LAYERS, {
      title: fetchClientLayersErrorTitle,
      content: fetchClientLayersErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const addClientLayer = (client: Client, layer: Layer) => async (
  dispatch: Dispatch
): Promise<AddClientLayerAction | RequestFailure> => {
  dispatch(requestStarted(ADD_CLIENT_LAYER));

  const res = await axiosClient
    .put(PATH_ADD_CLIENT_LAYER(client.uuid, layer.id), {})
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(ADD_CLIENT_LAYER));
    return dispatch(addClientLayerAction(layer));
  }

  return dispatch(
    requestFailure(ADD_CLIENT_LAYER, {
      title: addClientLayerErrorTitle,
      content: addClientLayerErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteClientLayer = (client: Client, layer: Layer) => async (
  dispatch: Dispatch
): Promise<DeleteClientLayerAction | RequestFailure> => {
  dispatch(requestStarted(DELETE_CLIENT_LAYER));

  const res = await axiosClient
    .delete(PATH_DELETE_CLIENT_LAYER(client.uuid, layer.id))
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_CLIENT_LAYER));
    return dispatch(deleteClientLayerAction(layer));
  }

  return dispatch(
    requestFailure(DELETE_CLIENT_LAYER, {
      title: deleteClientLayerErrorTitle,
      content: deleteClientLayerErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};
