import initialState from '../../constants/initialState';
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_STARTED,
  LOGOUT_FAILURE
} from '../../constants/actionNames/user';

import { UserAction, LoginSuccessAction } from '../../@types/Actions/User.d';
import { User } from '../../@types/Model/User.d';

export default function userReducer(
  state = initialState.entities.user,
  action: UserAction
): User | null {
  const { type } = action;
  if (type === LOGIN_SUCCESS) {
    return (action as LoginSuccessAction).user;
  }
  if (type === LOGOUT_STARTED || LOGOUT_SUCCESS || LOGOUT_FAILURE) {
    return null;
  }

  return state;
}
