import * as React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { hideErrorModal } from '../../actions/uiActions';

import { BUTTON_OK } from '../../constants/labels';

import { RequestErrorModalProps } from '../../@types/Modal.d';
import { GlobalState } from '../../@types/State.d';
import { RequestAction } from '../../@types/Actions/UI.d';

const RequestErrorModal: React.FC<RequestErrorModalProps> = (props: RequestErrorModalProps) => {
  const { title, content, show, hideModal } = props;

  const onHide = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    event.stopPropagation();

    hideModal();
  };

  return (
    <Modal show={show} onHide={() => hideModal('')}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{BUTTON_OK}</Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (
  state: GlobalState
): Pick<RequestErrorModalProps, 'show' | 'title' | 'content'> => ({
  show: state.ui.modals.requestError.show,
  title: state.ui.modals.requestError.title,
  content: state.ui.modals.requestError.content
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, RequestAction>
): Pick<RequestErrorModalProps, 'hideModal'> => ({
  hideModal: (payload: string) => dispatch(hideErrorModal(payload))
});

const RequestErrorModalContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestErrorModal)
);

export default RequestErrorModalContainer;
