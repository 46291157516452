import { PrintLayout } from '../../@types/Common.d';

export function extractPrintLayout(responsePrintLayout: any): PrintLayout {
  const { name, id } = responsePrintLayout;

  return { id, name, sortProperty: name } as PrintLayout;
}

export function extractPrintLayouts(responsePrintLayouts: any): PrintLayout[] {
  return responsePrintLayouts
    .map((responsePrintLayout: any) => extractPrintLayout(responsePrintLayout))
    .sort((a: PrintLayout, b: PrintLayout) => {
      if (a.name < b.name) return 1;
      if (a.name > b.name) return -1;
      return 0;
    });
}
