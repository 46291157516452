export const URL_OPEN_CAGE = 'https://api.opencagedata.com/geocode/v1/geojson';

export const PATH_LOGIN = 'login';
export const PATH_LOGOUT = 'logout';
export const PATH_REFRESH = 'token/refresh';
export const PATH_CHANGE_PASSWORD = 'changePassword';
export const PATH_USERCLIENTS = 'userclients';

export const PATH_ALL_CLIENTS = 'clients';
export const PATH_GET_CLIENT = (clientUUID: string): string => `client/${clientUUID}`;
export const PATH_CREATE_CLIENT = 'client/';
export const PATH_UPDATE_CLIENT = 'client/';
export const PATH_DELETE_CLIENT = (clientUUID: string): string => `client/${clientUUID}`;

export const PATH_GET_CLIENT_USERS = (clientUUID: string): string => `client/${clientUUID}/users`;
export const PATH_ADD_CLIENT_USER = (clientUUID: string, userUUId: string): string =>
  `client/${clientUUID}/user/${userUUId}`;
export const PATH_DELETE_CLIENT_USER = (clientUUID: string, userUUId: string): string =>
  `client/${clientUUID}/user/${userUUId}`;

export const PATH_GET_CLIENT_OFFER_ORDER = (clientUUID: string, order: boolean): string =>
  `client/${clientUUID}/${order ? 'offers' : 'orders'}`;
export const PATH_GET_CLIENT_OFFER_ORDER_ITEM = (
  clientUUID: string,
  order: boolean,
  offerOrderId: number
): string => `client/${clientUUID}/${order ? 'offer' : 'order'}/${offerOrderId}`;

export const PATH_GET_CLIENT_LAYERS = (clientUUID: string): string =>
  `client/${clientUUID}/arealayers`;
export const PATH_ADD_CLIENT_LAYER = (clientUUID: string, layerId: number): string =>
  `client/${clientUUID}/arealayer/${layerId}`;
export const PATH_DELETE_CLIENT_LAYER = (clientUUID: string, layerId: number): string =>
  `client/${clientUUID}/arealayer/${layerId}`;

export const PATH_ALL_PRODUCTS = `products`;
export const PATH_GET_PRODUCT = (productId: number): string => `product/${productId}`;
export const PATH_CREATE_PRODUCT = 'product/';
export const PATH_UPDATE_PRODUCT = 'product/';
export const PATH_DELETE_PRODUCT = (productId: number): string => `product/${productId}`;

export const PATH_GET_CLIENT_PRODUCTS = (clientUUID: string): string =>
  `client/${clientUUID}/products`;
export const PATH_ADD_CLIENT_PRODUCT = (clientUUID: string, productId: number): string =>
  `client/${clientUUID}/product/${productId}`;
export const PATH_DELETE_CLIENT_PRODUCT = (clientUUID: string, productId: number): string =>
  `client/${clientUUID}/product/${productId}`;

export const PATH_GET_CLIENT_ADDITIONAL_OPTIONS = (clientUUID: string): string =>
  `client/${clientUUID}/additionalOptions`;
export const PATH_UPDATE_CLIENT_ADDITIONAL_OPTIONS = (clientUUID: string): string =>
  `client/${clientUUID}/additionalOption`;

export const PATH_CLIENTLOCATIONS = (clientUUID: string): string =>
  `client/${clientUUID}/locations`;
export const PATH_GET_CLIENTLOCATION = (clientUUID: string, clientLocationsId: number): string =>
  `client/${clientUUID}/location/${clientLocationsId}`;
export const PATH_CREATE_CLIENTLOCATION = (clientUUID: string): string =>
  `client/${clientUUID}/location`;
export const PATH_UPDATE_CLIENTLOCATION = (clientUUID: string): string =>
  `client/${clientUUID}/location`;
export const PATH_DELETE_CLIENTLOCATION = (clientUUID: string, clientLocationsId: number): string =>
  `client/${clientUUID}/location/${clientLocationsId}`;
export const PATH_DELETE_MULTIPLE_CLIENTLOCATIONS = (clientUUID: string): string =>
  `client/${clientUUID}/locations/`;
export const PARH_CREATE_CLIENT_LOCATIONS_FROM_FILE = (clientUUID: string): string =>
  `client/${clientUUID}/locations/import`;
export const PATH_GET_POIS = `pois`;

export const PATH_ALL_USERS = 'users';
export const PATH_GET_USER = (userUUId?: string): string => `user/${userUUId ? `${userUUId}` : ''}`;
export const PATH_GET_CURRENT_USER = 'user';
export const PATH_CREATE_USER = 'user/';
export const PATH_UPDATE_USER = 'user/';
export const PATH_DELETE_USER = (userUUId: string): string => `user/${userUUId}`;
export const PATH_USER_INVITE = (userUUId: string): string => `user/${userUUId}/invite`;
export const PATH_USER_UPDATE_PASSWORD = (userUUId: string): string =>
  `user/${userUUId}/updatePassword`;

export const PATH_ALL_LAYERS = 'arealayers';
export const PATH_GET_LAYER = (layerId: number): string => `arealayer/${layerId}`;
export const PATH_TEMPLATES_GET = `layoutTemplates`;

export const PATH_CLIENT_DISTRIBUTION_APPOINTMENTS = (clientUUID: string): string =>
  `client/${clientUUID}/distributionAppointments`;
export const PATH_GET_CLIENT_DISTRIBUTION_APPOINTMENT = (
  clientUUID: string,
  distributionAppointmentssId: number
): string => `client/${clientUUID}/distributionAppointment/${distributionAppointmentssId}`;
export const PATH_CREATE_DISTRIBUTION_APPOINTMENT = (clientUUID: string): string =>
  `client/${clientUUID}/distributionAppointment`;
export const PATH_UPDATE_DISTRIBUTION_APPOINTMENT = (clientUUID: string): string =>
  `client/${clientUUID}/distributionAppointment`;
export const PATH_DELETE_DISTRIBUTION_APPOINTMENT = (
  clientUUID: string,
  distributionAppointmentssId: number
): string => `client/${clientUUID}/distributionAppointment/${distributionAppointmentssId}`;

export const PATH_GET_CLIENT_TEMPLATES = (clientUUID: string): string =>
  `client/${clientUUID}/layoutTemplates`;
export const PATH_ADD_CLIENT_TEMPLATE = (clientUUID: string, templateId: number): string =>
  `client/${clientUUID}/layoutTemplate/${templateId}`;
export const PATH_DELETE_CLIENT_TEMPLATE = (clientUUID: string, templateId: number): string =>
  `client/${clientUUID}/layoutTemplate/${templateId}`;

export const HEADER_SET_COOKIE = 'set-cookie';
export const HEADER_COOKIE = 'cookie';
