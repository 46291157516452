import * as React from 'react';

import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';

import { Weekday, selectPickerTheme, HOURS, MINUTES } from '../../../constants/constants';
import {
  CLIENT_LOCATIONS_MODAL_FROM,
  CLIENT_LOCATIONS_MODAL_TO,
  CLIENT_LOCATIONS_MODAL_SPLIT_LABEL,
  CLIENT_LOCATIONS_MODAL_OPENING_HOUR_INVALID
} from '../../../constants/labels';

import {
  ClientLocationModalOpeningHoursProps,
  ClientLocationModalOpeningHoursState
} from '../../../@types/Modal.d';
import { OpeningHours } from '../../../@types/Model/Client.d';

const generateTimes = (): string[] =>
  HOURS.map((hour) => MINUTES.map((minute) => `${hour}:${minute}`)).flat();

export default class ClientLocationModalOpeningHours extends React.Component<
  ClientLocationModalOpeningHoursProps,
  ClientLocationModalOpeningHoursState
> {
  constructor(props: ClientLocationModalOpeningHoursProps) {
    super(props);

    const { clientLocation } = this.props;
    this.state = { openingHours: clientLocation?.openingHours ?? {} };

    this.onChangeDayEnabled = this.onChangeDayEnabled.bind(this);
    this.onChangeOpeningHoursMorningFrom = this.onChangeOpeningHoursMorningFrom.bind(this);
    this.onChangeOpeningHoursMorningTo = this.onChangeOpeningHoursMorningTo.bind(this);
    this.onChangeOpeningHoursNoonFrom = this.onChangeOpeningHoursNoonFrom.bind(this);
    this.onChangeOpeningHoursNoonTo = this.onChangeOpeningHoursNoonTo.bind(this);
    this.onChangeDayEnabled = this.onChangeDayEnabled.bind(this);
    this.onChangeSplitEnabled = this.onChangeSplitEnabled.bind(this);
  }

  onChangeOpeningHoursMorningFrom(time: any, day: Weekday): void {
    const { openingHours } = this.state;

    const { value } = time;
    if (!value && value !== null) return;

    const dayOpeningHour = openingHours[day];

    if (!dayOpeningHour) return;

    dayOpeningHour.morningFrom = value;

    this.setState({
      openingHours: { ...openingHours, ...{ [day]: dayOpeningHour } }
    });
  }

  onChangeOpeningHoursMorningTo(time: any, day: Weekday): void {
    const { openingHours } = this.state;

    const { value } = time;
    if (!value && value !== null) return;

    const dayOpeningHour = openingHours[day];

    if (!dayOpeningHour) return;

    dayOpeningHour.morningTo = value;

    this.setState({
      openingHours: { ...openingHours, ...{ [day]: dayOpeningHour } }
    });
  }

  onChangeOpeningHoursNoonFrom(time: any, day: Weekday): void {
    const { openingHours } = this.state;

    const { value } = time;
    if (!value && value !== null) return;

    const dayOpeningHour = openingHours[day];

    if (!dayOpeningHour) return;

    dayOpeningHour.noonFrom = value;

    this.setState({
      openingHours: { ...openingHours, ...{ [day]: dayOpeningHour } }
    });
  }

  onChangeOpeningHoursNoonTo(time: any, day: Weekday): void {
    const { openingHours } = this.state;

    const { value } = time;
    if (!value && value !== null) return;

    const dayOpeningHour = openingHours[day];

    if (!dayOpeningHour) return;

    dayOpeningHour.noonTo = value;

    this.setState({
      openingHours: { ...openingHours, ...{ [day]: dayOpeningHour } }
    });
  }

  onChangeDayEnabled(event: React.ChangeEvent<HTMLInputElement>, day: Weekday): void {
    event.stopPropagation();
    // event.preventDefault();

    const { openingHours } = this.state;

    let newOpeningHours;

    if (event.currentTarget.checked) {
      newOpeningHours = {
        ...openingHours,
        ...{
          [day]: {
            continuouslyOpen: true,
            noonFrom: undefined,
            noonTo: undefined
          }
        }
      };
    } else {
      const { [day]: removedDay, ...rest } = openingHours;
      newOpeningHours = rest;
    }

    this.setState({
      openingHours: newOpeningHours
    });
  }

  onChangeSplitEnabled(event: React.ChangeEvent<HTMLInputElement>, day: Weekday): void {
    event.stopPropagation();
    // event.preventDefault();

    const { openingHours } = this.state;

    const openingDay = {
      ...openingHours[day],
      ...{
        continuouslyOpen: event.currentTarget.checked,
        noonFrom: undefined,
        noonTo: undefined
      }
    };

    this.setState({
      openingHours: { ...openingHours, ...{ [day]: openingDay } }
    });
  }

  getOpeningHours(): OpeningHours {
    const { openingHours } = this.state;

    return openingHours;
  }

  render(): JSX.Element {
    const { openingHours } = this.state;

    return (
      <div className="opening-hours-container">
        {Object.values(Weekday).map((day) => (
          <Row key={day} className="no-gutters opening-hour-row">
            <Col xs={2} className="switch-container left">
              <Form.Check
                type="switch"
                className="weekday-check"
                id={`opening-hours-enable-${day}-switch`}
                label=""
                checked={openingHours[day] !== undefined}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.onChangeDayEnabled(event, day)
                }
              />
              <div className="text-container">{day}</div>
            </Col>
            <Col xs={4}>
              <Row className="no-gutters">
                <Col className="p-1">
                  <Form.Group>
                    <Form.Label>{CLIENT_LOCATIONS_MODAL_FROM}</Form.Label>
                    <Select
                      blurInputOnSelect
                      isSearchable
                      options={generateTimes().map((hour) => ({
                        value: hour,
                        label: hour
                      }))}
                      value={{
                        label: openingHours[day]?.morningFrom,
                        value: openingHours[day]?.morningFrom
                      }}
                      onChange={(time) => this.onChangeOpeningHoursMorningFrom(time, day)}
                      theme={(theme: any) => selectPickerTheme(theme)}
                      isDisabled={!openingHours[day]}
                    />
                    <Form.Control
                      value={openingHours[day]?.morningFrom}
                      hidden
                      onChange={() => {}}
                      required={openingHours[day] !== undefined}
                    />
                    <Form.Control.Feedback type="invalid">
                      {CLIENT_LOCATIONS_MODAL_OPENING_HOUR_INVALID}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="p-1">
                  <Form.Group>
                    <Form.Label>{CLIENT_LOCATIONS_MODAL_TO}</Form.Label>
                    <Select
                      blurInputOnSelect
                      isSearchable
                      options={generateTimes().map((hour) => ({
                        value: hour,
                        label: hour
                      }))}
                      value={{
                        label: openingHours[day]?.morningTo,
                        value: openingHours[day]?.morningTo
                      }}
                      onChange={(time) => this.onChangeOpeningHoursMorningTo(time, day)}
                      theme={(theme: any) => selectPickerTheme(theme)}
                      isDisabled={!openingHours[day]}
                    />
                    <Form.Control
                      value={openingHours[day]?.morningTo}
                      hidden
                      onChange={() => {}}
                      required={openingHours[day] !== undefined}
                    />
                    <Form.Control.Feedback type="invalid">
                      {CLIENT_LOCATIONS_MODAL_OPENING_HOUR_INVALID}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              {openingHours[day] && !openingHours[day]?.continuouslyOpen && (
                <Row className="no-gutters">
                  <Col className="p-1">
                    <Form.Group>
                      <Form.Label>{CLIENT_LOCATIONS_MODAL_FROM}</Form.Label>
                      <Select
                        blurInputOnSelect
                        isSearchable
                        options={generateTimes().map((hour) => ({
                          value: hour,
                          label: hour
                        }))}
                        value={{
                          label: openingHours[day]?.noonFrom,
                          value: openingHours[day]?.noonFrom
                        }}
                        onChange={(time) => this.onChangeOpeningHoursNoonFrom(time, day)}
                        theme={(theme: any) => selectPickerTheme(theme)}
                        isDisabled={!openingHours[day]}
                      />
                      <Form.Control
                        value={openingHours[day]?.noonFrom}
                        hidden
                        onChange={() => {}}
                        required={!openingHours[day]?.continuouslyOpen}
                      />
                      <Form.Control.Feedback type="invalid">
                        {CLIENT_LOCATIONS_MODAL_OPENING_HOUR_INVALID}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="p-1">
                    <Form.Group>
                      <Form.Label>{CLIENT_LOCATIONS_MODAL_TO}</Form.Label>
                      <Select
                        blurInputOnSelect
                        isSearchable
                        options={generateTimes().map((hour) => ({
                          value: hour,
                          label: hour
                        }))}
                        value={{
                          label: openingHours[day]?.noonTo,
                          value: openingHours[day]?.noonTo
                        }}
                        onChange={(time) => this.onChangeOpeningHoursNoonTo(time, day)}
                        theme={(theme: any) => selectPickerTheme(theme)}
                        isDisabled={!openingHours[day]}
                      />
                      <Form.Control
                        value={openingHours[day]?.noonTo}
                        hidden
                        onChange={() => {}}
                        required={!openingHours[day]?.continuouslyOpen}
                      />
                      <Form.Control.Feedback type="invalid">
                        {CLIENT_LOCATIONS_MODAL_OPENING_HOUR_INVALID}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={2} className="switch-container right">
              <Form.Check
                type="switch"
                id={`opening-hours-split-${day}-switch`}
                label=""
                checked={openingHours[day]?.continuouslyOpen || !openingHours[day]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.onChangeSplitEnabled(event, day)
                }
                disabled={!openingHours[day]}
              />
              {CLIENT_LOCATIONS_MODAL_SPLIT_LABEL}
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}
