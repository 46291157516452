import { Dispatch } from 'redux';
import { Cookies } from 'react-cookie';

import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  PATH_GET_CURRENT_USER,
  PATH_LOGIN,
  PATH_ALL_USERS,
  PATH_GET_USER,
  PATH_CREATE_USER,
  PATH_UPDATE_USER,
  PATH_DELETE_USER,
  PATH_USER_INVITE,
  PATH_USER_UPDATE_PASSWORD
} from '../../constants/network';
import {
  loginFailureAction,
  loginStartedAction,
  loginSuccessAction,
  fetchUsersAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  inviteUserAction
} from '../../actions/userActions';
import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';

import { parseUser, parseUsers } from '../responseUtil/userResponseUtil';

import { axiosClient, COOKIE_USER } from '../../constants/constants';
import {
  loginErrorTitle,
  loginErrorContent,
  fetchUsersErrorTitle,
  fetchUsersErrorContent,
  createUserErrorTitle,
  createUserErrorContent,
  updateUserErrorTitle,
  updateUserErrorContent,
  deleteUserErrorTitle,
  deleteUserErrorContent,
  inviteUserErrorTitle,
  inviteUserErrorContent,
  changePasswordUserErrorTitle,
  changePasswordUserErrorContent
} from '../../constants/errorMessages';

import {
  LOGIN,
  FETCH_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  INVITE_USER,
  CHANGE_PASSWORD_USER
} from '../../constants/actionNames/user';

import { User } from '../../@types/Model/User.d';
import {
  CreateUserAction,
  DeleteUserAction,
  FetchUsersAction,
  InviteUserAction,
  LoginFailureAction,
  UpdateUserAction
} from '../../@types/Actions/User.d';
import { RequestFailure } from '../../@types/Actions/UI.d';
import config from '../../config';

export const getCurrentUser = (): Promise<AxiosResponse | AxiosError> =>
  axios.get(`${config.apiUrl}/${PATH_GET_CURRENT_USER}`, {
    validateStatus: (status: number) => status < 300,
    withCredentials: true
  });

export const postLogin = (email: string, password: string, cookies: Cookies) => async (
  dispatch: Dispatch
): Promise<LoginFailureAction> => {
  dispatch(loginStartedAction(LOGIN));

  const params = new URLSearchParams();
  params.append('username', email);
  params.append('password', password);

  try {
    const res = await axiosClient.post(PATH_LOGIN, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      validateStatus: (status: number) => status < 300
    });

    const userRes = await getCurrentUser();

    const user = parseUser((userRes as AxiosResponse).data);
    cookies.set(COOKIE_USER, user);
    return dispatch(loginSuccessAction(LOGIN, user));
  } catch (error) {
    cookies.remove(COOKIE_USER);
    dispatch(
      requestFailure(LOGIN, {
        title: loginErrorTitle,
        content: loginErrorContent(+((error as AxiosError).code ?? '404'))
      })
    );
    return dispatch(loginFailureAction((error as AxiosError).code ?? '404'));
  }
};

export const sendUserInvite = (user: User) => async (
  dispatch: Dispatch
): Promise<InviteUserAction> => {
  dispatch(requestStarted(INVITE_USER));

  const res = await axiosClient
    .put(
      PATH_USER_INVITE(user.uuid),
      {},
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(INVITE_USER));
    return dispatch(inviteUserAction('eyy'));
  }

  return dispatch(
    requestFailure(INVITE_USER, {
      title: inviteUserErrorTitle,
      content: inviteUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

// TODO Retrun type?
export const sendChangePasswordUser = (user: User, password: string) => async (
  dispatch: Dispatch
): Promise<InviteUserAction> => {
  dispatch(requestStarted(CHANGE_PASSWORD_USER));

  const res = await axiosClient
    .put(
      PATH_USER_UPDATE_PASSWORD(user.uuid),
      { password },
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CHANGE_PASSWORD_USER));
    return dispatch(inviteUserAction('eyy'));
  }

  return dispatch(
    requestFailure(CHANGE_PASSWORD_USER, {
      title: changePasswordUserErrorTitle,
      content: changePasswordUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getUser = (userUUId: string): Promise<any> =>
  axiosClient
    .get(PATH_GET_USER(userUUId), {
      validateStatus: (status: number) => status < 300
    })
    .then(
      (response) => response,
      (error) => error
    )
    .catch((error) => error);

export const getAllUsers = () => async (
  dispatch: Dispatch
): Promise<FetchUsersAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_USERS));

  const res = await axiosClient
    .get(PATH_ALL_USERS, {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_USERS));
    return dispatch(fetchUsersAction(parseUsers(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_USERS, {
      title: fetchUsersErrorTitle,
      content: fetchUsersErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const createUser = (user: User) => async (
  dispatch: Dispatch
): Promise<CreateUserAction | RequestFailure> => {
  dispatch(requestStarted(CREATE_USER));

  const { id, email, prename, lastname, salutation, internal, admin } = user;

  const res = await axiosClient
    .post(
      PATH_CREATE_USER,
      {
        id,
        email,
        forename: prename,
        surname: lastname,
        salutation,
        internal,
        admin,
        password: '4d52f4f0c165092e618ec0659e004d6d'
      },
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CREATE_USER));
    return dispatch(createUserAction(parseUser(res.data)));
  }

  return dispatch(
    requestFailure(CREATE_USER, {
      title: createUserErrorTitle,
      content: createUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const updateUser = (user: User) => async (
  dispatch: Dispatch
): Promise<UpdateUserAction | RequestFailure> => {
  dispatch(requestStarted(UPDATE_USER));

  const { id, email, prename, lastname, salutation, internal, admin } = user;

  const res = await axiosClient
    .put(
      PATH_UPDATE_USER,
      { id, email, forename: prename, surname: lastname, salutation, internal, admin },
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_USER));
    return dispatch(updateUserAction(parseUser(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_USER, {
      title: updateUserErrorTitle,
      content: updateUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteUser = (user: User) => async (
  dispatch: Dispatch
): Promise<DeleteUserAction | RequestFailure> => {
  dispatch(requestStarted(DELETE_USER));

  const res = await axiosClient
    .delete(PATH_DELETE_USER(user.uuid), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_USER));
    return dispatch(deleteUserAction(user));
  }

  return dispatch(
    requestFailure(DELETE_USER, {
      title: deleteUserErrorTitle,
      content: deleteUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};
