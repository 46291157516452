import { Product } from '../@types/Model/Product.d';
import {
  FetchAllProductsAction,
  FetchProductAction,
  UpdateProductAction,
  CreateProductAction,
  DeleteProductAction,
  SelectProduct
} from '../@types/Actions/Product.d';
import {
  FETCH_ALL_PRODUCTS,
  FETCH_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  SELECT_PRODUCT
} from '../constants/actionNames/products';

export const fetchAllProductsAction = (products: Product[]): FetchAllProductsAction => ({
  type: FETCH_ALL_PRODUCTS,
  products
});

export const fetchProductAction = (product: Product): FetchProductAction => ({
  type: FETCH_PRODUCT,
  product
});

export const createProductAction = (product: Product): CreateProductAction => ({
  type: CREATE_PRODUCT,
  product
});

export const updateProductAction = (product: Product): UpdateProductAction => ({
  type: UPDATE_PRODUCT,
  product
});

export const deleteProductAction = (product: Product): DeleteProductAction => ({
  type: DELETE_PRODUCT,
  product
});

export const selectProduct = (product: Product): SelectProduct => ({
  type: SELECT_PRODUCT,
  product
});
