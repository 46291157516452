import '../../styles/Login.scss';

import * as React from 'react';

import { Form, Col, FormControl, Button, Row } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';

import { withRouter } from 'react-router';
import {
  LOGIN_EMAIL_LABEL,
  LOGIN_EMAIL_PLACEHOLDER,
  LOGIN_EMAIL_INVALID,
  LOGIN_PASSWORD_LABEL,
  LOGIN_PASSWORD_PLACEHOLDER,
  LOGIN_PASSWORD_INVALID,
  LOGIN_BUTTON_LABEL
} from '../../constants/labels';

import { postLogin } from '../../util/api/userApi';

import { GlobalState } from '../../@types/State.d';
import { UserAction } from '../../@types/Actions/User.d';
import { LoginProps, LoginState } from '../../@types/Login.d';

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      validated: false,
      email: '',
      password: ''
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePasswort = this.onChangePasswort.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
    const email = event.currentTarget.value || '';

    this.setState({ email });
  }

  onChangePasswort(event: React.ChangeEvent<HTMLInputElement>): void {
    const password = event.currentTarget.value || '';

    this.setState({ password });
  }

  onClickSubmit(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        const { email, password } = this.state;
        const { sendLoginRequest, cookies } = this.props;

        sendLoginRequest(email, password, cookies);
      }
    });
  }

  render(): JSX.Element {
    const { validated } = this.state;

    return (
      <Row className="h-100 justify-content-center align-items-center">
        <Form
          id="login-form"
          className="login-form"
          onSubmit={this.onClickSubmit}
          noValidate
          validated={validated}
        >
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>{LOGIN_EMAIL_LABEL}</Form.Label>
                <FormControl
                  as="input"
                  autoComplete="email"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  onChange={this.onChangeEmail}
                  placeholder={LOGIN_EMAIL_PLACEHOLDER}
                  required
                />
                <Form.Control.Feedback type="invalid">{LOGIN_EMAIL_INVALID}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>{LOGIN_PASSWORD_LABEL}</Form.Label>
                <FormControl
                  as="input"
                  autoComplete="current_password"
                  type="password"
                  onChange={this.onChangePasswort}
                  placeholder={LOGIN_PASSWORD_PLACEHOLDER}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {LOGIN_PASSWORD_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group>
                <Button type="submit">{LOGIN_BUTTON_LABEL}</Button>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </Row>
    );
  }
}

const mapStateToProps = (state: GlobalState): Pick<LoginProps, 'user'> => ({
  user: state.entities.user
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, UserAction>
): Pick<LoginProps, 'sendLoginRequest'> => ({
  sendLoginRequest: (email: string, password: string, cookies: Cookies) =>
    dispatch(postLogin(email, password, cookies))
});

const LoginContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(withCookies(Login)));
// const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(withCookies(Login));

export default LoginContainer;
