import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import { AddRemoveListItemPorps } from '../../../@types/App.d';

const AddRemoveListItem: React.FC<AddRemoveListItemPorps> = (props: AddRemoveListItemPorps) => {
  const { item, notAssigned, itemAdd, itemDelete } = props;

  const onClickItem = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (notAssigned) itemAdd(item.id);
    else itemDelete(item.id);
  };

  return (
    <ListGroup.Item
      className={`add-remove-list-item ${notAssigned ? 'add' : 'remove'}`}
      onClick={onClickItem}
    >
      <Row className="w-100 no-gutters add-remove-list-item-row">
        <Col className={`item-icon-container ${item.subtitle ? 'has-subtitle' : ''}`}>
          <FontAwesomeIcon className="item-icon" icon={notAssigned ? faPlus : faMinus} />
        </Col>
        <Col className="text-container">
          <div className="text-eliptize-container">
            <span className="text-eliptize">{item.title}</span>
          </div>
          {item.subtitle && (
            <div className="text-eliptize-container">
              <span className="text-eliptize">
                <small>{`${item.subtitle}`}</small>
              </span>
            </div>
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default AddRemoveListItem;
