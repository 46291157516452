import { Module, DictState, Dict, PrintLayout } from '../@types/Common.d';
import { Entities, UI, AvailableFunctionality, GlobalState } from '../@types/State.d';
import { Client } from '../@types/Model/Client.d';
import { Product } from '../@types/Model/Product.d';
import { User } from '../@types/Model/User.d';
import { Layer } from '../@types/Model/Map.d';
import ClientsContainer from '../components/Clients/ClientsContainer';
import ProductsContainer from '../components/Products/ProductsContainer';
import UsersContainer from '../components/Users/UsersContainer';

import {
  CLIENTS_TITLE,
  CLIENTS_DESCRIPTION,
  PRODUCTS_TITLE,
  PRODUCTS_DESCRIPTION,
  USERS_TITLE,
  USERS_DESCRIPTION
} from './labels';
import { ROUTE_CLIENTS, ROUTE_PRODUCTS, ROUTE_USERS } from './constants';

const availableFunctionality: AvailableFunctionality[] = [
  {
    title: CLIENTS_TITLE,
    description: CLIENTS_DESCRIPTION,
    path: ROUTE_CLIENTS,
    component: ClientsContainer
  },
  {
    title: PRODUCTS_TITLE,
    description: PRODUCTS_DESCRIPTION,
    path: ROUTE_PRODUCTS,
    component: ProductsContainer
  },
  {
    title: USERS_TITLE,
    description: USERS_DESCRIPTION,
    path: ROUTE_USERS,
    component: UsersContainer
  }
];

const initialState: GlobalState = {
  entities: {
    user: null,
    users: { byId: {} as Dict<User>, allIds: [] } as DictState<User>,
    clients: { byId: {} as Dict<Client>, allIds: [] } as DictState<Client>,
    products: { byId: {} as Dict<Product>, allIds: [] } as DictState<Product>,
    layers: { byId: {} as Dict<Layer>, allIds: [] } as DictState<Layer>,
    printLayouts: { byId: {} as Dict<PrintLayout>, allIds: [] } as DictState<PrintLayout>,
    modules: { byId: {} as Dict<Module>, allIds: [] } as DictState<Module>
  } as Entities,
  ui: {
    basic: {
      loggedIn: false,
      loading: false,
      runningRequests: [],
      availableFunctionality
    },
    modals: {
      confirmation: { show: false },
      requestError: { show: false },
      clientLocation: { show: false },
      changePassword: { show: false }
    }
  } as UI
};

export default initialState;
