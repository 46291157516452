import * as React from 'react';

import { Tabs, Tab } from 'react-bootstrap';

import ClientsDetailsClientContainer from './ClientsDetailsClient';
import ClientsDetailsClientLocationsContainer from './ClientLocations/ClientsDetailsClientLocations';
import ClientsDetailsLayersContainer from './ClientLayers/ClientsDetailsLayers';
import ClientsDetailsDistributionTemplatesContainer from './ClientDistributionTemplates/ClientsDetailsDistributionTemplates';
import ClientsDetailsUsersContainer from './ClientUsers/ClientsDetailsUsers';
import ClientsDetailsProductsContainer from './ClientProducts/ClientsDetailsProducts';
import ClientsDetailsOfferOrderContainer from './ClientOfferOrder/ClientsDetailsOfferOrder';
import ClientDetailsDistributionAppointmentsContainer from './ClientDistributionAppointments/ClientDetailsDistributionAppointments';

import {
  CLIENTS_DETAILS_TAB_ID_CLIENT,
  CLIENTS_DETAILS_TAB_ID_CLIENT_LOCATIONS,
  CLIENTS_DETAILS_TAB_ID_LAYERS,
  CLIENTS_DETAILS_TAB_ID_DISTRIBUTION_TEMPLATES,
  CLIENTS_DETAILS_TAB_ID_USERS,
  CLIENTS_DETAILS_TAB_ID_PRODUCTS,
  CLIENTS_DETAILS_TAB_ID_OFFER_ORDER,
  TRANSMISSIONTYPE_OFFER,
  CLIENTS_DETAILS_TAB_ID_DISTRIBUTION_ACTIONS,
  CLIENTS_DETAILS_TAB_ID_MODULES,
  CLIENTS_DETAILS_TAB_ID_PRINT_LAYOUTS
} from '../../../constants/constants';
import {
  CLIENTS_DETAILS_TAB_CLIENT,
  CLIENTS_DETAILS_TAB_CLIENT_LOCATIONS,
  CLIENTS_DETAILS_TAB_LAYERS,
  CLIENTS_DETAILS_TAB_DISTRIBUTION_TEMPLATES,
  CLIENTS_DETAILS_TAB_USERS,
  CLIENTS_DETAILS_TAB_PRODUCTS,
  CLIENTS_DETAILS_TAB_OFFER_ORDER,
  CLIENTS_DETAILS_TAB_DISTRIBUTION_ACTIONS,
  CLIENTS_DETAILS_TAB_MODULE,
  CLIENTS_DETAILS_TAB_PRINT_LAYOUTS
} from '../../../constants/labels';

import { ClientsDetailsTabsProps } from '../../../@types/Clients.d';
import ClientModulesContainer from './ClientModules/ClientDetailsModules';
import ClientDetailsPrintLayoutsContainer from './ClientPrintLayouts/ClientDetailsPrintLayoutsContainer';

const ClientsDetailsTabs: React.FC<ClientsDetailsTabsProps> = (props: ClientsDetailsTabsProps) => {
  const { client } = props;

  let clientModules;

  if (client) {
    const { modules } = client;
    clientModules = modules.allIds.map((id) => modules.byId[id]);
  }

  return (
    <Tabs
      defaultActiveKey={CLIENTS_DETAILS_TAB_ID_CLIENT}
      id="clients-details-tabs"
      mountOnEnter
      unmountOnExit
    >
      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_CLIENT}
        className="h-100"
        title={CLIENTS_DETAILS_TAB_CLIENT}
      >
        <div className="tab-container">
          <ClientsDetailsClientContainer />
        </div>
      </Tab>

      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_USERS}
        className="h-100"
        title={CLIENTS_DETAILS_TAB_USERS}
      >
        <div className="tab-container">
          <ClientsDetailsUsersContainer />
        </div>
      </Tab>
      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_LAYERS}
        className="h-100"
        title={CLIENTS_DETAILS_TAB_LAYERS}
      >
        <div className="tab-container">
          <ClientsDetailsLayersContainer />
        </div>
      </Tab>
      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_CLIENT_LOCATIONS}
        title={CLIENTS_DETAILS_TAB_CLIENT_LOCATIONS}
        className="h-100"
      >
        <div className="tab-container">
          <ClientsDetailsClientLocationsContainer />
        </div>
      </Tab>

      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_PRODUCTS}
        className="h-100"
        title={CLIENTS_DETAILS_TAB_PRODUCTS}
      >
        <div className="tab-container">
          <ClientsDetailsProductsContainer />
        </div>
      </Tab>

      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_DISTRIBUTION_TEMPLATES}
        title={CLIENTS_DETAILS_TAB_DISTRIBUTION_TEMPLATES}
        className="h-100"
      >
        <div className="tab-container">
          <ClientsDetailsDistributionTemplatesContainer client={client} />
        </div>
      </Tab>
      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_OFFER_ORDER}
        title={CLIENTS_DETAILS_TAB_OFFER_ORDER(client?.transmissionType === TRANSMISSIONTYPE_OFFER)}
        className="h-100"
      >
        <div className="tab-container">
          <ClientsDetailsOfferOrderContainer />
        </div>
      </Tab>
      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_DISTRIBUTION_ACTIONS}
        title={CLIENTS_DETAILS_TAB_DISTRIBUTION_ACTIONS}
        className="h-100"
        // disabled={client?.distributionDateType !== 'TEMPLATE'}
      >
        <div className="tab-container">
          <ClientDetailsDistributionAppointmentsContainer />
        </div>
      </Tab>
      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_MODULES}
        title={CLIENTS_DETAILS_TAB_MODULE}
        className="h-100"
      >
        <div className="tab-container">
          <ClientModulesContainer />
        </div>
      </Tab>
      <Tab
        eventKey={CLIENTS_DETAILS_TAB_ID_PRINT_LAYOUTS}
        title={CLIENTS_DETAILS_TAB_PRINT_LAYOUTS}
        className="h-100"
        disabled={!clientModules?.find((module) => module.type === 'LAYOUTDESIGN')?.enabled}
      >
        <div className="tab-container">
          <ClientDetailsPrintLayoutsContainer />
        </div>
      </Tab>
    </Tabs>
  );
};

export default ClientsDetailsTabs;
