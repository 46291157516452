import initialState from '../../../constants/initialState';
import {
  HIDE_CONFIRMATION_MODAL,
  SHOW_CONFIRMATION_MODAL
} from '../../../constants/actionNames/ui';

import { ShowConfirmationModal } from '../../../@types/Actions/UI.d';
import { Action } from '../../../@types/Actions/Common.d';
import { ConfirmationModal } from '../../../@types/State.d';

export default function confirmationModalReducer(
  state = initialState.ui.modals.confirmation,
  action: Action
): ConfirmationModal {
  let payload;

  switch (action.type) {
    case SHOW_CONFIRMATION_MODAL:
      payload = (action as ShowConfirmationModal).payload;
      return {
        ...state,
        ...{
          show: true,
          title: payload.title,
          content: payload.content,
          confirmationCallback: payload.confirmationCallback
        }
      };
    case HIDE_CONFIRMATION_MODAL:
      return { ...state, ...{ show: false, title: undefined, content: undefined } };
    default:
      return state;
  }
}
