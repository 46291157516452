import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';

import ClientDetailsOfferOrderAreaList from './ClientDetailsOfferOrderAreaList';

import { getClientLocationCirculation } from '../../../../../util/utils';

import { ClientDetailOfferOrderDistributionListItemProps } from '../../../../../@types/Clients.d';

const ClientDetailsOfferOrderDistributionListItem: React.FC<ClientDetailOfferOrderDistributionListItemProps> = (
  props: ClientDetailOfferOrderDistributionListItemProps
) => {
  const { location, showLocalitiesModal } = props;

  return (
    <ListGroup.Item className="distribution-list-item">
      <Row className="no-gutters">
        <Col sm={3} xl={2} className="client-location-title text-eliptize">
          {location.name}
        </Col>
        <Col sm={3} xl={2} className="client-location-circulation">
          {`${getClientLocationCirculation(location).toLocaleString()} Stk.`}
        </Col>

        <Col>
          <ClientDetailsOfferOrderAreaList
            areas={location.areas}
            showLocalitiesModal={showLocalitiesModal}
          />
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default ClientDetailsOfferOrderDistributionListItem;
