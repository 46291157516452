import { Layer } from '../@types/Model/Map.d';
import {
  FetchLayersAction,
  FetchLayerAction,
  UpdateLayerAction,
  CreateLayerAction,
  DeleteLayerAction,
  SelectLayer
} from '../@types/Actions/Layer.d';
import {
  FETCH_LAYERS,
  FETCH_LAYER,
  UPDATE_LAYER,
  CREATE_LAYER,
  DELETE_LAYER,
  SELECT_LAYER
} from '../constants/actionNames/layer';

export const fetchLayersAction = (layers: Layer[]): FetchLayersAction => ({
  type: FETCH_LAYERS,
  layers
});

export const fetchLayerAction = (layer: Layer): FetchLayerAction => ({
  type: FETCH_LAYER,
  layer
});

export const createLayerAction = (layer: Layer): CreateLayerAction => ({
  type: CREATE_LAYER,
  layer
});

export const updateLayerAction = (layer: Layer): UpdateLayerAction => ({
  type: UPDATE_LAYER,
  layer
});

export const deleteLayerAction = (layer: Layer): DeleteLayerAction => ({
  type: DELETE_LAYER,
  layer
});

export const selectLayer = (layer: Layer): SelectLayer => ({
  type: SELECT_LAYER,
  layer
});
