export const FETCH_CLIENT_USERS = 'FETCH_CLIENT_USERS';
export const ADD_CLIENT_USER = 'ADD_CLIENT_USER';
export const DELETE_CLIENT_USER = 'DELETE_CLIENT_USER';
export const FETCH_CLIENT_PRODUCTS = 'FETCH_CLIENT_PRODUCTS';
export const ADD_CLIENT_PRODUCT = 'ADD_CLIENT_PRODUCT';
export const DELETE_CLIENT_PRODUCT = 'DELETE_CLIENT_PRODUCT';
export const FETCH_CLIENT_LAYERS = 'FETCH_CLIENT_LAYERS';
export const ADD_CLIENT_LAYER = 'ADD_CLIENT_LAYER';
export const DELETE_CLIENT_LAYER = 'DELETE_CLIENT_LAYER';
export const FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS = 'FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS';
export const UPDATE_CLIENT_ADDITIONAL_OPTION = 'UPDATE_CLIENT_ADDITIONAL_OPTION';
export const FETCH_ALL_CLIENT_MODULES = 'FETCH_ALL_CLIENT_MODULES';
export const FETCH_ALL_MODULES = 'FETCH_ALL_MODULES';
export const UPDATE_CLIENT_MODULE = 'UPDATE_CLIENT_MODULE';
