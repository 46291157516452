import { Dispatch } from 'redux';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';
import { fetchLayersAction } from '../../actions/layerActions';

import { parseLayers } from '../responseUtil/layerResponseUtil';

import { axiosClient } from '../../constants/constants';
import { PATH_ALL_LAYERS, PATH_GET_LAYER } from '../../constants/network';
import { fetchLayersErrorTitle, fetchLayersErrorContent } from '../../constants/errorMessages';
import { FETCH_LAYERS } from '../../constants/actionNames/layer';

import { FetchLayersAction } from '../../@types/Actions/Layer.d';
import { RequestFailure } from '../../@types/Actions/UI.d';

export const getAllLayers = () => async (
  dispatch: Dispatch
): Promise<FetchLayersAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_LAYERS));

  const res = await axiosClient
    .get(PATH_ALL_LAYERS, {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_LAYERS));
    return dispatch(fetchLayersAction(parseLayers(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_LAYERS, {
      title: fetchLayersErrorTitle,
      content: fetchLayersErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getLayer = (layerId: number): Promise<any> =>
  axiosClient
    .get(PATH_GET_LAYER(layerId), {
      validateStatus: (status: number) => status < 300
    })
    .then(
      (response) => response,
      (error) => error
    )
    .catch((error) => error);
