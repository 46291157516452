import {
  FETCH_CLIENT_PRODUCTS,
  ADD_CLIENT_PRODUCT,
  DELETE_CLIENT_PRODUCT
} from '../../constants/actionNames/clients/clientActions';

import { Product } from '../../@types/Model/Product.d';
import {
  FetchClientProductsAction,
  AddClientProductAction,
  DeleteClientProductAction
} from '../../@types/Actions/Client/ClientProduct.d';

export const fetchClientProductsAction = (products: Product[]): FetchClientProductsAction => ({
  type: FETCH_CLIENT_PRODUCTS,
  products
});

export const addClientProductAction = (product: Product): AddClientProductAction => ({
  type: ADD_CLIENT_PRODUCT,
  product
});

export const deleteClientProductAction = (product: Product): DeleteClientProductAction => ({
  type: DELETE_CLIENT_PRODUCT,
  product
});
