import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { Row, Col } from 'react-bootstrap';

import { requestStarted, requestSuccess, requestFailure } from '../../../../../actions/uiActions';

import ClientDetailsOfferOrderDistributionList from './ClientDetailsOfferOrderDistributionList';
import LocalitiesModal from '../../../../Modal/Localities/LocalitiesModal';

import { TRANSMISSIONTYPE_OFFER } from '../../../../../constants/constants';
import { FETCH_CLIENT_OFFER_ORDER } from '../../../../../constants/actionNames/clients/clientOfferOrderActions';
import {
  fetchClientOfferOrderErrorTitle,
  fetchAllClientOfferOrderErrorContent
} from '../../../../../constants/errorMessages';

import {
  CLIENTS_OFFER_ORDER_DISTRIBUTION_CIRCULATION_HEADER,
  CLIENTS_OFFER_ORDER_DISTRIBUTION_DISTRIBUTION_HEADER
} from '../../../../../constants/labels';

import { getOfferOrderItemData } from '../../../../../util/api/clientsApi';
import {
  extractOfferTemplate,
  extractOrderTemplate
} from '../../../../../util/responseUtil/clientResponseUtil';
import { getClientLocationsCirculation } from '../../../../../util/utils';

import {
  ClientDetailOfferOrderDistributionProps,
  ClientDetailOfferOrderDistributionState
} from '../../../../../@types/Clients.d';
import { AreaSendFormat } from '../../../../../@types/Common.d';
import { GlobalState } from '../../../../../@types/State.d';
import { ErrorMessage } from '../../../../../@types/Model/Message.d';
import { UIAction } from '../../../../../@types/Actions/UI.d';

class ClientDetailsOfferOrderDistribution extends React.Component<
  ClientDetailOfferOrderDistributionProps,
  ClientDetailOfferOrderDistributionState
> {
  constructor(props: ClientDetailOfferOrderDistributionProps) {
    super(props);

    const { offerOrder } = this.props;

    this.state = {
      showLocalitiesModal: false,
      offerOrder: cloneDeep(offerOrder)
    };

    this.fetchOfferOrder = this.fetchOfferOrder.bind(this);
    this.showLocalities = this.showLocalities.bind(this);
  }

  componentDidMount(): void {
    const { offerOrder } = this.props;

    if (offerOrder) this.fetchOfferOrder(offerOrder.id);
  }

  componentDidUpdate(prevProps: ClientDetailOfferOrderDistributionProps): void {
    const { offerOrder, client } = this.props;

    if (prevProps.client?.id !== client?.id || prevProps.offerOrder?.id !== offerOrder?.id) {
      if (offerOrder) this.fetchOfferOrder(offerOrder.id);
    }
  }

  showLocalities(show: boolean, area: AreaSendFormat): void {
    this.setState({ showLocalitiesModal: show, selectedArea: area });
  }

  async fetchOfferOrder(offerOrderId: number): Promise<void> {
    const { reqStarted, reqSuccess, reqFailure, client } = this.props;
    if (!offerOrderId || !client) return;

    const isOffer = client?.transmissionType === TRANSMISSIONTYPE_OFFER;

    reqStarted(FETCH_CLIENT_OFFER_ORDER);
    const res = await getOfferOrderItemData(client, isOffer, offerOrderId);

    if (res.status < 300) {
      let offerOrder;

      if (isOffer) offerOrder = extractOfferTemplate(res.data);
      else offerOrder = extractOrderTemplate(res.data);

      reqSuccess(FETCH_CLIENT_OFFER_ORDER);
      this.setState({ offerOrder });

      return;
    }

    reqFailure(FETCH_CLIENT_OFFER_ORDER, {
      title: fetchClientOfferOrderErrorTitle,
      content: fetchAllClientOfferOrderErrorContent(
        res.response?.status ?? res.request?.status ?? 404,
        isOffer
      )
    });
  }

  render(): JSX.Element {
    const { offerOrder, selectedArea, showLocalitiesModal } = this.state;

    return (
      <>
        {selectedArea && (
          <LocalitiesModal
            area={selectedArea}
            show={showLocalitiesModal}
            hideModal={this.showLocalities}
          />
        )}
        <Row className="no-gutters section-header">
          <Col>{CLIENTS_OFFER_ORDER_DISTRIBUTION_CIRCULATION_HEADER}</Col>
        </Row>
        <Row className="no-gutters">
          <Col>{`${getClientLocationsCirculation(offerOrder?.locations ?? [])} Stk.`}</Col>
        </Row>
        <Row className="no-gutters section-header">
          <Col>{CLIENTS_OFFER_ORDER_DISTRIBUTION_DISTRIBUTION_HEADER}</Col>
        </Row>
        <Row className="no-gutters">
          <ClientDetailsOfferOrderDistributionList
            locations={offerOrder?.locations ?? []}
            showLocalitiesModal={this.showLocalities}
          />
        </Row>
      </>
    );
  }
}

const mapStateToProps = (
  state: GlobalState
): Pick<ClientDetailOfferOrderDistributionProps, 'client' | 'offerOrder'> => ({
  client: state.entities.clients.selectedItem,
  offerOrder: state.entities.clients.selectedItem?.offerOrder.selectedItem
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, UIAction>
): Pick<ClientDetailOfferOrderDistributionProps, 'reqStarted' | 'reqSuccess' | 'reqFailure'> => ({
  reqStarted: (payload: string) => dispatch(requestStarted(payload)),
  reqSuccess: (payload: string) => dispatch(requestSuccess(payload)),
  reqFailure: (payload: string, errorMessage: ErrorMessage) =>
    dispatch(requestFailure(payload, errorMessage))
});

const ClientDetailsOfferOrderDistributionContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientDetailsOfferOrderDistribution)
);
export default ClientDetailsOfferOrderDistributionContainer;
