import {
  RequestSuccess,
  RequestStarted,
  RequestFailure,
  HideErrorModal,
  ShowConfirmationModal,
  HideConfirmationModal,
  ShowClientLocationModal,
  HideClientLocationModal,
  ShowLoading,
  HideLoading,
  ShowChangePasswordModal,
  HideChangePasswordModal
} from '../@types/Actions/UI.d';
import {
  REQUEST_SUCCESS,
  REQUEST_STARTED,
  REQUEST_FAILURE,
  HIDE_ERROR_MODAL,
  SHOW_CONFIRMATION_MODAL,
  HIDE_CONFIRMATION_MODAL,
  SHOW_CLIENT_LOCATION_MODAL,
  HIDE_CLIENT_LOCATION_MODAL,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_CHANGE_PASSWORD_MODAL,
  HIDE_CHANGE_PASSWORD_MODAL
} from '../constants/actionNames/ui';
import { ConfirmationMessage, ErrorMessage } from '../@types/Model/Message.d';
import { ClientLocation } from '../@types/Model/Client.d';

export const requestStarted = (payload: string): RequestStarted => ({
  type: REQUEST_STARTED,
  payload
});
export const requestSuccess = (payload: string): RequestSuccess => ({
  type: REQUEST_SUCCESS,
  payload
});
export const requestFailure = (payload: string, errorMessage: ErrorMessage): RequestFailure => ({
  type: REQUEST_FAILURE,
  payload,
  errorMessage
});

export const showLoading = (payload: string): ShowLoading => ({
  type: SHOW_LOADING,
  payload
});

export const hideLoading = (payload: string): HideLoading => ({
  type: HIDE_LOADING,
  payload
});

export const hideErrorModal = (payload: string): HideErrorModal => ({
  type: HIDE_ERROR_MODAL,
  payload
});

export const showConfirmationModal = (payload: ConfirmationMessage): ShowConfirmationModal => ({
  type: SHOW_CONFIRMATION_MODAL,
  payload
});

export const hideConfirmationModal = (payload: string): HideConfirmationModal => ({
  type: HIDE_CONFIRMATION_MODAL,
  payload
});

export const showClientLocationModal = (
  clientLocation?: ClientLocation
): ShowClientLocationModal => ({
  type: SHOW_CLIENT_LOCATION_MODAL,
  clientLocation
});

export const hideClientLocationModal = (payload: string): HideClientLocationModal => ({
  type: HIDE_CLIENT_LOCATION_MODAL,
  payload
});

export const showChangePasswordModal = (payload: string): ShowChangePasswordModal => ({
  type: SHOW_CHANGE_PASSWORD_MODAL,
  payload
});

export const hideChangePasswordModal = (payload: string): HideChangePasswordModal => ({
  type: HIDE_CHANGE_PASSWORD_MODAL,
  payload
});
