import { map } from 'lodash';

import { User } from '../../@types/Model/User.d';

export const parseUser = (userData: any): User => {
  const { uuid, email, forename, id, salutation, surname, internal, admin } = userData;
  return {
    uuid,
    email,
    prename: forename,
    id,
    salutation,
    lastname: surname,
    internal,
    admin,
    sortProperty: `${surname}, ${forename}`
  } as User;
};

export const parseUsers = (usersData: any): User[] =>
  map(usersData, (userData) => parseUser(userData)).sort((a: User, b: User) => {
    if (`${a.lastname}${a.prename}` > `${b.lastname}${b.prename}`) return 1;
    if (`${a.lastname}${a.prename}` < `${b.lastname}${b.prename}`) return -1;
    return 0;
  });
