import { Dispatch } from 'redux';

import {
  addClientDistributionAppointmentAction,
  fetchAllClientDistributionAppointmentsAction,
  updateClientDistributionAppointmentAction,
  deleteClientDistributionAppointmentAction
} from '../../actions/client/clientDistributionAppointments';
import { requestFailure, requestStarted, requestSuccess } from '../../actions/uiActions';
import {
  CREATE_CLIENT_DISTRIBUTION_ACTION,
  DELETE_CLIENT_DISTRIBUTION_ACTION,
  FETCH_ALL_CLIENT_DISTRIBUTION_ACTIONS,
  UPDATE_CLIENT_DISTRIBUTION_ACTION
} from '../../constants/actionNames/clients/clientDistributionAppointments';
import { axiosClient } from '../../constants/constants';
import {
  fetchAllClientAdditionalOptionsErrorContent,
  createClientDistributionAppointmentErrorContent,
  createClientDistributionAppointmentErrorTitle,
  deleteClientDistributionAppointmentErrorContent,
  deleteClientDistributionAppointmentErrorTitle,
  updateClientDistributionAppointmentErrorContent,
  updateClientDistributionAppointmentErrorTitle,
  fetchAllClientAdditionalOptionsErrorTitle
} from '../../constants/errorMessages';
import {
  PATH_CLIENT_DISTRIBUTION_APPOINTMENTS,
  PATH_CREATE_DISTRIBUTION_APPOINTMENT,
  PATH_UPDATE_DISTRIBUTION_APPOINTMENT,
  PATH_DELETE_DISTRIBUTION_APPOINTMENT,
  PATH_GET_CLIENT_DISTRIBUTION_APPOINTMENT
} from '../../constants/network';
import {
  extractDistributionAppointment,
  extractDistributionAppointments
} from '../responseUtil/clientResponseUtil';

import { Client } from '../../@types/Model/Client.d';
import { DistributionAppointment } from '../../@types/Model/Distribution.d';
import {
  AddClientDistributionAppointmentAction,
  DeleteClientDistributionAppointmentAction,
  FetchAllClientDistributionAppointmentsAction,
  UpdateClientDistributionAppointmentAction
} from '../../@types/Actions/Client/ClientDistributionAppointments.d';
import { RequestFailure } from '../../@types/Actions/UI.d';

export const createClientDistributionAppointment = (
  client: Client,
  distributionAppointment: DistributionAppointment
) => async (
  dispatch: Dispatch
): Promise<AddClientDistributionAppointmentAction | RequestFailure> => {
  dispatch(requestStarted(CREATE_CLIENT_DISTRIBUTION_ACTION));

  const { date, ...rest } = distributionAppointment;

  const res = await axiosClient
    .post(
      PATH_CREATE_DISTRIBUTION_APPOINTMENT(client.uuid),
      { ...rest, ...{ date: date.format('YYYY-MM-DD'), ...{ id: -1 } } },
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CREATE_CLIENT_DISTRIBUTION_ACTION));
    return dispatch(
      addClientDistributionAppointmentAction(extractDistributionAppointment(res.data))
    );
  }

  return dispatch(
    requestFailure(CREATE_CLIENT_DISTRIBUTION_ACTION, {
      title: createClientDistributionAppointmentErrorTitle,
      content: createClientDistributionAppointmentErrorContent(
        res.response?.status ?? res.request?.status ?? 404
      )
    })
  );
};

export const updateClientDistributionAppointment = (
  client: Client,
  distributionAppointment: DistributionAppointment
) => async (
  dispatch: Dispatch
): Promise<UpdateClientDistributionAppointmentAction | RequestFailure> => {
  dispatch(requestStarted(UPDATE_CLIENT_DISTRIBUTION_ACTION));

  const { date, ...rest } = distributionAppointment;

  const res = await axiosClient
    .put(
      PATH_UPDATE_DISTRIBUTION_APPOINTMENT(client.uuid),
      {
        ...rest,
        ...{ date: date.format('YYYY-MM-DD') }
      },
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_CLIENT_DISTRIBUTION_ACTION));
    return dispatch(
      updateClientDistributionAppointmentAction(extractDistributionAppointment(res.data))
    );
  }

  return dispatch(
    requestFailure(UPDATE_CLIENT_DISTRIBUTION_ACTION, {
      title: updateClientDistributionAppointmentErrorTitle,
      content: updateClientDistributionAppointmentErrorContent(
        res.response?.status ?? res.request?.status ?? 404
      )
    })
  );
};

export const deleteClientDistributionAppointment = (
  client: Client,
  distributionAppointment: DistributionAppointment
) => async (
  dispatch: Dispatch
): Promise<DeleteClientDistributionAppointmentAction | RequestFailure> => {
  dispatch(requestStarted(DELETE_CLIENT_DISTRIBUTION_ACTION));

  const res = await axiosClient
    .delete(PATH_DELETE_DISTRIBUTION_APPOINTMENT(client.uuid, distributionAppointment.id), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_CLIENT_DISTRIBUTION_ACTION));
    return dispatch(deleteClientDistributionAppointmentAction(distributionAppointment.id));
  }

  return dispatch(
    requestFailure(DELETE_CLIENT_DISTRIBUTION_ACTION, {
      title: deleteClientDistributionAppointmentErrorTitle,
      content: deleteClientDistributionAppointmentErrorContent(
        res.response?.status ?? res.request?.status ?? 404
      )
    })
  );
};

export const getAllClientDistributionAppointments = (client: Client) => async (
  dispatch: Dispatch
): Promise<FetchAllClientDistributionAppointmentsAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_ALL_CLIENT_DISTRIBUTION_ACTIONS));

  const res = await axiosClient
    .get(PATH_CLIENT_DISTRIBUTION_APPOINTMENTS(client.uuid), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_CLIENT_DISTRIBUTION_ACTIONS));
    return dispatch(
      fetchAllClientDistributionAppointmentsAction(extractDistributionAppointments(res.data))
    );
  }

  return dispatch(
    requestFailure(FETCH_ALL_CLIENT_DISTRIBUTION_ACTIONS, {
      title: fetchAllClientAdditionalOptionsErrorTitle,
      content: fetchAllClientAdditionalOptionsErrorContent(
        res.response?.status ?? res.request?.status ?? 404
      )
    })
  );
};

export const getClientDistributionAppointment = (
  client: Client,
  distributionAppointment: DistributionAppointment
): Promise<any> =>
  axiosClient
    .get(PATH_GET_CLIENT_DISTRIBUTION_APPOINTMENT(client.uuid, distributionAppointment.id), {
      validateStatus: (status: number) => status < 300
    })
    .then(
      (response) => response,
      (error) => error
    )
    .catch((error) => error);
