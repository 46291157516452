import { getDict, getIndicies, insertSorted } from '../../util/utils';

import initialState from '../../constants/initialState';
import {
  FETCH_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  SELECT_USER
} from '../../constants/actionNames/user';

import { ClientUserAction } from '../../@types/Actions/Client/ClientUser.d';
import {
  FetchUsersAction,
  CreateUserAction,
  UpdateUserAction,
  DeleteUserAction,
  SelectUser
} from '../../@types/Actions/User.d';
import { DictState } from '../../@types/Common.d';
import { User } from '../../@types/Model/User.d';

export default function usersReducer(
  state = initialState.entities.users,
  action: ClientUserAction
): DictState<User> {
  const { type } = action;

  if (type === FETCH_USERS) {
    const castedAction = action as FetchUsersAction;

    const byId = getDict<User>(castedAction.users);
    const allIds = getIndicies<User>(byId);

    return { ...state, ...{ allIds, byId, selectedItem: byId[allIds[0]] } };
  }
  if (type === CREATE_USER) {
    const castedAction = action as CreateUserAction;
    const { allIds } = state;

    const byId = {
      ...state.byId,
      ...{ [castedAction.user.id]: castedAction.user }
    };

    return {
      ...state,
      ...{
        allIds: insertSorted<User>(byId, [...allIds], castedAction.user),
        byId,
        selectedItem: byId[castedAction.user.id]
      }
    } as DictState<User>;
  }
  if (type === UPDATE_USER) {
    const castedAction = action as UpdateUserAction;

    const byId = {
      ...state.byId,
      ...{ [castedAction.user.id]: castedAction.user }
    };
    const allIds = getIndicies<User>(byId);

    return {
      ...state,
      ...{ allIds, byId }
    } as DictState<User>;
  }
  if (type === DELETE_USER) {
    const castedAction = action as DeleteUserAction;
    const { byId } = state;

    const selectedIndex = state.allIds.indexOf(castedAction.user.id);

    if (selectedIndex < 0) return state;

    const allIds = [
      ...state.allIds.slice(0, selectedIndex),
      ...state.allIds.slice(selectedIndex + 1)
    ];

    return {
      ...state,
      ...{ allIds, selectedItem: byId[allIds[selectedIndex]] }
    } as DictState<User>;
  }
  if (type === SELECT_USER) {
    const castedAction = action as SelectUser;
    const { byId } = state;

    return { ...state, ...{ selectedItem: byId[castedAction.user?.id] } } as DictState<User>;
  }

  return state;
}
