import { map } from 'lodash';

import { getOpeningHours } from '../utils';

import { ClientLocation } from '../../@types/Model/Client.d';

export const parseClientLocation = (clientLocationData: any): ClientLocation => {
  const {
    id,
    addressName,
    city,
    colorSelectedFill,
    distributionArea, // TODO
    email,
    housenumber,
    img,
    lat,
    lon,
    name,
    web,
    fax,
    number,
    openingHours,
    phone,
    postcode,
    street,
    planable,
    poi,
    billingDefault
  } = clientLocationData;

  return {
    id,
    addressName,
    city,
    colorSelectedFill,
    distributionArea, // TODO
    email,
    housenumber,
    img,
    lat,
    lon,
    name,
    number,
    web,
    fax,
    openingHours: getOpeningHours(openingHours),
    phone,
    postcode,
    street,
    planable,
    billingDefault,
    poi: { active: poi?.active, inactive: poi?.inactive, id: poi?.id, name: poi?.name },
    sortProperty: name
  } as ClientLocation;
};

export const parseClientLocations = (clientLocationsData: any): ClientLocation[] =>
  map(clientLocationsData, (clientLocationData) => parseClientLocation(clientLocationData)).sort(
    (a: ClientLocation, b: ClientLocation) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    }
  );
