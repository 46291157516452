import * as React from 'react';

import { Route, NavLink, Link, useRouteMatch, Switch, withRouter } from 'react-router-dom';
import { Col, Navbar, Nav, Row } from 'react-bootstrap';

import DashboardContainer from './Dashboard/DashboardContainer';
import ConfirmationModalContainer from './Modal/ConfirmationModal';
import ClientLocationModalContainer from './Modal/ClientLocation/ClientLocationModal';
import ChangePasswordModalContainer from './Modal/ChangePasswordModal';

import { APP_TITLE, DASHBOARD_TITLE, LOGOUT_BUTTON_LABEL } from '../constants/labels';
import { ROUTE_DASHBOARD } from '../constants/constants';

import { LoggedInContainerProps } from '../@types/App.d';
import NotFound from './Common/NotFound';
import { AvailableFunctionality } from '../@types/State.d';

const LoggedInContainer: React.FC<LoggedInContainerProps> = (props: LoggedInContainerProps) => {
  const { availableFunctionality, logout } = props;
  const { url } = useRouteMatch();

  return (
    <div className="app-container">
      <Navbar expand="lg">
        <Navbar.Brand as={Link} to="/">
          {APP_TITLE}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {[
              ...[
                <Nav.Link key={1} as={NavLink} to={`/${ROUTE_DASHBOARD}`}>
                  {DASHBOARD_TITLE}
                </Nav.Link>
              ],
              ...availableFunctionality.map((func: AvailableFunctionality, index: number) => (
                <Nav.Link key={`${func.title}${index + 2}`} as={NavLink} to={`/${func.path}`}>
                  {func.title}
                </Nav.Link>
              ))
            ]}
          </Nav>
          <Nav className="justify-content-end">
            <Nav.Link onClick={() => logout()}>{LOGOUT_BUTTON_LABEL}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Row className="root-content-container">
        <Col className="root-content">
          <ConfirmationModalContainer />
          <ClientLocationModalContainer />
          <ChangePasswordModalContainer />
          <Switch>
            {[
              ...[
                <Route key={0} exact path={`${url}`}>
                  <DashboardContainer />
                </Route>,
                <Route key={1} path={`${url}${ROUTE_DASHBOARD}`}>
                  <DashboardContainer />
                </Route>
              ],
              ...availableFunctionality.map((func: AvailableFunctionality, index: number) => (
                <Route key={`${func.title}${index + 2}`} path={`${url}${func.path}`}>
                  {func.component}
                </Route>
              )),
              ...[
                <Route key={availableFunctionality.length + 4} path="*">
                  <NotFound />
                </Route>
              ]
            ]}
          </Switch>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(LoggedInContainer);
