import * as React from 'react';

import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { difference, map } from 'lodash';
import { withRouter } from 'react-router-dom';

import AddRemoveList from '../../../Common/AddRemoveList/AddRemoveList';

import { getAllUsers } from '../../../../util/api/userApi';
import {
  getClientUsers,
  addClientUser,
  deleteClientUser
} from '../../../../util/api/clientUserApi';

import {
  CLIENT_USERS_ASSIGNED_USERS,
  CLIENT_USERS_NOT_ASSIGNED_USERS,
  LIST_SEARCH_HINT_USERS
} from '../../../../constants/labels';

import { ClientUsersProps, ClientUsersState } from '../../../../@types/Clients.d';
import { ListItem } from '../../../../@types/Common.d';
import { GlobalState } from '../../../../@types/State.d';
import { User } from '../../../../@types/Model/User.d';
import { Client } from '../../../../@types/Model/Client.d';
import { UserAction } from '../../../../@types/Actions/User.d';
import { RequestAction } from '../../../../@types/Actions/UI.d';

class ClientsDetailsUsers extends React.Component<ClientUsersProps, ClientUsersState> {
  constructor(props: ClientUsersProps) {
    super(props);

    this.state = {
      hasChanged: false,
      notAssignedUsers: [],
      init: true
    };

    this.filterUsers = this.filterUsers.bind(this);
    this.addClientUser = this.addClientUser.bind(this);
    this.deleteClientUser = this.deleteClientUser.bind(this);
  }

  componentDidMount(): void {
    const { usersFetch, clientUsersFetch, client } = this.props;

    if (!client) return;

    usersFetch(client);
    clientUsersFetch(client);
  }

  componentDidUpdate(prevProps: ClientUsersProps): void {
    const { usersFetch, clientUsersFetch, client, users } = this.props;
    const { hasChanged, init } = this.state;

    if (!client) return;

    if (client?.id !== prevProps.client?.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ init: true }, () => {
        usersFetch(client);
        clientUsersFetch(client);
      });
    } else if (
      JSON.stringify(client?.users.allIds) !== JSON.stringify(prevProps.client?.users.allIds) ||
      JSON.stringify(users.allIds) !== JSON.stringify(prevProps.users.allIds) ||
      init
    ) {
      this.filterUsers();
    } else if (
      JSON.stringify(client?.users.allIds) === JSON.stringify(prevProps.client?.users.allIds) &&
      JSON.stringify(users.allIds) === JSON.stringify(prevProps.users.allIds) &&
      client.id === prevProps.client?.id &&
      hasChanged
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasChanged: false,
        init: false
      });
    }
  }

  filterUsers(): void {
    const { client, users } = this.props;

    if (!client?.users) return;

    const notAssignedUsersIds = difference(users.allIds, client.users.allIds);
    const notAssignedUsers = map(notAssignedUsersIds, (id: number) => users.byId[id]) as User[];

    this.setState({ notAssignedUsers, hasChanged: true, init: false });
  }

  addClientUser(userId: number): void {
    const { client, users, clientUserAdd } = this.props;

    clientUserAdd(client, users.byId[userId]);
  }

  deleteClientUser(userId: number): void {
    const { client, users, clientUserDelete } = this.props;

    clientUserDelete(client, users.byId[userId]);
  }

  render(): JSX.Element {
    const { client } = this.props;
    const { notAssignedUsers, hasChanged } = this.state;

    return (
      <div className="h-100 d-flex flex-column">
        <Row className="client-relations-container">
          {client ? (
            <>
              <Col sm={6} className="client-relation-list-col">
                <Row className="no-gutters">
                  <Col className="section-header">{CLIENT_USERS_NOT_ASSIGNED_USERS}</Col>
                </Row>
                <Row className="client-relation-list-row">
                  <Col className="p-0 h-100 d-flex flex-column">
                    <AddRemoveList
                      items={map(notAssignedUsers, (user) => {
                        const { id, prename, lastname, email } = user;
                        return {
                          id,
                          title: `${lastname}, ${prename}`,
                          subtitle: email
                        } as ListItem;
                      })}
                      notAssigned
                      hasChanged={hasChanged}
                      searchHint={LIST_SEARCH_HINT_USERS}
                      itemAdd={this.addClientUser}
                      itemDelete={this.deleteClientUser}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6} className="client-relation-list-col">
                <Row className="no-gutters">
                  <Col className="section-header">{CLIENT_USERS_ASSIGNED_USERS}</Col>
                </Row>
                <Row className="client-relation-list-row">
                  <Col className="p-0 h-100 d-flex flex-column">
                    <AddRemoveList
                      items={map(client?.users.allIds, (userId: number) => {
                        const { id, prename, lastname, email } = client?.users.byId[userId];
                        return {
                          id,
                          title: `${lastname}, ${prename}`,
                          subtitle: email
                        } as ListItem;
                      })}
                      hasChanged={hasChanged}
                      searchHint={LIST_SEARCH_HINT_USERS}
                      itemAdd={this.addClientUser}
                      itemDelete={this.deleteClientUser}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Col />
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): Pick<ClientUsersProps, 'client' | 'users'> => ({
  client: state.entities.clients.selectedItem,
  users: state.entities.users
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, RequestAction | UserAction>
): Pick<
  ClientUsersProps,
  'usersFetch' | 'clientUserAdd' | 'clientUserDelete' | 'clientUsersFetch'
> => ({
  usersFetch: () => dispatch(getAllUsers()),
  clientUsersFetch: (client: Client) => dispatch(getClientUsers(client)),
  clientUserAdd: (client: Client, user: User) => dispatch(addClientUser(client, user)),
  clientUserDelete: (client: Client, user: User) => dispatch(deleteClientUser(client, user))
});

const ClientsDetailsUsersContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsDetailsUsers)
);
export default ClientsDetailsUsersContainer;
