import * as React from 'react';

import { Button, Form } from 'react-bootstrap';

import ClientModuleDistribution from './ClientModuleDistribution';

import { BUTTON_SUBMIT, CLIENTS_MODULES_EMPTY } from '../../../../constants/labels';

import {
  ClientModuleListItemProps,
  ClientModuleListProps,
  ClientModuleListItemState,
  GetConfigDataInterface
} from '../../../../@types/Clients.d';
import { ModuleConfig } from '../../../../@types/Common.d';
import { AdditionalOptionKaufDa } from '../../../../@types/Model/AddtionalOption.d';
import { DistributionConfig } from '../../../../@types/Model/Distribution.d';
import ClientModuleKaufDa from './ClientModuleKaufDa';

class ClientModulesListItem extends React.Component<
  ClientModuleListItemProps,
  ClientModuleListItemState
> {
  configDivRef = React.createRef<HTMLDivElement>();

  configRef = React.createRef<GetConfigDataInterface>();

  buttonContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: ClientModuleListItemProps) {
    super(props);

    this.onChangeEnabled = this.onChangeEnabled.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onChangeModuleConfig = this.onChangeModuleConfig.bind(this);
  }

  onChangeEnabled(event: React.ChangeEvent<HTMLInputElement>): void {
    const { module, updateClientModule } = this.props;

    updateClientModule({ ...module, ...{ enabled: event.currentTarget.checked } });
  }

  onChangeModuleConfig(config: ModuleConfig): void {
    const { module, updateClientModule } = this.props;

    updateClientModule({ ...module, ...{ config } });
  }

  onClickSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();

    const { current } = this.configRef;

    if (current === null) return;

    const { module, updateClientModule } = this.props;

    updateClientModule({ ...module, ...{ config: current.getConfigData() } });
  }

  render(): JSX.Element {
    const { module } = this.props;
    const { title, enabled, type, config } = module;

    let configComponent;

    switch (type) {
      case 'BILBOARD':
        break;
      case 'DIALOG':
        break;
      case 'DISTRIBUTION':
        configComponent = (
          <ClientModuleDistribution
            ref={this.configRef as React.RefObject<ClientModuleDistribution>}
            containingDiv={this.configDivRef}
            distributionConfig={config as DistributionConfig}
          />
        );
        break;
      case 'LAYOUTDESIGN':
        break;
      case 'KAUFDA':
        configComponent = (
          <ClientModuleKaufDa
            ref={this.configRef as React.RefObject<ClientModuleKaufDa>}
            updateAdditionalOption={this.onChangeModuleConfig}
            kaufDa={module.config as AdditionalOptionKaufDa}
            containingDiv={this.configDivRef}
          />
        );
        break;
      default:
        break;
    }

    return (
      <div className="module-item-container">
        <div className="module-item-content-container">
          <div className="module-item">
            <div className="title">{title}</div>
            <Form.Check
              type="switch"
              id={`module-${module.id}`}
              label=""
              onChange={this.onChangeEnabled}
              checked={enabled}
            />
          </div>
          <div
            className={`module-item-config-container collapse-content height ${
              enabled ? 'shown' : 'hidden'
            }`}
          >
            {configComponent}
            {configComponent && (
              <div ref={this.buttonContainerRef} className="module-config-button-container">
                <Button variant="success" onClick={this.onClickSubmit}>
                  {BUTTON_SUBMIT}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const ClientModulesList: React.FC<ClientModuleListProps> = (props: ClientModuleListProps) => {
  const { modules, updateClientModule } = props;

  return (
    <div className="modules-list">
      {modules && modules.length > 0 ? (
        modules.map((module) => (
          <ClientModulesListItem
            key={module.id}
            module={module}
            updateClientModule={updateClientModule}
          />
        ))
      ) : (
        <div className="module-item-container">
          <div className="module-item">
            <div className="title">{CLIENTS_MODULES_EMPTY}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientModulesList;
