import * as React from 'react';

import { Col, Row, Button, Popover } from 'react-bootstrap';
import { ColorResult, SketchPicker } from 'react-color';

import { BUTTON_ABORT, BUTTON_APPLY } from '../../../constants/labels';

import { ColorPickerProps, ColorPickerState } from '../../../@types/Modal.d';

export default class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
  static onClickDiv(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.stopPropagation();
  }

  static onKeyDownDiv(event: React.KeyboardEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }

  constructor(props: ColorPickerProps) {
    super(props);

    const { color } = this.props;
    this.state = {
      color
    };
    this.onClickAccept = this.onClickAccept.bind(this);
    this.onClickAbort = this.onClickAbort.bind(this);
    this.onChangeComplete = this.onChangeComplete.bind(this);
  }

  onClickAccept(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    const { accept, abort } = this.props;
    const { color } = this.state;
    event.stopPropagation();

    accept(color);
    abort();
  }

  onClickAbort(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    const { abort } = this.props;
    event.stopPropagation();

    abort();
  }

  onChangeComplete(color: ColorResult): void {
    this.setState({ color: color.rgb });
  }

  render(): JSX.Element {
    const { color } = this.state;
    const { id } = this.props;

    return (
      <Popover id={`colorPicker${id}`} placement="auto" onClick={ColorPicker.onClickDiv}>
        <Popover.Content>
          <Row className="no-gutters">
            <SketchPicker
              color={color}
              onChangeComplete={this.onChangeComplete}
              width="330px"
              presetColors={[]}
            />
          </Row>
          <Row className="no-gutters">
            <Col md={6}>
              <Button variant="primary" onClick={this.onClickAccept}>
                {BUTTON_APPLY}
              </Button>
            </Col>
            <Col md={6}>
              <Button variant="primary" onClick={this.onClickAbort}>
                {BUTTON_ABORT}
              </Button>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );
  }
}
