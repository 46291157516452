import { map } from 'lodash';

import { Product } from '../../@types/Model/Product.d';

export const parseProduct = (productData: any): Product => {
  const { id, name, description, printDocRequired } = productData;
  return {
    id,
    name,
    description,
    printDocRequired,
    sortProperty: name
  } as Product;
};

export const parseProducts = (productsData: any): Product[] =>
  map(productsData, (productData) => parseProduct(productData)).sort((a: Product, b: Product) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });
