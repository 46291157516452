import { Dispatch } from 'redux';

import {
  addClientPrintLayout,
  fetchAllClientPrintLayouts,
  fetchAllPrintLayouts,
  removeClientPrintLayout,
  updateClientPrintLayout as updateClientPrintLayoutAction
} from '../../actions/printLayoutActions';
import { requestFailure, requestStarted, requestSuccess } from '../../actions/uiActions';

import {
  FETCH_ALL_PRINT_LAYOUTS,
  FETCH_ALL_CLIENT_PRINT_LAYOUTS,
  UPDATE_CLIENT_PRINT_LAYOUT,
  ADD_CLIENT_PRINT_LAYOUT,
  REMOVE_CLIENT_PRINT_LAYOUT
} from '../../constants/actionNames/printLayouts';
import { axiosClient } from '../../constants/constants';
import {
  fetchAllPrintLayoutsErrorTitle,
  fetchAllPrintLayoutsErrorContent,
  fetchAllClientPrintLayoutsErrorTitle,
  fetchAllClientPrintLayoutsErrorContent,
  updateClientPrintLayoutErrorTitle,
  updateClientPrintLayoutErrorContent,
  addClientPrintLayoutErrorTitle,
  addClientPrintLayoutErrorContent,
  removeClientPrintLayoutErrorTitle,
  removeClientPrintLayoutErrorContent
} from '../../constants/errorMessages';
import {
  PATH_GET_CLIENT_TEMPLATES,
  PATH_TEMPLATES_GET,
  PATH_ADD_CLIENT_TEMPLATE,
  PATH_DELETE_CLIENT_TEMPLATE
} from '../../constants/network';
import { extractPrintLayout, extractPrintLayouts } from '../responseUtil/printLayoutUtil';

import { Client } from '../../@types/Model/Client.d';
import { PrintLayout } from '../../@types/Common.d';

export const getAllPrintLayouts = () => async (dispatch: Dispatch) => {
  dispatch(requestStarted(FETCH_ALL_PRINT_LAYOUTS));

  const res = await axiosClient
    .get(PATH_TEMPLATES_GET, { validateStatus: (status: number): boolean => status < 300 })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_PRINT_LAYOUTS));
    return dispatch(fetchAllPrintLayouts(extractPrintLayouts(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_ALL_PRINT_LAYOUTS, {
      title: fetchAllPrintLayoutsErrorTitle,
      content: fetchAllPrintLayoutsErrorContent(res.response?.status ?? res.request.status ?? 404)
    })
  );
};

export const getAllClientPrintLayouts = (client: Client) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(FETCH_ALL_CLIENT_PRINT_LAYOUTS));

  const res = await axiosClient
    .get(PATH_GET_CLIENT_TEMPLATES(client.uuid), {
      validateStatus: (status: number): boolean => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_CLIENT_PRINT_LAYOUTS));
    return dispatch(fetchAllClientPrintLayouts(extractPrintLayouts(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_ALL_CLIENT_PRINT_LAYOUTS, {
      title: fetchAllClientPrintLayoutsErrorTitle,
      content: fetchAllClientPrintLayoutsErrorContent(
        res.response?.status ?? res.request.status ?? 404
      )
    })
  );
};

export const updateClientPrintLayout = (client: Client, printLayout: PrintLayout) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(UPDATE_CLIENT_PRINT_LAYOUT));

  const res = {
    status: 200,
    data: printLayout
  } as any;

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_CLIENT_PRINT_LAYOUT));
    return dispatch(updateClientPrintLayoutAction(extractPrintLayout(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_CLIENT_PRINT_LAYOUT, {
      title: updateClientPrintLayoutErrorTitle,
      content: updateClientPrintLayoutErrorContent(
        res.response?.status ?? res.request.status ?? 404
      )
    })
  );
};

export const addPrintLayout = (client: Client, printLayout: PrintLayout) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(ADD_CLIENT_PRINT_LAYOUT));

  const res = await axiosClient
    .put(
      PATH_ADD_CLIENT_TEMPLATE(client.uuid, printLayout.id),
      {},
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(ADD_CLIENT_PRINT_LAYOUT));
    return dispatch(addClientPrintLayout(printLayout));
  }

  return dispatch(
    requestFailure(ADD_CLIENT_PRINT_LAYOUT, {
      title: addClientPrintLayoutErrorTitle,
      content: addClientPrintLayoutErrorContent(res.response?.status ?? res.request.status ?? 404)
    })
  );
};

export const removePrintLayout = (client: Client, printLayout: PrintLayout) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(REMOVE_CLIENT_PRINT_LAYOUT));

  const res = await axiosClient
    .delete(PATH_DELETE_CLIENT_TEMPLATE(client.uuid, printLayout.id), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(REMOVE_CLIENT_PRINT_LAYOUT));
    return dispatch(removeClientPrintLayout(printLayout));
  }

  return dispatch(
    requestFailure(REMOVE_CLIENT_PRINT_LAYOUT, {
      title: removeClientPrintLayoutErrorTitle,
      content: removeClientPrintLayoutErrorContent(
        res.response?.status ?? res.request.status ?? 404
      )
    })
  );
};
