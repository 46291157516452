import * as React from 'react';
import { Form } from 'react-bootstrap';
import {
  ClientModuleKaufDaProps,
  ClientModuleKaufDaState,
  GetConfigDataInterface
} from '../../../../@types/Clients.d';
import { AdditionalOptionKaufDa } from '../../../../@types/Model/AddtionalOption.d';
import {
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE,
  CLIENT_ADDITIONAL_OPTION_PRESET_LABEL
} from '../../../../constants/labels';
import AdditionalOptionItemKaufDaDefault from './KaufDa/AdditionalOptionItemKaufDaDefault';
import AdditionalOptionItemKaufDaPreset from './KaufDa/AdditionalOptionItemKaufDaPreset';

export default class ClientModuleKaufDa
  extends React.Component<ClientModuleKaufDaProps, ClientModuleKaufDaState>
  implements GetConfigDataInterface {
  constructor(props: ClientModuleKaufDaProps) {
    super(props);

    const { kaufDa } = props;

    this.state = {
      kaufDa,
      validated: false
    };

    this.setAdditionalOption = this.setAdditionalOption.bind(this);
    this.getConfigData = this.getConfigData.bind(this);
    this.onChangeMode = this.onChangeMode.bind(this);
  }

  onChangeMode(event: React.ChangeEvent<HTMLInputElement>): void {
    const { kaufDa, updateAdditionalOption } = this.props;

    this.setState(
      {
        kaufDa: { ...kaufDa, ...{ mode: event.currentTarget.checked ? 'PRESET' : 'DEFAULT' } }
      },
      updateAdditionalOption(kaufDa)
    );
  }

  setAdditionalOption(kaufDa: AdditionalOptionKaufDa): void {
    const { updateAdditionalOption } = this.props;
    this.setState({ kaufDa }, updateAdditionalOption(kaufDa));
  }

  getConfigData(): AdditionalOptionKaufDa {
    const { kaufDa } = this.state;

    return kaufDa;
  }

  render(): JSX.Element {
    const { containingDiv } = this.props;
    const { kaufDa, validated } = this.state;
    const { mode, id } = kaufDa;

    const formId = `additional-options-${id}-form`;

    return (
      <div ref={containingDiv} className="module-config">
        <div className="module-config-category">
          <div className="title">{CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE}</div>
          <div className="content">
            <div className="client-additional-option-header preset">
              <div className="switch">
                <Form.Check
                  type="switch"
                  id={`additional-option-${id ?? -1}-mode`}
                  label=""
                  onChange={this.onChangeMode}
                  checked={mode === 'PRESET'}
                />
              </div>
              <div>{CLIENT_ADDITIONAL_OPTION_PRESET_LABEL}</div>
            </div>
            <Form id={formId} onSubmit={() => {}} noValidate validated={validated}>
              {mode === 'DEFAULT' ? (
                <AdditionalOptionItemKaufDaDefault
                  additionalOption={kaufDa}
                  changeAdditionalOption={this.setAdditionalOption}
                />
              ) : (
                <AdditionalOptionItemKaufDaPreset
                  additionalOption={kaufDa}
                  changeAdditionalOption={this.setAdditionalOption}
                />
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
