import { combineReducers } from 'redux';

import uiBasicReducer from './uiBasicReducer';
import modalsReducer from './modals/modalReducer';

const uiReducer = combineReducers({
  basic: uiBasicReducer,
  modals: modalsReducer
});

export default uiReducer;
