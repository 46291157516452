import { map } from 'lodash';
import moment from 'moment';

import { parseProduct } from './productResponseUtil';

import { TRANSMISSIONTYPE_OFFER } from '../../constants/constants';

import { Dict, DictState, OfferSheetTemplate, Module, PrintLayout } from '../../@types/Common.d';
import { Client, ClientLocation } from '../../@types/Model/Client.d';
import { User } from '../../@types/Model/User.d';
import { Product } from '../../@types/Model/Product.d';
import { Layer } from '../../@types/Model/Map.d';
import {
  DistributionTemplateLocation,
  DistributionAppointment
} from '../../@types/Model/Distribution.d';
import { Offer, Order } from '../../@types/Model/OfferOrder.d';
import {
  AdditionalOptionSocialMedia,
  AdditionalOptionKaufDa,
  AdditionalOption,
  SocialMediaTargetGroup
} from '../../@types/Model/AddtionalOption.d';

export function extractOfferSheetTemplate(responseOfferSheetTemplate: any): OfferSheetTemplate {
  const { id, name } = responseOfferSheetTemplate;

  return { id, name } as OfferSheetTemplate;
}

export function extractOfferSheetTemplates(
  responseOfferSheetTemplates: any[]
): OfferSheetTemplate[] {
  return responseOfferSheetTemplates.map((responseOfferSheetTemplate) =>
    extractOfferSheetTemplate(responseOfferSheetTemplate)
  );
}

export const parseClient = (clientData: any): Client => {
  const {
    id,
    uuid,
    emailContactPerson,
    freyMedia,
    generateOffer,
    name,
    transmissionType,
    billingType,
    billCity,
    billHousenumber,
    billName,
    billPostcode,
    billStreet,
    weekparts,
    showPrice,
    internalClient,
    planningRestriction,
    distributionDateType,
    offerSheetTemplate
  } = clientData;

  // TODO client locations
  return {
    id,
    uuid,
    emailContactPerson,
    freyMedia,
    generateOffer,
    name,
    transmissionType,
    billingType,
    billCity,
    billHousenumber,
    billName,
    billPostcode,
    billStreet,
    clientLocations: {
      allIds: [] as number[],
      byId: {} as Dict<ClientLocation>
    } as DictState<ClientLocation>,
    users: { allIds: [] as number[], byId: {} as Dict<User> } as DictState<User>,
    products: { allIds: [] as number[], byId: {} as Dict<Product> } as DictState<Product>,
    layers: { allIds: [] as number[], byId: {} as Dict<Layer> } as DictState<Layer>,
    offerOrder:
      transmissionType === TRANSMISSIONTYPE_OFFER
        ? ({ allIds: [] as number[], byId: {} as Dict<Offer> } as DictState<Offer>)
        : ({ allIds: [] as number[], byId: {} as Dict<Order> } as DictState<Order>),
    additionalOptions: {
      allIds: [] as number[],
      byId: {} as Dict<AdditionalOption>
    } as DictState<AdditionalOption>,
    modules: { allIds: [] as number[], byId: {} as Dict<Module> } as DictState<Module>,
    printLayouts: {
      allIds: [] as number[],
      byId: {} as Dict<PrintLayout>
    } as DictState<PrintLayout>,
    distributionAppointments: {
      allIds: [] as number[],
      byId: {} as Dict<DistributionAppointment>
    } as DictState<DistributionAppointment>,
    sortProperty: name,
    weekparts,
    planningRestriction,
    distributionDateType,
    internalClient,
    showPrice,
    offerSheetTemplate: offerSheetTemplate
      ? extractOfferSheetTemplate(offerSheetTemplate)
      : undefined
  } as Client;
};

export const parseClients = (clientsData: any): Client[] =>
  map(clientsData, (clientData) => parseClient(clientData)).sort((a: Client, b: Client) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });

export function extractDistributionTemplateLocation(
  responseDistributionTemplateLocation: any
): DistributionTemplateLocation {
  const { areas, locationId, locationSrcId, id, name } = responseDistributionTemplateLocation;
  return {
    locationId: locationId ?? locationSrcId ?? id,
    name,
    areas: map(areas, (area) => ({
      areaKey: area.areaKey,
      name: area.name ?? area.areaKey,
      localities: map(area.localities, (locality) => ({
        id: locality.id,
        name: locality.name,
        localityKey: locality.localityKey,
        selected: locality.selected,
        circulation: locality.circulation ?? 0
      }))
    }))
  } as DistributionTemplateLocation;
}

export function extractDistributionTemplateLocations(
  responseDistributionTemplateLocations: any
): DistributionTemplateLocation[] {
  return map(responseDistributionTemplateLocations, (responseDistributionTemplateLocation) =>
    extractDistributionTemplateLocation(responseDistributionTemplateLocation)
  );
}

export function extractOrderTemplate(responseOrderTemplate: any): Order {
  const {
    id,
    actionName,
    distributionDay,
    locations,
    weekpart,
    copiesToSubsidiary,
    city,
    company,
    email,
    forname,
    housenumber,
    phone,
    postcode,
    salutation,
    street,
    product,
    surename
  } = responseOrderTemplate;
  const sDistributionDay = moment(distributionDay).format('DD.MM.YYYY');

  return {
    id,
    actionName: actionName.length > 0 ? actionName : `Bestellung vom ${sDistributionDay}`,
    distributionDay: sDistributionDay,
    weekpart,
    copiesToSubsidiary,
    city,
    company,
    email,
    prename: forname,
    housenumber,
    phone,
    postcode,
    salutation,
    street,
    lastname: surename,
    locations: extractDistributionTemplateLocations(locations),
    product: parseProduct(product ?? []),
    sortProperty: distributionDay
  } as Order;
}

export function extractOrderTemplates(responseOrderTemplates: any): Order[] {
  return map(responseOrderTemplates, (responseOrderTemplate) =>
    extractOrderTemplate(responseOrderTemplate)
  );
}

export function extractOfferTemplate(responseOrderTemplate: any): Offer {
  const {
    id,
    actionName,
    locations,
    weekpart,
    copiesToSubsidiary,
    city,
    distributionDay,
    company,
    email,
    forname,
    housenumber,
    phone,
    postcode,
    salutation,
    street,
    surename,
    product
  } = responseOrderTemplate;
  const sDistributionDay = moment(distributionDay).format('DD.MM.YYYY');
  return {
    id,
    actionName: actionName.length > 0 ? actionName : `Bestellung vom ${sDistributionDay}`,
    distributionDay: sDistributionDay,
    weekpart,
    copiesToSubsidiary,
    city,
    company,
    email,
    prename: forname,
    housenumber,
    phone,
    postcode,
    salutation,
    street,
    lastname: surename,
    locations: extractDistributionTemplateLocations(locations),
    product: parseProduct(product ?? []),
    sortProperty: distributionDay
  } as Offer;
}

export function extractOfferTemplates(responseOfferTemplates: any): Offer[] {
  return map(responseOfferTemplates, (responseOfferTemplate) =>
    extractOfferTemplate(responseOfferTemplate)
  );
}

export function extractAdditionalOption(responseAdditionalOption: any): AdditionalOption {
  const { data, enabled, id, platform, mode, price, type } = responseAdditionalOption;

  let optionsData;

  try {
    optionsData = JSON.parse(data);
  } catch (e) {
    optionsData = {};
  }

  if (type === 'KAUFDA')
    return {
      mode,
      type,
      enabled,
      id,
      price,
      defaultRange: optionsData.range ?? optionsData.defaultRange,
      defaultDuration: optionsData.duration ?? optionsData.defaultDuration,
      defaultHomepage: optionsData.homepage ?? optionsData.defaultHomepage,
      presetSelectionRanges: optionsData.presetSelectionRanges ?? [],
      presetSelectionDurations: optionsData.presetSelectionDurations ?? [],
      presetSelectionHomepages: optionsData.presetSelectionHomepages ?? [],
      presetValuesRanges: optionsData.presetValuesRanges ?? [],
      presetValuesHomepages: optionsData.presetValuesHomepages ?? [],
      sortProperty: 'type'
    } as AdditionalOptionKaufDa;
  if (type === 'SOCIALMEDIA')
    return {
      mode,
      type,
      enabled,
      id,
      price,
      platform,
      defaultRange: optionsData.range ?? optionsData.defaultRange,
      defaultDuration: optionsData.duration ?? optionsData.defaultDuration,
      defaultBudget: optionsData.budget ?? optionsData.defaultBudget,
      defaultTargetGroups: (optionsData.defaultTargetGroups?.map(
        (group: any) => ({ id: group.id, name: group.name } as SocialMediaTargetGroup)
      ) ?? []) as SocialMediaTargetGroup[],
      presetSelectionRanges: optionsData.presetSelectionRanges ?? [],
      presetSelectionDurations: optionsData.presetSelectionDurations ?? [],
      presetSelectionBudgets: optionsData.presetSelectionBudgets ?? [],
      presetSelectionTargetGroups: optionsData.presetSelectionTargetGroups ?? [],
      presetValuesRanges: optionsData.presetValuesRanges ?? [],
      presetValuesDurations: optionsData.presetValuesDurations ?? [],
      presetValuesBudgets: optionsData.presetValuesBudgets ?? [],
      presetValuesTargetGroups: optionsData.presetValuesTargetGroups ?? [],
      sortProperty: 'platform'
    } as AdditionalOptionSocialMedia;

  return { id, type } as AdditionalOption;
}

export function extractAdditionalOptions(responseAdditionalOptions: any[]): AdditionalOption[] {
  return responseAdditionalOptions.map((responseAdditionalOption) =>
    extractAdditionalOption(responseAdditionalOption)
  );
}

export function extractDistributionAppointment(
  responseDistributionAppointment: any
): DistributionAppointment {
  const { id, date, name, type } = responseDistributionAppointment;
  const cDate = moment(date);

  return {
    id,
    date: cDate,
    name,
    type,
    sortProperty: `${cDate.unix()}`
  } as DistributionAppointment;
}

export function extractDistributionAppointments(
  responseDistributionAppointments: any[]
): DistributionAppointment[] {
  return responseDistributionAppointments.map((responseDistributionAppointment: any) =>
    extractDistributionAppointment(responseDistributionAppointment)
  );
}

export function extractClientModule(responseModule: any): Module {
  const { title, enabled, id, config, type } = responseModule;

  return { id, title, enabled, config, type, sortProperty: title } as Module;
}

export function extractClientModules(responseModules: any): Module[] {
  return responseModules.map((responseModule: any) => extractClientModule(responseModule));
}

export function extractPrintLayout(responsePrintTemplate: any): PrintLayout {
  const { name, id } = responsePrintTemplate;

  return { id, name } as PrintLayout;
}

export function extractPrintTemplates(responsePrintLayouts: any): PrintLayout[] {
  return responsePrintLayouts
    .map((responsePrintLayout: any) => extractPrintLayout(responsePrintLayout))
    .sort((a: PrintLayout, b: PrintLayout) => {
      if (a.name < b.name) return 1;
      if (a.name > b.name) return -1;
      return 0;
    });
}
