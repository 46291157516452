import * as React from 'react';
import { Form } from 'react-bootstrap';

import { LayerTypeDisplayMatcher, LAYER_TYPES } from '../../../../constants/constants';
import { CLIENTS_MODULES_CONFIG_DISTRIBUTORS } from '../../../../constants/labels';

import {
  ClientModuleDistributionProps,
  ClientModuleDistributionState,
  GetConfigDataInterface
} from '../../../../@types/Clients.d';
import { LayerType } from '../../../../@types/Model/Map.d';
import { DistributionConfig } from '../../../../@types/Model/Distribution.d';

export default class ClientModuleDistribution
  extends React.Component<ClientModuleDistributionProps, ClientModuleDistributionState>
  implements GetConfigDataInterface {
  constructor(props: ClientModuleDistributionProps) {
    super(props);

    const { distributionConfig } = this.props;
    const { enabledLayerTypes } = distributionConfig;

    this.state = {
      selectedLayerTypes: enabledLayerTypes
    };

    this.onChangeSelectedLayerTypes = this.onChangeSelectedLayerTypes.bind(this);

    this.getConfigData = this.getConfigData.bind(this);
  }

  onChangeSelectedLayerTypes(
    event: React.ChangeEvent<HTMLInputElement>,
    layerType: LayerType
  ): void {
    const { selectedLayerTypes } = this.state;
    const selected = event.currentTarget.checked;

    let nSelectedTypes = [...selectedLayerTypes];

    if (selected) nSelectedTypes = [...nSelectedTypes, ...[layerType]] as LayerType[];
    else {
      const index = selectedLayerTypes.indexOf(layerType);

      if (index < 0) return;

      nSelectedTypes = [
        ...nSelectedTypes.slice(0, index),
        nSelectedTypes.slice(index + 1)
      ] as LayerType[];
    }

    this.setState({ selectedLayerTypes: nSelectedTypes });
  }

  getConfigData(): DistributionConfig {
    const { selectedLayerTypes } = this.state;

    return { enabledLayerTypes: selectedLayerTypes } as DistributionConfig;
  }

  render(): JSX.Element {
    const { containingDiv } = this.props;
    const { selectedLayerTypes } = this.state;

    return (
      <div ref={containingDiv} className="module-config">
        <div className="module-config-category">
          <div className="title">{CLIENTS_MODULES_CONFIG_DISTRIBUTORS}</div>
          <div className="content">
            {LAYER_TYPES.map((type) => (
              <Form.Group key={type}>
                <Form.Check
                  custom
                  type="switch"
                  checked={selectedLayerTypes.indexOf(type) !== -1}
                  id={`layer-type-${type}-check`}
                  label={LayerTypeDisplayMatcher[type].title}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.onChangeSelectedLayerTypes(event, type)
                  }
                />
              </Form.Group>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
