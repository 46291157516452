import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';

import ClientDetailsClientForm from './ClientDetailsClientForm';

import {
  requestStarted,
  requestSuccess,
  requestFailure,
  showConfirmationModal
} from '../../../actions/uiActions';

import {
  getClient,
  createClient,
  updateClient,
  deleteClient,
  getOfferSheetTemplates
} from '../../../util/api/clientsApi';
import {
  extractOfferSheetTemplates,
  parseClient
} from '../../../util/responseUtil/clientResponseUtil';

import {
  fetchClientErrorTitle,
  fetchClientErrorContent,
  fetchOfferSheetTemplatesErrorTitle,
  fetchOfferSheetTemplatesErrorContent
} from '../../../constants/errorMessages';
import { TRANSMISSIONTYPE_OFFER } from '../../../constants/constants';
import {
  BUTTON_APPLY,
  BUTTON_CREATE,
  BUTTON_DELETE,
  DELETE_CONFIRMATION_MODAL_TITLE,
  DELETE_CONFIRMATION_MODAL_CONTENT
} from '../../../constants/labels';
import {
  FETCH_CLIENT,
  FETCH_OFFER_SHEET_TEMPLATES
} from '../../../constants/actionNames/clients/clients';

import { ClientsDetailsClientProps, ClientsDetailsClientState } from '../../../@types/Clients.d';
import {
  Weekpart,
  PlanningRestriction,
  Dict,
  DictState,
  InternalClient,
  OfferSheetTemplate
} from '../../../@types/Common.d';
import { GlobalState } from '../../../@types/State.d';
import { ErrorMessage, ConfirmationMessage } from '../../../@types/Model/Message.d';
import { Client, ClientLocation } from '../../../@types/Model/Client.d';
import { TransmissionType, Offer, Order, BillingType } from '../../../@types/Model/OfferOrder.d';
import { User } from '../../../@types/Model/User.d';
import { Layer } from '../../../@types/Model/Map.d';
import { AdditionalOption } from '../../../@types/Model/AddtionalOption.d';
import {
  DistributionDateType,
  DistributionAppointment
} from '../../../@types/Model/Distribution.d';
import { Product } from '../../../@types/Model/Product.d';
import { ClientAction } from '../../../@types/Actions/Client/Client.d';
import { UIAction } from '../../../@types/Actions/UI.d';

class ClientsDetailsClient extends React.Component<
  ClientsDetailsClientProps,
  ClientsDetailsClientState
> {
  constructor(props: ClientsDetailsClientProps) {
    super(props);

    this.state = {
      validated: false,
      emailContactPerson: '',
      internalClient: 'FREYPLUS_MEDIA',
      generateOffer: false,
      name: '',
      transmissionType: 'OFFER',
      billingType: 'CLIENT',
      billCity: '',
      billHousenumber: '',
      billName: '',
      billPostcode: '',
      billStreet: '',
      planningRestriction: 'NONE',
      distributionDateType: 'FREE',
      weekparts: [],
      showPrice: false,
      offerSheetTemplates: []
    };

    this.changeEmailContactPerson = this.changeEmailContactPerson.bind(this);
    this.changeInternalClient = this.changeInternalClient.bind(this);
    this.changeGenerateOffer = this.changeGenerateOffer.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeTransmissionType = this.changeTransmissionType.bind(this);
    this.changeBillingType = this.changeBillingType.bind(this);
    this.changeBillCity = this.changeBillCity.bind(this);
    this.changeBillHousenumber = this.changeBillHousenumber.bind(this);
    this.changeBillName = this.changeBillName.bind(this);
    this.changeBillPostcode = this.changeBillPostcode.bind(this);
    this.changeBillStreet = this.changeBillStreet.bind(this);
    this.changeWeekparts = this.changeWeekparts.bind(this);
    this.changePlanningRestriction = this.changePlanningRestriction.bind(this);
    this.changeShowPrice = this.changeShowPrice.bind(this);
    this.changeDistributionDayType = this.changeDistributionDayType.bind(this);
    this.changeOfferSheetTemplate = this.changeOfferSheetTemplate.bind(this);

    this.onSubmit = this.onSubmit.bind(this);

    this.fetchClient = this.fetchClient.bind(this);
    this.deleteClient = this.deleteClient.bind(this);
    this.deleteClientCallback = this.deleteClientCallback.bind(this);
    this.fetchOfferSheetTemplates = this.fetchOfferSheetTemplates.bind(this);
  }

  componentDidMount(): void {
    const { client } = this.props;

    if (client) {
      this.fetchClient(client.uuid);
      this.fetchOfferSheetTemplates();
    }
  }

  componentDidUpdate(prevProps: ClientsDetailsClientProps): void {
    const { client } = this.props;
    if (prevProps.client?.id !== client?.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        validated: false,
        emailContactPerson: '',
        internalClient: 'FREYPLUS_MEDIA',
        generateOffer: false,
        name: '',
        transmissionType: 'OFFER',
        billingType: 'CLIENT',
        billCity: '',
        billHousenumber: '',
        billName: '',
        billPostcode: '',
        billStreet: '',
        planningRestriction: 'NONE',
        distributionDateType: 'FREE',
        weekparts: [],
        showPrice: false,
        offerSheetTemplate: undefined,
        offerSheetTemplates: []
      });

      if (client) {
        this.fetchClient(client.uuid);
        this.fetchOfferSheetTemplates();
      }
    }
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        const { client, clientUpdate, clientCreate } = this.props;
        const {
          id,
          emailContactPerson,
          internalClient,
          generateOffer,
          name,
          transmissionType,
          billingType,
          billCity,
          billHousenumber,
          billName,
          billPostcode,
          billStreet,
          planningRestriction,
          distributionDateType,
          weekparts,
          showPrice
        } = this.state;

        const clientNew = {
          id,
          emailContactPerson,
          internalClient,
          generateOffer,
          name,
          transmissionType,
          billingType,
          billCity,
          billHousenumber,
          billName,
          billPostcode,
          billStreet,
          planningRestriction,
          distributionDateType,
          weekparts,
          showPrice,
          clientLocations: {
            allIds: [] as number[],
            byId: {} as Dict<ClientLocation>
          } as DictState<ClientLocation>,
          users: { allIds: [] as number[], byId: {} as Dict<User> } as DictState<User>,
          products: { allIds: [] as number[], byId: {} as Dict<Product> } as DictState<Product>,
          layers: { allIds: [] as number[], byId: {} as Dict<Layer> } as DictState<Layer>,
          additionalOptions: {
            allIds: [] as number[],
            byId: {} as Dict<AdditionalOption>
          } as DictState<AdditionalOption>,
          distributionAppointments: {
            allIds: [] as number[],
            byId: {} as Dict<DistributionAppointment>
          } as DictState<DistributionAppointment>,
          offerOrder:
            transmissionType === TRANSMISSIONTYPE_OFFER
              ? ({ allIds: [] as number[], byId: {} as Dict<Offer> } as DictState<Offer>)
              : ({ allIds: [] as number[], byId: {} as Dict<Order> } as DictState<Order>),
          sortProperty: ''
        } as Client;

        if (client) {
          clientUpdate(clientNew);
        } else {
          clientCreate(clientNew);
        }
      }
    });
  }

  deleteClient(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();

    const { showConfirmation } = this.props;

    showConfirmation({
      title: DELETE_CONFIRMATION_MODAL_TITLE,
      content: DELETE_CONFIRMATION_MODAL_CONTENT,
      confirmationCallback: this.deleteClientCallback
    });
  }

  deleteClientCallback(): void {
    const { clientDelete, client } = this.props;

    clientDelete(client);
  }

  async fetchClient(clientUUID: string | undefined): Promise<void> {
    if (!clientUUID) return;

    const { reqStarted, reqSuccess, reqFailure } = this.props;
    reqStarted(FETCH_CLIENT);
    const res = await getClient(clientUUID);

    if (res.status < 300) {
      const client = parseClient(res.data);

      this.setState(
        {
          validated: false,
          id: client?.id,
          emailContactPerson: client?.emailContactPerson ?? '',
          internalClient: client?.internalClient ?? 'FREYPLUS_MEDIA',
          generateOffer: client?.generateOffer ?? false,
          showPrice: client?.showPrice ?? false,
          name: client?.name ?? '',
          transmissionType: client?.transmissionType ?? 'OFFER',
          billingType: client?.billingType ?? 'CLIENT',
          billCity: client?.billCity ?? '',
          billHousenumber: client?.billHousenumber ?? '',
          billName: client?.billName ?? '',
          billPostcode: client?.billPostcode ?? '',
          billStreet: client?.billStreet ?? '',
          planningRestriction: client?.planningRestriction ?? 'NONE',
          distributionDateType: client?.distributionDateType ?? 'FREE',
          weekparts: client?.weekparts ?? ([] as Weekpart[]),
          offerSheetTemplate: client?.offerSheetTemplate
        },
        () => reqSuccess(FETCH_CLIENT)
      );
      return;
    }

    reqFailure(FETCH_CLIENT, {
      title: fetchClientErrorTitle,
      content: fetchClientErrorContent(res.response?.status ?? res.request?.status ?? 404)
    });
  }

  fetchOfferSheetTemplates(): void {
    const { reqStarted, reqSuccess, reqFailure } = this.props;

    reqStarted(FETCH_OFFER_SHEET_TEMPLATES);
    const res = getOfferSheetTemplates();

    if (res.status < 300) {
      const offerSheetTemplates = extractOfferSheetTemplates(res.data);

      this.setState({ offerSheetTemplates }, () => reqSuccess(FETCH_OFFER_SHEET_TEMPLATES));

      return;
    }

    reqFailure(FETCH_OFFER_SHEET_TEMPLATES, {
      title: fetchOfferSheetTemplatesErrorTitle,
      content: fetchOfferSheetTemplatesErrorContent(
        res.response?.status ?? res.request?.status ?? 404
      )
    });
  }

  changeEmailContactPerson(emailContactPerson: string): void {
    this.setState({ emailContactPerson });
  }

  changeInternalClient(internalClient: InternalClient): void {
    this.setState({ internalClient });
  }

  changeBillingType(billingType: BillingType): void {
    this.setState({ billingType });
  }

  changeShowPrice(showPrice: boolean): void {
    this.setState({ showPrice });
  }

  changeGenerateOffer(generateOffer: boolean): void {
    this.setState({ generateOffer });
  }

  changeName(name: string): void {
    this.setState({ name });
  }

  changeTransmissionType(transmissionType: TransmissionType): void {
    this.setState({ transmissionType });
  }

  changePlanningRestriction(planningRestriction: PlanningRestriction): void {
    this.setState({ planningRestriction });
  }

  changeDistributionDayType(distributionDateType: DistributionDateType): void {
    this.setState({ distributionDateType }, () => {
      if (distributionDateType === 'TEMPLATE') this.changeWeekparts(['BEST']);
    });
  }

  changeBillCity(billCity: string): void {
    this.setState({ billCity });
  }

  changeBillHousenumber(billHousenumber: string): void {
    this.setState({ billHousenumber });
  }

  changeBillName(billName: string): void {
    this.setState({ billName });
  }

  changeBillPostcode(billPostcode: string): void {
    this.setState({ billPostcode });
  }

  changeBillStreet(billStreet: string): void {
    this.setState({ billStreet });
  }

  changeWeekparts(weekparts: Weekpart[]): void {
    this.setState({ weekparts });
  }

  changeOfferSheetTemplate(offerSheetTemplate: OfferSheetTemplate): void {
    this.setState({ offerSheetTemplate });
  }

  render(): JSX.Element {
    const {
      validated,
      emailContactPerson,
      internalClient,
      generateOffer,
      name,
      transmissionType,
      billingType,
      billCity,
      billHousenumber,
      billName,
      billPostcode,
      billStreet,
      weekparts,
      showPrice,
      planningRestriction,
      distributionDateType,
      offerSheetTemplate,
      offerSheetTemplates
    } = this.state;
    const { client } = this.props;

    return (
      <Form
        id="client-detail-form"
        className="client-detail-form"
        onSubmit={this.onSubmit}
        noValidate
        validated={validated}
      >
        <ClientDetailsClientForm
          emailContactPerson={emailContactPerson}
          internalClient={internalClient}
          generateOffer={generateOffer}
          name={name}
          transmissionType={transmissionType}
          billingType={billingType}
          billCity={billCity}
          billHousenumber={billHousenumber}
          billName={billName}
          billPostcode={billPostcode}
          billStreet={billStreet}
          weekparts={weekparts}
          showPrice={showPrice}
          planningRestriction={planningRestriction}
          distributionDateType={distributionDateType}
          offerSheetTemplate={offerSheetTemplate}
          offerSheetTemplates={offerSheetTemplates}
          changeEmailContactPerson={this.changeEmailContactPerson}
          changeInternalClient={this.changeInternalClient}
          changeGenerateOffer={this.changeGenerateOffer}
          changeName={this.changeName}
          changeTransmissionType={this.changeTransmissionType}
          changeBillingType={this.changeBillingType}
          changeBillCity={this.changeBillCity}
          changeBillHousenumber={this.changeBillHousenumber}
          changeBillName={this.changeBillName}
          changeBillPostcode={this.changeBillPostcode}
          changeBillStreet={this.changeBillStreet}
          changePlanningRestrictions={this.changePlanningRestriction}
          changeDistributionDayType={this.changeDistributionDayType}
          changeWeekparts={this.changeWeekparts}
          changeShowPrice={this.changeShowPrice}
          changeOfferSheetTemplate={this.changeOfferSheetTemplate}
        />
        <Row className="no-gutters">
          <Col sm={12} xl={4}>
            <Row className="no-gutters">
              <Col sm={4} className="p-1">
                <Button type="submit" variant="success" block>
                  {client ? BUTTON_APPLY : BUTTON_CREATE}
                </Button>
              </Col>
              {client && (
                <Col sm={4} className="p-1">
                  <Button variant="danger" className="ml-1" block onClick={this.deleteClient}>
                    {BUTTON_DELETE}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: GlobalState): Pick<ClientsDetailsClientProps, 'client'> => ({
  client: state.entities.clients.selectedItem
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, UIAction | ClientAction>
): Pick<
  ClientsDetailsClientProps,
  | 'reqStarted'
  | 'reqFailure'
  | 'reqSuccess'
  | 'clientUpdate'
  | 'clientCreate'
  | 'clientDelete'
  | 'showConfirmation'
> => ({
  reqStarted: (payload: string) => dispatch(requestStarted(payload)),
  reqSuccess: (payload: string) => dispatch(requestSuccess(payload)),
  reqFailure: (payload: string, errorMessage: ErrorMessage) =>
    dispatch(requestFailure(payload, errorMessage)),
  clientUpdate: (client: Client) => dispatch(updateClient(client)),
  clientCreate: (client: Client) => dispatch(createClient(client)),
  clientDelete: (client: Client) => dispatch(deleteClient(client)),
  showConfirmation: (confirmationMessage: ConfirmationMessage) =>
    dispatch(showConfirmationModal(confirmationMessage))
});

const ClientsDetailsClientContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsDetailsClient)
);
export default ClientsDetailsClientContainer;
