import {
  ADD_CLIENT_PRINT_LAYOUT,
  FETCH_ALL_CLIENT_PRINT_LAYOUTS,
  FETCH_ALL_PRINT_LAYOUTS,
  REMOVE_CLIENT_PRINT_LAYOUT,
  UPDATE_CLIENT_PRINT_LAYOUT
} from '../constants/actionNames/printLayouts';

import {
  AddClientPrintLayoutAction,
  FetchAllClientPrintLayoutsAction,
  FetchAllPrintLayoutsAction,
  RemoveClientPrintLayoutAction,
  UpdateClientPrintLayoutAction
} from '../@types/Actions/PrintLayout.d';
import { PrintLayout } from '../@types/Common.d';

export const fetchAllPrintLayouts = (printLayouts: PrintLayout[]): FetchAllPrintLayoutsAction => ({
  type: FETCH_ALL_PRINT_LAYOUTS,
  printLayouts
});

export const fetchAllClientPrintLayouts = (
  printLayouts: PrintLayout[]
): FetchAllClientPrintLayoutsAction => ({
  type: FETCH_ALL_CLIENT_PRINT_LAYOUTS,
  printLayouts
});

export const updateClientPrintLayout = (
  printLayout: PrintLayout
): UpdateClientPrintLayoutAction => ({
  type: UPDATE_CLIENT_PRINT_LAYOUT,
  printLayout
});

export const addClientPrintLayout = (printLayout: PrintLayout): AddClientPrintLayoutAction => ({
  type: ADD_CLIENT_PRINT_LAYOUT,
  printLayout
});

export const removeClientPrintLayout = (
  printLayout: PrintLayout
): RemoveClientPrintLayoutAction => ({
  type: REMOVE_CLIENT_PRINT_LAYOUT,
  printLayout
});
