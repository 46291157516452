import {
  FETCH_ALL_CLIENTS,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  SELECT_CLIENT
} from '../../constants/actionNames/clients/clients';

import { Client } from '../../@types/Model/Client.d';
import {
  FetchAllClientsAction,
  CreateClientsAction,
  UpdateClientsAction,
  DeleteClientsAction,
  SelectClient
} from '../../@types/Actions/Client/Client.d';

export const fetchAllClientsAction = (clients: Client[]): FetchAllClientsAction => ({
  type: FETCH_ALL_CLIENTS,
  clients
});

export const createClientsAction = (client: Client): CreateClientsAction => ({
  type: CREATE_CLIENT,
  client
});

export const updateClientsAction = (client: Client): UpdateClientsAction => ({
  type: UPDATE_CLIENT,
  client
});

export const deleteClientsAction = (client: Client): DeleteClientsAction => ({
  type: DELETE_CLIENT,
  client
});

export const selectClientAction = (client: Client): SelectClient => ({
  type: SELECT_CLIENT,
  client
});
