import * as React from 'react';
import { Row } from 'react-bootstrap';

import { map } from 'lodash';

import DashboardButton from './DashboardButton';

import { DashboardButtonAreaProps, DashboardButtonAreaState } from '../../@types/Dashboad.d';

export default class DashboardButtonArea extends React.Component<
  DashboardButtonAreaProps,
  DashboardButtonAreaState
> {
  constructor(props: DashboardButtonAreaProps) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    const { availableFunctionality } = this.props;

    return (
      <Row className="dashboard-button-area">
        {map(availableFunctionality, (func, index: number) => (
          <DashboardButton key={index} availableFunctionality={func} />
        ))}
      </Row>
    );
  }
}
