import * as React from 'react';

import ClientsDetailsClientContainer from './ClientsDetails/ClientsDetailsClient';

import { CLIENTS_NEW } from '../../constants/labels';

import { ClientsNewProps } from '../../@types/Clients.d';

const ClientsNew: React.FC<ClientsNewProps> = () => (
  <div>
    <div className="title">{CLIENTS_NEW}</div>
    <div className="tab-container">
      <ClientsDetailsClientContainer />
    </div>
  </div>
);

export default ClientsNew;
