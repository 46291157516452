import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { difference } from 'lodash';

import {
  updateClientPrintLayout,
  getAllClientPrintLayouts,
  getAllPrintLayouts,
  addPrintLayout,
  removePrintLayout
} from '../../../../util/api/printLayoutApi';
import {
  CLIENT_LAYOUTS_ASSIGNED_LAYERS,
  CLIENT_LAYOUTS_NOT_ASSIGNED_LAYERS,
  LIST_SEARCH_HINT_LAYOUTS
} from '../../../../constants/labels';

import {
  ClientDetailPrintLayoutsContainerProps,
  ClientDetailPrintLayoutsContainerState
} from '../../../../@types/Clients.d';
import { ListItem, PrintLayout } from '../../../../@types/Common.d';
import { GlobalState } from '../../../../@types/State.d';
import { Client } from '../../../../@types/Model/Client.d';
import { ClientAction } from '../../../../@types/Actions/Client/Client.d';
import { PrintLayoutAction } from '../../../../@types/Actions/PrintLayout.d';
import AddRemoveList from '../../../Common/AddRemoveList/AddRemoveList';

class ClientDetailsPrintLayouts extends React.Component<
  ClientDetailPrintLayoutsContainerProps,
  ClientDetailPrintLayoutsContainerState
> {
  constructor(props: ClientDetailPrintLayoutsContainerProps) {
    super(props);

    this.state = {
      hasChanged: false,
      init: true,
      notAssignedPrintLayouts: [] as PrintLayout[]
    };

    this.filterPrintLayouts = this.filterPrintLayouts.bind(this);
    this.addPrintLayout = this.addPrintLayout.bind(this);
    this.deletePrintLayout = this.deletePrintLayout.bind(this);
  }

  componentDidMount(): void {
    const { client, printLayoutsFetch, clientPrintLayoutsFetch } = this.props;

    if (!client) return;

    printLayoutsFetch();
    clientPrintLayoutsFetch(client);
  }

  componentDidUpdate(prevProps: ClientDetailPrintLayoutsContainerProps): void {
    const { client, printLayouts, printLayoutsFetch, clientPrintLayoutsFetch } = this.props;
    const { init, hasChanged } = this.state;

    if (!client) return;

    if (client.id !== prevProps.client?.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ init: true }, () => {
        printLayoutsFetch();
        clientPrintLayoutsFetch(client);
      });
    } else if (
      JSON.stringify(printLayouts.allIds) !== JSON.stringify(prevProps.printLayouts.allIds) ||
      JSON.stringify(client.printLayouts.allIds) !==
        JSON.stringify(prevProps.client?.printLayouts.allIds) ||
      init
    ) {
      this.filterPrintLayouts();
    } else if (
      JSON.stringify(printLayouts.allIds) === JSON.stringify(prevProps.printLayouts.allIds) &&
      JSON.stringify(client.printLayouts.allIds) ===
        JSON.stringify(prevProps.client?.printLayouts.allIds) &&
      hasChanged
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasChanged: false, init: false });
    }
  }

  addPrintLayout(printLayoutId: number): void {
    const { clientPrintLayoutAdd, client, printLayouts } = this.props;

    if (!client || !printLayouts.byId[printLayoutId]) return;

    clientPrintLayoutAdd(client, printLayouts.byId[printLayoutId]);
  }

  deletePrintLayout(printLayoutId: number): void {
    const { clientPrintLayoutRemove, client, printLayouts } = this.props;

    if (!client || !printLayouts.byId[printLayoutId]) return;

    clientPrintLayoutRemove(client, printLayouts.byId[printLayoutId]);
  }

  filterPrintLayouts(): void {
    const { printLayouts, client } = this.props;

    if (!client?.printLayouts) return;

    const { printLayouts: clientPrintLayouts } = client;

    const notAssignedPrintLayoutIds = difference(printLayouts.allIds, clientPrintLayouts.allIds);
    const notAssignedPrintLayouts = notAssignedPrintLayoutIds.map(
      (id) => printLayouts.byId[id]
    ) as PrintLayout[];

    this.setState({ notAssignedPrintLayouts, hasChanged: true, init: false });
  }

  render(): JSX.Element {
    const { client } = this.props;
    const { notAssignedPrintLayouts, hasChanged } = this.state;

    return (
      <div className="h-100 d-flex flex-column">
        <Row className="client-relations-container">
          {client ? (
            <>
              <Col sm={6} className="client-relation-list-col">
                <Row className="no-gutters">
                  <Col className="section-header">{CLIENT_LAYOUTS_NOT_ASSIGNED_LAYERS}</Col>
                </Row>
                <Row className="client-relation-list-row">
                  <Col className="p-0 h-100 d-flex flex-column">
                    <AddRemoveList
                      items={notAssignedPrintLayouts.map((layout) => {
                        const { id, name: title } = layout;
                        return { id, title } as ListItem;
                      })}
                      notAssigned
                      hasChanged={hasChanged}
                      searchHint={LIST_SEARCH_HINT_LAYOUTS}
                      itemAdd={this.addPrintLayout}
                      itemDelete={this.deletePrintLayout}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6} className="client-relation-list-col">
                <Row className="no-gutters">
                  <Col className="section-header">{CLIENT_LAYOUTS_ASSIGNED_LAYERS}</Col>
                </Row>
                <Row className="client-relation-list-row">
                  <Col className="p-0 h-100 d-flex flex-column">
                    <AddRemoveList
                      items={client.printLayouts.allIds.map((layoutId: number) => {
                        const { id, name: title } = client?.printLayouts.byId[layoutId];
                        return { id, title } as ListItem;
                      })}
                      hasChanged={hasChanged}
                      searchHint={LIST_SEARCH_HINT_LAYOUTS}
                      itemAdd={this.addPrintLayout}
                      itemDelete={this.deletePrintLayout}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Col />
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (
  state: GlobalState
): Pick<ClientDetailPrintLayoutsContainerProps, 'client' | 'printLayouts'> => ({
  client: state.entities.clients.selectedItem,
  printLayouts: state.entities.printLayouts
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, ClientAction | PrintLayoutAction>
): Pick<
  ClientDetailPrintLayoutsContainerProps,
  | 'clientPrintLayoutsFetch'
  | 'printLayoutsFetch'
  | 'clientPrintLayoutUpdate'
  | 'clientPrintLayoutAdd'
  | 'clientPrintLayoutRemove'
> => ({
  clientPrintLayoutsFetch: (client: Client) => dispatch(getAllClientPrintLayouts(client)),
  printLayoutsFetch: () => dispatch(getAllPrintLayouts()),
  clientPrintLayoutUpdate: (client: Client, printLayout: PrintLayout) =>
    dispatch(updateClientPrintLayout(client, printLayout)),
  clientPrintLayoutAdd: (client: Client, printLayout: PrintLayout) =>
    dispatch(addPrintLayout(client, printLayout)),
  clientPrintLayoutRemove: (client: Client, printLayout: PrintLayout) =>
    dispatch(removePrintLayout(client, printLayout))
});

const ClientDetailsPrintLayoutsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientDetailsPrintLayouts)
);

export default ClientDetailsPrintLayoutsContainer;
