import * as React from 'react';

import { Table, Form } from 'react-bootstrap';
import { map } from 'lodash';

import {
  CLIENT_LOCATIONS_TABLE_HEADER_NAME,
  CLIENT_LOCATIONS_TABLE_HEADER_ADDRESS,
  CLIENT_LOCATIONS_TABLE_HEADER_NUMMER,
  CLIENT_LOCATIONS_TABLE_HEADER_SELECTED_FILL,
  CLIENT_LOCATIONS_TABLE_HEADER_ACTIONS,
  CLIENT_LOCATIONS_TABLE_HEADER_PLANABLE,
  CLIENT_LOCATIONS_TABLE_HEADER_POI_ICON,
  CLIENT_LOCATIONS_TABLE_HEADER_BILLING_DEFAULT
} from '../../../../constants/labels';

import ClientLocationCellContainer from './ClientLocationCell';

import { ClientLocationTableProps } from '../../../../@types/Clients.d';
import { ClientLocation } from '../../../../@types/Model/Client.d';

const ClientLocationTable: React.FC<ClientLocationTableProps> = (
  props: ClientLocationTableProps
) => {
  const {
    client,
    clientLocations,
    multiSelect,
    changeBillingDefault,
    addAllToMultiSelect,
    removeAllFromMultiSelect,
    addToMultiSelect,
    removeFromMultiSelect
  } = props;

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.currentTarget.checked) removeAllFromMultiSelect();
    else addAllToMultiSelect();
  };

  return (
    <Table responsive="md">
      <thead>
        <tr>
          <th>
            <Form.Check
              custom
              type="checkbox"
              checked={multiSelect.length === clientLocations.length}
              id="clientlocation-select-all"
              label=""
              onChange={onChangeSelect}
            />
          </th>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th />
          <th>{CLIENT_LOCATIONS_TABLE_HEADER_POI_ICON}</th>
          <th>{CLIENT_LOCATIONS_TABLE_HEADER_NAME}</th>
          <th>{CLIENT_LOCATIONS_TABLE_HEADER_ADDRESS}</th>
          <th>{CLIENT_LOCATIONS_TABLE_HEADER_NUMMER}</th>
          <th>{CLIENT_LOCATIONS_TABLE_HEADER_SELECTED_FILL}</th>
          {client?.billingType !== 'CLIENT' && (
            <th>{CLIENT_LOCATIONS_TABLE_HEADER_BILLING_DEFAULT}</th>
          )}
          <th>{CLIENT_LOCATIONS_TABLE_HEADER_PLANABLE}</th>
          <th>{CLIENT_LOCATIONS_TABLE_HEADER_ACTIONS}</th>
        </tr>
      </thead>
      <tbody>
        {map(clientLocations, (clientLocation: ClientLocation) => (
          <ClientLocationCellContainer
            key={clientLocation.id}
            clientLocation={clientLocation}
            changeBillingDefault={changeBillingDefault}
            removeFromMultiSelect={removeFromMultiSelect}
            addToMultiSelect={addToMultiSelect}
            selected={multiSelect.indexOf(clientLocation.id) >= 0}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default ClientLocationTable;
