import { Dispatch } from 'redux';

import {
  fetchClientLocationsAction,
  createClientLocationAction,
  updateClientLocationAction,
  deleteClientLocationAction,
  importClientLocations,
  deleteMultipleClientLocationAction
} from '../../actions/client/clientLocationActions';

import {
  parseClientLocations,
  parseClientLocation
} from '../responseUtil/clientLocationResponseUtil';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';

import { axiosClient, COUNTRY_CODE_DE } from '../../constants/constants';
import {
  PATH_CLIENTLOCATIONS,
  PATH_CREATE_CLIENTLOCATION,
  PATH_UPDATE_CLIENTLOCATION,
  PATH_DELETE_CLIENTLOCATION,
  PATH_GET_CLIENTLOCATION,
  PARH_CREATE_CLIENT_LOCATIONS_FROM_FILE,
  PATH_GET_POIS,
  PATH_DELETE_MULTIPLE_CLIENTLOCATIONS
} from '../../constants/network';
import {
  deleteClientLocationErrorTitle,
  deleteClientLocationErrorContent,
  updateClientLocationErrorTitle,
  updateClientLocationErrorContent,
  createClientLocationErrorTitle,
  createClientLocationErrorContent,
  fetchClientLocationsErrorTitle,
  fetchClientLocationsErrorContent
} from '../../constants/errorMessages';
import {
  FETCH_CLIENT_LOCATIONS,
  CREATE_CLIENT_LOCATION,
  UPDATE_CLIENT_LOCATION,
  DELETE_CLIENT_LOCATION,
  DELETE_MULTIPLE_CLIENT_LOCATIONS,
  IMPORT_CLIENT_LOCATIONS
} from '../../constants/actionNames/clients/clientLocationActions';
import { getOpeningHoursSend } from '../utils';

import { Client, ClientLocation } from '../../@types/Model/Client.d';
import {
  CreateClientLocationAction,
  DeleteClientLocationAction,
  DeleteMultipleClientLocationsAction,
  FetchClientLocationAction,
  ImportClientLocationAction,
  UpdateClientLocationAction
} from '../../@types/Actions/Client/ClientLocation.d';
import { RequestFailure } from '../../@types/Actions/UI.d';

export const getClientLocations = (client: Client) => async (
  dispatch: Dispatch
): Promise<FetchClientLocationAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_CLIENT_LOCATIONS));

  const res = await axiosClient
    .get(PATH_CLIENTLOCATIONS(client.uuid), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_CLIENT_LOCATIONS));
    return dispatch(fetchClientLocationsAction(parseClientLocations(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_CLIENT_LOCATIONS, {
      title: fetchClientLocationsErrorTitle,
      content: fetchClientLocationsErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const createClientLocation = (client: Client, clientLocation: ClientLocation) => async (
  dispatch: Dispatch
): Promise<CreateClientLocationAction | RequestFailure> => {
  dispatch(requestStarted(CREATE_CLIENT_LOCATION));

  const { poi, openingHours, ...clientLocationNoPoi } = clientLocation;

  const res = await axiosClient
    .post(
      PATH_CREATE_CLIENTLOCATION(client.uuid),
      {
        ...clientLocationNoPoi,
        ...{ poiId: poi.id, openingHours: getOpeningHoursSend(openingHours) }
      },
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CREATE_CLIENT_LOCATION));
    return dispatch(createClientLocationAction(parseClientLocation(res.data)));
  }

  return dispatch(
    requestFailure(CREATE_CLIENT_LOCATION, {
      title: createClientLocationErrorTitle,
      content: createClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const updateClientLocation = (client: Client, clientLocation: ClientLocation) => async (
  dispatch: Dispatch
): Promise<UpdateClientLocationAction | RequestFailure> => {
  dispatch(requestStarted(UPDATE_CLIENT_LOCATION));

  const { poi, openingHours, ...clientLocationNoPoi } = clientLocation;

  const res = await axiosClient
    .put(
      PATH_UPDATE_CLIENTLOCATION(client.uuid),
      {
        ...clientLocationNoPoi,
        ...{ poiId: poi.id, openingHours: getOpeningHoursSend(openingHours) }
      },
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_CLIENT_LOCATION));
    return dispatch(updateClientLocationAction(parseClientLocation(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_CLIENT_LOCATION, {
      title: updateClientLocationErrorTitle,
      content: updateClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteClientLocation = (client: Client, clientLocation: ClientLocation) => async (
  dispatch: Dispatch
): Promise<DeleteClientLocationAction | RequestFailure> => {
  dispatch(requestStarted(DELETE_CLIENT_LOCATION));

  const res = await axiosClient
    .delete(PATH_DELETE_CLIENTLOCATION(client.uuid, clientLocation.id), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_CLIENT_LOCATION));
    return dispatch(deleteClientLocationAction(clientLocation.id));
  }

  return dispatch(
    requestFailure(DELETE_CLIENT_LOCATION, {
      title: deleteClientLocationErrorTitle,
      content: deleteClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteMultipleClientLocations = (
  client: Client,
  clientLocationIds: number[]
) => async (dispatch: Dispatch): Promise<DeleteMultipleClientLocationsAction | RequestFailure> => {
  dispatch(requestStarted(DELETE_MULTIPLE_CLIENT_LOCATIONS));

  const res = await axiosClient
    .delete(PATH_DELETE_MULTIPLE_CLIENTLOCATIONS(client.uuid), {
      validateStatus: (status: number) => status < 300,
      data: clientLocationIds
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_MULTIPLE_CLIENT_LOCATIONS));
    return dispatch(deleteMultipleClientLocationAction(clientLocationIds));
  }

  return dispatch(
    requestFailure(DELETE_MULTIPLE_CLIENT_LOCATIONS, {
      title: deleteClientLocationErrorTitle,
      content: deleteClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getClientLocation = (client: Client, clientLocation: ClientLocation): Promise<any> =>
  axiosClient
    .get(PATH_GET_CLIENTLOCATION(client.uuid, clientLocation.id), {
      validateStatus: (status: number) => status < 300
    })
    .then(
      (response) => parseClientLocation(response.data),
      (error) => error
    )
    .catch((error) => error);

export const getClientLocationCoordinates = (
  postcode: string,
  city: string,
  street: string,
  housenumber: string
): Promise<any> =>
  axiosClient
    .get(process.env.REACT_APP_URL_NOMINATIM ?? '', {
      params: {
        q: `${postcode} ${city} ${street} ${housenumber}`,
        key: process.env.REACT_APP_OPENCAGE_API_KEY,
        language: COUNTRY_CODE_DE.toLowerCase(),
        no_annotations: 1,
        min_confidence: 5,
        countrycode: `${COUNTRY_CODE_DE.toLowerCase()}}`
      }
    })
    .then(
      (response) => response,
      (error) => error
    )
    .catch((error) => error);

export const getClientLocationAddress = (lat: string, lon: string): Promise<any> =>
  axiosClient
    .get(process.env.REACT_APP_URL_NOMINATIM ?? '', {
      params: {
        q: `${lat},${lon}`,
        key: process.env.REACT_APP_OPENCAGE_API_KEY,
        language: COUNTRY_CODE_DE.toLowerCase(),
        no_annotations: 1,
        min_confidence: 5,
        countrycode: `${COUNTRY_CODE_DE.toLowerCase()}}`
      }
    })
    .then(
      (response) => response,
      (error) => error
    )
    .catch((error) => error);

export const importClientLocationFile = (client: Client, clientLocationsFile: string) => async (
  dispatch: Dispatch
): Promise<ImportClientLocationAction | RequestFailure> => {
  dispatch(requestStarted(IMPORT_CLIENT_LOCATIONS));

  const res = await axiosClient
    .post(
      PARH_CREATE_CLIENT_LOCATIONS_FROM_FILE(client.uuid),
      { data: clientLocationsFile },
      {
        validateStatus: (status: number) => status < 300
      }
    )
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(IMPORT_CLIENT_LOCATIONS));
    return dispatch(importClientLocations(parseClientLocations(res.data)));
  }

  return dispatch(
    requestFailure(IMPORT_CLIENT_LOCATIONS, {
      title: createClientLocationErrorTitle,
      content: createClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getPOIs = (): Promise<any> =>
  axiosClient
    .get(PATH_GET_POIS, {
      validateStatus: (status: number) => status < 300
    })
    .then(
      (response) =>
        (response.data as []).map((poiItem) => {
          const { active, inactive, id, name } = poiItem;
          return { active, inactive, id, name };
        }),
      (error) => error
    )
    .catch((error) => error);
