import { combineReducers } from 'redux';

import confirmationModalReducer from './confirmationModalReducer';
import requestErrorModalReducer from './requestErrorModalReducer';
import clientLocationModalReducer from './clientLocationModalReducer';
import changePasswordModalReducer from './changePasswordModalReducer';

const modalsReducer = combineReducers({
  confirmation: confirmationModalReducer,
  requestError: requestErrorModalReducer,
  clientLocation: clientLocationModalReducer,
  changePassword: changePasswordModalReducer
});

export default modalsReducer;
