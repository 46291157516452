import {
  FetchAllModulesAction,
  FetchClientModulesAction,
  UpdateClientModuleAction
} from '../../@types/Actions/Client/ClientModules.d';
import { Module } from '../../@types/Common.d';
import {
  FETCH_ALL_MODULES,
  FETCH_ALL_CLIENT_MODULES,
  UPDATE_CLIENT_MODULE
} from '../../constants/actionNames/clients/clientActions';

export const fetchClientModulesAction = (modules: Module[]): FetchClientModulesAction => ({
  type: FETCH_ALL_CLIENT_MODULES,
  modules
});

export const fetchAllModulesAction = (modules: Module[]): FetchAllModulesAction => ({
  type: FETCH_ALL_MODULES,
  modules
});

export const updateClientModuleAction = (module: Module): UpdateClientModuleAction => ({
  type: UPDATE_CLIENT_MODULE,
  module
});
