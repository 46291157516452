import '../../styles/Products.scss';

import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { map } from 'lodash';

import ProductsDetailsTabs from './ProductsDetails/ProductsDetailsTabs';
import ProductsNew from './ProductsNew';
import AddListItem from '../Common/AddListItem';
import SearchableList from '../Common/SearchableList/SearchableList';

import { getAllProducts } from '../../util/api/productApi';
import { selectProduct } from '../../actions/productActions';
import { ROUTE_NEW } from '../../constants/constants';
import {
  PRODUCTS_ADD_LIST_ITEM,
  PRODUCTS_EMPTY_LIST,
  LIST_SEARCH_HINT_PRODUCTS
} from '../../constants/labels';

import { ProductAction } from '../../@types/Actions/Product.d';
import { ProductsProps, ProductsState } from '../../@types/Products.d';
import { ListItem } from '../../@types/Common.d';
import { GlobalState } from '../../@types/State.d';
import { Product } from '../../@types/Model/Product.d';

class Products extends React.Component<ProductsProps, ProductsState> {
  constructor(props: ProductsProps) {
    super(props);

    this.state = {};

    this.clickAddProduct = this.clickAddProduct.bind(this);
    this.clickProduct = this.clickProduct.bind(this);
  }

  componentDidMount(): void {
    const { fetchAllProducts, user } = this.props;

    fetchAllProducts(user?.email);
  }

  clickAddProduct(): void {
    const { productSelect } = this.props;
    productSelect(undefined);
  }

  clickProduct(productId: number): void {
    const { productSelect, products } = this.props;

    productSelect(products.byId[productId]);
  }

  render(): JSX.Element {
    const { products, match } = this.props;

    return (
      <Row className="products-container">
        <Col xs={5} lg={3} className="custom-list-container">
          <SearchableList
            items={map(products.allIds, (productId) => {
              const { id, name, description } = products.byId[productId];
              return { id, title: name, subtitle: description } as ListItem;
            })}
            selectedItem={{
              id: products?.selectedItem?.id,
              title: products?.selectedItem?.name,
              subtitle: products?.selectedItem?.description
            }}
            itemClick={this.clickProduct}
            emptyListString={PRODUCTS_EMPTY_LIST}
            searchHint={LIST_SEARCH_HINT_PRODUCTS}
          />
          <AddListItem clickAddItem={this.clickAddProduct} itemText={PRODUCTS_ADD_LIST_ITEM} />
        </Col>
        <Col xs={7} lg={9} className="custom-detail-container">
          <Switch>
            <Route exact path={`${match.path}`}>
              {products.selectedItem ? (
                <ProductsDetailsTabs />
              ) : (
                <Redirect to={`${match.path}${ROUTE_NEW}`} />
              )}
            </Route>
            <Route path={`${match.path}${ROUTE_NEW}`}>
              {products.selectedItem ? <Redirect to={`${match.url}`} /> : <ProductsNew />}
            </Route>
          </Switch>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: GlobalState): Pick<ProductsProps, 'user' | 'products'> => ({
  user: state.entities.user,
  products: state.entities.products
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, ProductAction>
): Pick<ProductsProps, 'fetchAllProducts' | 'productSelect'> => ({
  fetchAllProducts: () => dispatch(getAllProducts()),
  productSelect: (product: Product) => dispatch(selectProduct(product))
});

const ProductsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Products));
export default ProductsContainer;
