import * as React from 'react';

import { ListGroup } from 'react-bootstrap';
import { map } from 'lodash';

import SearchableListItem from './SearchableListItem';
import ListSearch from '../ListSearch';

import { SearchableListProps, SearchableListState } from '../../../@types/App.d';

export default class SearchableList extends React.Component<
  SearchableListProps,
  SearchableListState
> {
  constructor(props: SearchableListProps) {
    super(props);

    const { items } = props;

    this.state = {
      filteredItems: items,
      filterString: ''
    };

    this.filterList = this.filterList.bind(this);
  }

  componentDidUpdate(prevProps: SearchableListProps): void {
    const { items } = this.props;

    if (JSON.stringify(items) !== JSON.stringify(prevProps.items)) {
      const { filterString } = this.state;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filteredItems: items }, () => this.filterList(filterString));
    }
  }

  filterList(pFilterString: string): void {
    this.setState({ filterString: pFilterString }, () => {
      const { items } = this.props;
      const { filterString } = this.state;
      let filteredItems;
      if (filterString.length <= 0) filteredItems = items;
      else {
        const searchStringRegex = map(filterString.split(''), (s) => `[${s}]\\w*`).join('');
        const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
        filteredItems = items.filter(
          ({ title, subtitle }) => title.match(regex) || subtitle?.match(regex)
        );
      }

      this.setState({ filteredItems });
    });
  }

  render(): JSX.Element {
    const { selectedItem, itemClick, searchHint, emptyListString } = this.props;
    const { filteredItems, filterString } = this.state;

    return (
      <>
        <ListSearch
          searchHint={searchHint}
          filterList={this.filterList}
          searchString={filterString}
        />
        <ListGroup className="custom-list selectable">
          {filteredItems.length > 0 ? (
            map(filteredItems, (item) => (
              <SearchableListItem
                key={item.id}
                item={item}
                selected={selectedItem ? item.id === selectedItem.id : false}
                itemClick={itemClick}
              />
            ))
          ) : (
            <ListGroup.Item className="custom-list-item">{emptyListString ?? ''}</ListGroup.Item>
          )}
        </ListGroup>
      </>
    );
  }
}
