import * as React from 'react';

import { Form, Col, Row, FormControl, Button } from 'react-bootstrap';
import moment, { Moment } from 'moment';

import { SingleDatePicker } from 'react-dates';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import Select from 'react-select';

import { find } from 'lodash';
import { requestFailure, requestStarted, requestSuccess } from '../../../../actions/uiActions';

import {
  createClientDistributionAppointment,
  deleteClientDistributionAppointment,
  getClientDistributionAppointment,
  updateClientDistributionAppointment
} from '../../../../util/api/clientDistributionAppointmentsApi';
import { extractDistributionAppointment } from '../../../../util/responseUtil/clientResponseUtil';
import {
  fetchClientDistributionAppointmentErrorContent,
  fetchClientDistributionAppointmentErrorTitle
} from '../../../../constants/errorMessages';

import {
  CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_LABEL,
  CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_PLACEHOLDER,
  CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_INVALID,
  CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_LABEL,
  CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_PLACEHOLDER,
  CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_INVALID,
  CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_LABEL,
  CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_PLACEHOLDER,
  CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_INVALID,
  CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_LABEL,
  CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_PLACEHOLDER,
  CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_INVALID,
  BUTTON_APPLY,
  BUTTON_DELETE,
  BUTTON_CREATE
} from '../../../../constants/labels';
import { FETCH_CLIENT_DISTRIBUTION_ACTION } from '../../../../constants/actionNames/clients/clientDistributionAppointments';
import { DATE_TYPE_ARRAY, selectPickerTheme } from '../../../../constants/constants';

import {
  ClientDetailsDistributionAppointmentsDetailsProps,
  ClientDetailsDistributionAppointmentsDetailsState
} from '../../../../@types/Clients.d';
import { GlobalState } from '../../../../@types/State.d';
import { Client } from '../../../../@types/Model/Client.d';
import { DistributionAppointment } from '../../../../@types/Model/Distribution.d';
import { ErrorMessage } from '../../../../@types/Model/Message.d';
import { ClientDistributionAppointmentActions } from '../../../../@types/Actions/Client/ClientDistributionAppointments.d';
import { UIAction } from '../../../../@types/Actions/UI.d';

class ClientDetailsDistributionAppointmentsDetails extends React.Component<
  ClientDetailsDistributionAppointmentsDetailsProps,
  ClientDetailsDistributionAppointmentsDetailsState
> {
  constructor(props: ClientDetailsDistributionAppointmentsDetailsProps) {
    super(props);

    const { distributionAppointment } = this.props;

    this.state = {
      validated: false,
      datePickerFocused: false,
      name: distributionAppointment?.name ?? '',
      date: distributionAppointment?.date ?? moment(),
      type: distributionAppointment?.type ?? 'WEEK'
    };

    this.onChangeActionName = this.onChangeActionName.bind(this);
    this.onChangeCalendarWeek = this.onChangeCalendarWeek.bind(this);
    this.onChangeDatePickerFocus = this.onChangeDatePickerFocus.bind(this);
    this.onChangeDateType = this.onChangeDateType.bind(this);
    this.onClickDeleteDistributionAppointment = this.onClickDeleteDistributionAppointment.bind(
      this
    );
    this.onSubmit = this.onSubmit.bind(this);
    this.fetchDistributionOption = this.fetchDistributionOption.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidMount(): void {
    const { distributionAppointment } = this.props;

    if (distributionAppointment) this.fetchDistributionOption();
  }

  componentDidUpdate(prevProps: ClientDetailsDistributionAppointmentsDetailsProps): void {
    const { distributionAppointment, client } = this.props;

    if (
      prevProps.client?.id !== client?.id ||
      prevProps.distributionAppointment?.id !== distributionAppointment?.id
    ) {
      this.resetForm();

      if (distributionAppointment) this.fetchDistributionOption();
    }
  }

  onChangeActionName(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ name: event.currentTarget.value ?? '' });
  }

  onChangeCalendarWeek(date: Moment | null): void {
    this.setState({ date });
  }

  onChangeDatePickerFocus(focused: boolean): void {
    this.setState({ datePickerFocused: focused });
  }

  onChangeDateType(type: any): void {
    if (type && type !== null) this.setState({ type: type.value });
  }

  onClickDeleteDistributionAppointment(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    event.stopPropagation();
    event.preventDefault();

    const { distributionAppointmentDelete, client, distributionAppointment } = this.props;

    distributionAppointmentDelete(client, distributionAppointment);
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        const {
          client,
          distributionAppointmentCreate,
          distributionAppointmentUpdate,
          distributionAppointment
        } = this.props;
        const { name, date, type } = this.state;
        const distributionAppointmentNew = {
          name,
          date,
          type,
          sortProperty: `${date?.unix()}`,
          ...(distributionAppointment ? { id: distributionAppointment.id } : {})
        } as DistributionAppointment;

        if (distributionAppointment)
          distributionAppointmentUpdate(client, distributionAppointmentNew);
        else distributionAppointmentCreate(client, distributionAppointmentNew);
      }
    });
  }

  isDayBlocked(day: Moment): boolean {
    const { type } = this.state;

    const tenDaysAhead = moment().add(10, 'd');

    if (type === 'WEEK')
      return day.isBefore(tenDaysAhead) || day.isoWeek() === tenDaysAhead.isoWeek();

    return day.isBefore(tenDaysAhead) || (day.day() !== 3 && day.day() !== 6);
  }

  isDayHighlighted(day: Moment): boolean {
    const { date, type } = this.state;

    if (date === null) return false;

    if (type === 'WEEK') return date.isoWeek() === day.isoWeek() && date.year() === day.year();

    return day.isoWeek() === date.isoWeek() && day.day() === date.day();
  }

  async fetchDistributionOption(): Promise<void> {
    const { reqStarted, reqSuccess, reqFailure, client, distributionAppointment } = this.props;

    if (!distributionAppointment || !client) return;

    reqStarted(FETCH_CLIENT_DISTRIBUTION_ACTION);

    const res = await getClientDistributionAppointment(client, distributionAppointment);

    if (res.status < 300) {
      const { name, date, type } = extractDistributionAppointment(res.data);

      this.setState({ name, date, type }, () => reqSuccess(FETCH_CLIENT_DISTRIBUTION_ACTION));

      return;
    }

    reqFailure(FETCH_CLIENT_DISTRIBUTION_ACTION, {
      title: fetchClientDistributionAppointmentErrorTitle,
      content: fetchClientDistributionAppointmentErrorContent(
        res.response?.status ?? res.request?.status ?? 404
      )
    });
  }

  resetForm(): void {
    this.setState({
      validated: false,
      datePickerFocused: false,
      name: '',
      date: moment(),
      type: 'WEEK'
    });
  }

  render(): JSX.Element {
    const { distributionAppointment } = this.props;
    const { validated, name, date, type, datePickerFocused } = this.state;

    return (
      <Form
        id="offer-order-detail-form"
        className="offer-order-detail-form"
        onSubmit={this.onSubmit}
        noValidate
        validated={validated}
      >
        <Row className="no-gutters h-100">
          <Col xs={12} xl={6}>
            <Form.Row>
              <Col xs={12} xl={6}>
                <Form.Group>
                  <Form.Label>{CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_LABEL}</Form.Label>
                  <FormControl
                    as="input"
                    autoComplete="new-password"
                    type="text"
                    value={name}
                    onChange={this.onChangeActionName}
                    placeholder={CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group>
                  <Form.Label>{CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_LABEL}</Form.Label>
                  <Select
                    blurInputOnSelect
                    isSearchable={false}
                    options={DATE_TYPE_ARRAY}
                    value={find(DATE_TYPE_ARRAY, { value: type })}
                    placeholder={CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_PLACEHOLDER}
                    onChange={this.onChangeDateType}
                    theme={(theme: any) => selectPickerTheme(theme)}
                  />
                  <Form.Control value={type} hidden onChange={() => {}} required />
                  <Form.Control.Feedback type="invalid">
                    {CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="no-gutters">
              <Col xs={12} lg={6}>
                <Form.Group>
                  <Form.Label>
                    {type === 'WEEK'
                      ? CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_LABEL
                      : CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_LABEL}
                  </Form.Label>
                  {type === 'WEEK' ? (
                    <SingleDatePicker
                      date={date}
                      onDateChange={this.onChangeCalendarWeek}
                      focused={datePickerFocused}
                      onFocusChange={({ focused }) =>
                        this.onChangeDatePickerFocus(focused || false)
                      }
                      id="calendat-week-picker"
                      placeholder={CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_PLACEHOLDER}
                      showClearDate
                      isDayBlocked={(day: any) => this.isDayBlocked(day)}
                      isDayHighlighted={(day: any) => this.isDayHighlighted(day)}
                      numberOfMonths={1}
                      displayFormat="WW. \K\W YYYY"
                      block
                    />
                  ) : (
                    <SingleDatePicker
                      date={date}
                      onDateChange={this.onChangeCalendarWeek}
                      focused={datePickerFocused}
                      onFocusChange={({ focused }) =>
                        this.onChangeDatePickerFocus(focused || false)
                      }
                      id="calendat-week-picker"
                      placeholder={CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_PLACEHOLDER}
                      showClearDate
                      isDayBlocked={(day: any) => this.isDayBlocked(day)}
                      isDayHighlighted={(day: any) => this.isDayHighlighted(day)}
                      numberOfMonths={1}
                      displayFormat="DD.MM.YYYY"
                      block
                    />
                  )}
                  <Form.Control
                    value={date !== null ? date.format('dd.MM.yyyy') : ''}
                    onChange={() => {}}
                    hidden
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {type === 'WEEK'
                      ? CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_INVALID
                      : CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col sm={12} xl={4}>
            <Row className="no-gutters">
              <Col sm={5} className="p-1">
                <Button type="submit" variant="success" className="h-100" block>
                  {distributionAppointment ? BUTTON_APPLY : BUTTON_CREATE}
                </Button>
              </Col>
              {distributionAppointment && (
                <Col sm={5} className="p-1">
                  <Button
                    variant="danger"
                    className="ml-1 h-100"
                    block
                    onClick={this.onClickDeleteDistributionAppointment}
                  >
                    {BUTTON_DELETE}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (
  state: GlobalState
): Pick<
  ClientDetailsDistributionAppointmentsDetailsProps,
  'client' | 'distributionAppointment'
> => ({
  client: state.entities.clients.selectedItem,
  distributionAppointment:
    state.entities.clients.selectedItem?.distributionAppointments.selectedItem
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, UIAction | ClientDistributionAppointmentActions>
): Pick<
  ClientDetailsDistributionAppointmentsDetailsProps,
  | 'reqStarted'
  | 'reqFailure'
  | 'reqSuccess'
  | 'distributionAppointmentUpdate'
  | 'distributionAppointmentCreate'
  | 'distributionAppointmentDelete'
> => ({
  reqStarted: (payload: string) => dispatch(requestStarted(payload)),
  reqSuccess: (payload: string) => dispatch(requestSuccess(payload)),
  reqFailure: (payload: string, errorMessage: ErrorMessage) =>
    dispatch(requestFailure(payload, errorMessage)),
  distributionAppointmentUpdate: (
    client: Client,
    distributionAppointment: DistributionAppointment
  ) => dispatch(updateClientDistributionAppointment(client, distributionAppointment)),
  distributionAppointmentDelete: (
    client: Client,
    distributionAppointment: DistributionAppointment
  ) => dispatch(deleteClientDistributionAppointment(client, distributionAppointment)),
  distributionAppointmentCreate: (
    client: Client,
    distributionAppointment: DistributionAppointment
  ) => dispatch(createClientDistributionAppointment(client, distributionAppointment))
});

const ClientDetailsDistributionAppointmentsDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientDetailsDistributionAppointmentsDetails)
);
export default ClientDetailsDistributionAppointmentsDetailsContainer;
