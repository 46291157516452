import * as React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { hideConfirmationModal } from '../../actions/uiActions';

import { BUTTON_ABORT, BUTTON_APPLY } from '../../constants/labels';

import { ConfirmationModalProps } from '../../@types/Modal.d';
import { GlobalState } from '../../@types/State.d';
import { ConfirmationModalAction } from '../../@types/Actions/UI.d';

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props: ConfirmationModalProps) => {
  const { title, content, show, hideModal, confirmationCallback } = props;

  const onHide = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    event.preventDefault();

    hideModal();
  };

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    event.preventDefault();

    hideModal();
    if (confirmationCallback) confirmationCallback();
  };

  return (
    <Modal show={show} onHide={() => hideModal('')}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onConfirm}>{BUTTON_APPLY}</Button>
        <Button variant="danger" onClick={onHide}>
          {BUTTON_ABORT}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (
  state: GlobalState
): Pick<ConfirmationModalProps, 'show' | 'title' | 'confirmationCallback' | 'content'> => ({
  show: state.ui.modals.confirmation.show,
  title: state.ui.modals.confirmation.title,
  content: state.ui.modals.confirmation.content,
  confirmationCallback: state.ui.modals.confirmation.confirmationCallback
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, ConfirmationModalAction>
): Pick<ConfirmationModalProps, 'hideModal'> => ({
  hideModal: (payload: string) => dispatch(hideConfirmationModal(payload))
});

const ConfirmationModalContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)
);

export default ConfirmationModalContainer;
