import * as React from 'react';

import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router-dom';
import { difference, map } from 'lodash';

import AddRemoveList from '../../../Common/AddRemoveList/AddRemoveList';

import {
  CLIENT_LAYERS_ASSIGNED_LAYERS,
  CLIENT_LAYERS_NOT_ASSIGNED_LAYERS,
  LIST_SEARCH_HINT_LAYERS
} from '../../../../constants/labels';
import { getAllLayers } from '../../../../util/api/layerApi';
import {
  addClientLayer,
  getClientLayers,
  deleteClientLayer
} from '../../../../util/api/clientLayerApi';

import { ClientsDetailsLayersProps, ClientsDetailsLayersState } from '../../../../@types/Clients.d';
import { ListItem, Module } from '../../../../@types/Common.d';
import { GlobalState } from '../../../../@types/State.d';
import { Layer, LayerType } from '../../../../@types/Model/Map.d';
import { Client } from '../../../../@types/Model/Client.d';
import { DistributionConfig } from '../../../../@types/Model/Distribution.d';
import { RequestAction } from '../../../../@types/Actions/UI.d';
import { ClientLayerAction } from '../../../../@types/Actions/Client/ClientLayer.d';
import { LayerTypeDisplayMatcher } from '../../../../constants/constants';

class ClientsDetailsLayers extends React.Component<
  ClientsDetailsLayersProps,
  ClientsDetailsLayersState
> {
  constructor(props: ClientsDetailsLayersProps) {
    super(props);

    const { client } = this.props;

    let configLayers = [] as LayerType[];

    if (client) {
      const { modules } = client;

      configLayers = (modules.allIds
        .map((id: number) => modules.byId[id])
        .find((module: Module) => module.type === 'DISTRIBUTION')?.config as DistributionConfig)
        ?.enabledLayerTypes;
    }
    this.state = {
      hasChanged: false,
      notAssignedLayers: [],
      init: true,
      configLayers,
      filteredTypes: configLayers ?? []
    };

    this.filterLayers = this.filterLayers.bind(this);
    this.addClientLayer = this.addClientLayer.bind(this);
    this.deleteClientLayer = this.deleteClientLayer.bind(this);

    this.onChangeLayerType = this.onChangeLayerType.bind(this);
  }

  componentDidMount(): void {
    const { layersFetch, clientLayersFetch, client } = this.props;

    if (!client) return;

    layersFetch(client);
    clientLayersFetch(client);
  }

  componentDidUpdate(prevProps: ClientsDetailsLayersProps): void {
    const { layersFetch, clientLayersFetch, client, layers } = this.props;
    const { hasChanged, init } = this.state;

    if (!client) return;

    if (client?.id !== prevProps.client?.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ init: true }, () => {
        layersFetch(client);
        clientLayersFetch(client);
      });
    } else if (
      JSON.stringify(client?.layers.allIds) !== JSON.stringify(prevProps.client?.layers.allIds) ||
      JSON.stringify(layers.allIds) !== JSON.stringify(prevProps.layers.allIds) ||
      init
    ) {
      this.filterLayers();
    } else if (
      JSON.stringify(client?.layers.allIds) === JSON.stringify(prevProps.client?.layers.allIds) &&
      JSON.stringify(layers.allIds) === JSON.stringify(prevProps.layers.allIds) &&
      client?.id === prevProps.client?.id &&
      hasChanged
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasChanged: false, init: false });
    }
  }

  onChangeLayerType(event: React.ChangeEvent<HTMLInputElement>, type: LayerType): void {
    const { filteredTypes } = this.state;

    let nLayerTypes = [...filteredTypes];

    if (event.currentTarget.checked) nLayerTypes = [...filteredTypes, ...[type]];
    else {
      const index = filteredTypes.indexOf(type);
      nLayerTypes = [...filteredTypes.slice(0, index), ...filteredTypes.slice(index + 1)];
    }

    this.setState({ filteredTypes: nLayerTypes, hasChanged: true, init: false });
  }

  filterLayers(): void {
    const { client, layers } = this.props;

    if (!client?.layers) return;

    const notAssignedLayersIds = difference(layers.allIds, client.layers.allIds);
    const notAssignedLayers = map(notAssignedLayersIds, (id: number) => layers.byId[id]) as Layer[];

    this.setState({ notAssignedLayers, hasChanged: true, init: false });
  }

  addClientLayer(layerId: number): void {
    const { client, layers, clientLayerAdd } = this.props;

    clientLayerAdd(client, layers.byId[layerId]);
  }

  deleteClientLayer(layerId: number): void {
    const { client, layers, clientLayerDelete } = this.props;

    clientLayerDelete(client, layers.byId[layerId]);
  }

  render(): JSX.Element {
    const { client } = this.props;
    const { notAssignedLayers, hasChanged, filteredTypes, configLayers } = this.state;

    return (
      <Row className="no-gutters h-100">
        <Col className="client-layer-container">
          <Row className="no-gutters">
            <Col>
              <div className="layer-filter-container">
                {configLayers?.map((type) => (
                  <Form.Group key={type}>
                    <Form.Check
                      custom
                      type="switch"
                      checked={filteredTypes.indexOf(type) !== -1}
                      id={`layer-type-${type}-check`}
                      label={LayerTypeDisplayMatcher[type].title}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        this.onChangeLayerType(event, type)
                      }
                    />
                  </Form.Group>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="client-relations-container">
            {client ? (
              <>
                <Col sm={6} className="client-relation-list-col">
                  <Row className="no-gutters">
                    <Col className="section-header">{CLIENT_LAYERS_NOT_ASSIGNED_LAYERS}</Col>
                  </Row>
                  <Row className="client-relation-list-row">
                    <Col className="p-0 h-100 d-flex flex-column">
                      <AddRemoveList
                        items={notAssignedLayers
                          // .filter((layer) => filteredTypes.indexOf(layer.type) !== -1)
                          .map((layer) => {
                            const { id, title, description } = layer;
                            return { id, title, subtitle: description } as ListItem;
                          })}
                        notAssigned
                        hasChanged={hasChanged}
                        searchHint={LIST_SEARCH_HINT_LAYERS}
                        itemAdd={this.addClientLayer}
                        itemDelete={this.deleteClientLayer}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm={6} className="client-relation-list-col">
                  <Row className="no-gutters">
                    <Col className="section-header">{CLIENT_LAYERS_ASSIGNED_LAYERS}</Col>
                  </Row>
                  <Row className="client-relation-list-row">
                    <Col className="p-0 h-100 d-flex flex-column">
                      <AddRemoveList
                        items={map(client?.layers.allIds, (layerId: number) => {
                          const { id, title, description } = client?.layers.byId[layerId];
                          return { id, title, subtitle: description } as ListItem;
                        })}
                        hasChanged={hasChanged}
                        searchHint={LIST_SEARCH_HINT_LAYERS}
                        itemAdd={this.addClientLayer}
                        itemDelete={this.deleteClientLayer}
                      />
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <Col />
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (
  state: GlobalState
): Pick<ClientsDetailsLayersProps, 'client' | 'layers'> => ({
  client: state.entities.clients.selectedItem,
  layers: state.entities.layers
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, RequestAction | ClientLayerAction>
): Pick<
  ClientsDetailsLayersProps,
  'layersFetch' | 'clientLayersFetch' | 'clientLayerAdd' | 'clientLayerDelete'
> => ({
  layersFetch: () => dispatch(getAllLayers()),
  clientLayersFetch: (client: Client) => dispatch(getClientLayers(client)),
  clientLayerAdd: (client: Client, layer: Layer) => dispatch(addClientLayer(client, layer)),
  clientLayerDelete: (client: Client, layer: Layer) => dispatch(deleteClientLayer(client, layer))
});

const ClientsDetailsLayersContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsDetailsLayers)
);
export default ClientsDetailsLayersContainer;
