import initialState from '../../../constants/initialState';
import {
  SHOW_CHANGE_PASSWORD_MODAL,
  HIDE_CHANGE_PASSWORD_MODAL
} from '../../../constants/actionNames/ui';
import { ChangePasswordModal } from '../../../@types/State.d';
import { Action } from '../../../@types/Actions/Common.d';

export default function changePasswordModalReducer(
  state = initialState.ui.modals.changePassword,
  action: Action
): ChangePasswordModal {
  switch (action.type) {
    case SHOW_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        ...{
          show: true
        }
      };
    case HIDE_CHANGE_PASSWORD_MODAL:
      return { ...state, ...{ show: false } };
    default:
      return state;
  }
}
