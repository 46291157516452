import { Dispatch } from 'redux';
import {
  FetchAllModulesAction,
  FetchClientModulesAction,
  UpdateClientModuleAction
} from '../../@types/Actions/Client/ClientModules.d';
import {
  fetchAllModulesAction,
  fetchClientModulesAction,
  updateClientModuleAction
} from '../../actions/client/clientModules';

import {
  DUMMY_CLIENT_MODULES,
  axiosClient,
  DUMMY_MODULES,
  DUMMY_OFFER_SHEET_TEMPLATES
} from '../../constants/constants';
import {
  fetchModulsErrorTitle,
  fetchModulsErrorContent,
  fetchClientsErrorTitle,
  fetchClientsErrorContent,
  createClientErrorTitle,
  createClientErrorContent,
  updateClientErrorTitle,
  updateClientErrorContent,
  deleteClientErrorTitle,
  deleteClientErrorContent,
  fetchAllClientOfferOrderErrorTitle,
  fetchAllClientOfferOrderErrorContent,
  fetchAllClientAdditionalOptionsErrorTitle,
  fetchAllClientAdditionalOptionsErrorContent,
  updateClientAdditionalOptionsErrorTitle,
  updateClientAdditionalOptionsErrorContent,
  fetchClientModulsErrorTitle,
  fetchClientModulsErrorContent,
  updateClienModuleErrorTitle,
  updateClienModuleErrorContent
} from '../../constants/errorMessages';
import {
  FETCH_ALL_MODULES,
  FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS,
  FETCH_ALL_CLIENT_MODULES,
  UPDATE_CLIENT_ADDITIONAL_OPTION,
  UPDATE_CLIENT_MODULE
} from '../../constants/actionNames/clients/clientActions';

import {
  fetchAllClientAdditionalOptions,
  updateClientAdditionalOption as udpateClientAdditionalOptionAction
} from '../../actions/client/clientAdditionalOptions';
import {
  fetchAllClientsAction,
  createClientsAction,
  updateClientsAction,
  deleteClientsAction
} from '../../actions/client/clientActions';

import { fetchAllClientOfferOrderAction } from '../../actions/client/clientOfferOrderActions';
import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';

import {
  parseClients,
  parseClient,
  extractOfferTemplates,
  extractOrderTemplates,
  extractAdditionalOption,
  extractAdditionalOptions,
  extractClientModules,
  extractClientModule
} from '../responseUtil/clientResponseUtil';

import {
  PATH_ALL_CLIENTS,
  PATH_GET_CLIENT,
  PATH_CREATE_CLIENT,
  PATH_UPDATE_CLIENT,
  PATH_DELETE_CLIENT,
  PATH_GET_CLIENT_OFFER_ORDER,
  PATH_GET_CLIENT_OFFER_ORDER_ITEM,
  PATH_GET_CLIENT_ADDITIONAL_OPTIONS,
  PATH_UPDATE_CLIENT_ADDITIONAL_OPTIONS
} from '../../constants/network';

import {
  FETCH_ALL_CLIENTS,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT
} from '../../constants/actionNames/clients/clients';
import { FETCH_ALL_CLIENT_OFFER_ORDER } from '../../constants/actionNames/clients/clientOfferOrderActions';

import {
  AdditionalOption,
  AdditionalOptionKaufDa,
  AdditionalOptionSocialMedia
} from '../../@types/Model/AddtionalOption.d';
import { Client } from '../../@types/Model/Client.d';
import {
  CreateClientsAction,
  DeleteClientsAction,
  FetchAllClientsAction,
  UpdateClientsAction
} from '../../@types/Actions/Client/Client.d';
import { RequestAction, RequestFailure } from '../../@types/Actions/UI.d';
import { FetchAllClientOfferOrderAction } from '../../@types/Actions/Client/ClientOfferOrder.d';
import {
  FetchAllClientAdditionalOptionsAction,
  UpdateClientAdditionalOptionAction
} from '../../@types/Actions/Client/ClientAdditionalOptions.d';
import { Module } from '../../@types/Common.d';

export const getAllClients = (user: string) => async (
  dispatch: Dispatch
): Promise<FetchAllClientsAction | RequestAction> => {
  dispatch(requestStarted(FETCH_ALL_CLIENTS));

  const res = await axiosClient
    .get(PATH_ALL_CLIENTS, {
      params: { user },
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_CLIENTS));
    return dispatch(fetchAllClientsAction(parseClients(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_ALL_CLIENTS, {
      title: fetchClientsErrorTitle,
      content: fetchClientsErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getClient = (clientUUID: string): Promise<any> =>
  axiosClient
    .get(PATH_GET_CLIENT(clientUUID), {
      validateStatus: (status: number) => status < 300
    })
    .then(
      (response) => response,
      (error) => error
    )
    .catch((error) => error);

export const createClient = (client: Client) => async (
  dispatch: Dispatch
): Promise<CreateClientsAction | RequestFailure> => {
  dispatch(requestStarted(CREATE_CLIENT));

  const {
    clientLocations,
    users,
    products,
    layers,
    offerOrder,
    additionalOptions,
    distributionAppointments,
    ...restClient
  } = client;

  const res = await axiosClient
    .post(PATH_CREATE_CLIENT, restClient, {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CREATE_CLIENT));
    return dispatch(createClientsAction(parseClient(res.data)));
  }

  return dispatch(
    requestFailure(CREATE_CLIENT, {
      title: createClientErrorTitle,
      content: createClientErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const updateClient = (client: Client) => async (
  dispatch: Dispatch
): Promise<UpdateClientsAction | RequestFailure> => {
  dispatch(requestStarted(UPDATE_CLIENT));

  const {
    clientLocations,
    users,
    products,
    layers,
    offerOrder,
    additionalOptions,
    distributionAppointments,
    ...restClient
  } = client;

  const res = await axiosClient
    .put(PATH_UPDATE_CLIENT, restClient, {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_CLIENT));
    return dispatch(updateClientsAction(parseClient(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_CLIENT, {
      title: updateClientErrorTitle,
      content: updateClientErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteClient = (client: Client) => async (
  dispatch: Dispatch
): Promise<DeleteClientsAction | RequestFailure> => {
  dispatch(requestStarted(DELETE_CLIENT));

  const res = await axiosClient
    .delete(PATH_DELETE_CLIENT(client.uuid), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_CLIENT));
    return dispatch(deleteClientsAction(client));
  }

  return dispatch(
    requestFailure(DELETE_CLIENT, {
      title: deleteClientErrorTitle,
      content: deleteClientErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getClientOfferOrder = (client: Client, offer: boolean) => async (
  dispatch: Dispatch
): Promise<FetchAllClientOfferOrderAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_ALL_CLIENT_OFFER_ORDER));

  const res = await axiosClient
    .get(PATH_GET_CLIENT_OFFER_ORDER(client.uuid, offer), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_CLIENT_OFFER_ORDER));
    return dispatch(
      fetchAllClientOfferOrderAction(
        offer ? extractOfferTemplates(res.data) : extractOrderTemplates(res.data)
      )
    );
  }

  return dispatch(
    requestFailure(FETCH_ALL_CLIENT_OFFER_ORDER, {
      title: fetchAllClientOfferOrderErrorTitle,
      content: fetchAllClientOfferOrderErrorContent(
        res.response?.status ?? res.request?.status ?? 404,
        offer
      )
    })
  );
};

export const getOfferOrderItemData = (
  client: Client,
  offer: boolean,
  offerOrderId: number
): Promise<any> =>
  axiosClient
    .get(PATH_GET_CLIENT_OFFER_ORDER_ITEM(client.uuid, offer, offerOrderId), {})
    .then(
      (response) => response,
      (error) => error
    )

    .catch((error) => error);

export const getAllClientAdditionalItems = (client: Client) => async (
  dispatch: Dispatch
): Promise<FetchAllClientAdditionalOptionsAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS));

  const res = await axiosClient
    .get(PATH_GET_CLIENT_ADDITIONAL_OPTIONS(client.uuid), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS));
    return dispatch(fetchAllClientAdditionalOptions(extractAdditionalOptions(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS, {
      title: fetchAllClientAdditionalOptionsErrorTitle,
      content: fetchAllClientAdditionalOptionsErrorContent(res.status)
    })
  );
};

export const updateClientAdditionalOption = (
  client: Client,
  additionalOption: AdditionalOption
) => async (dispatch: Dispatch): Promise<UpdateClientAdditionalOptionAction | RequestFailure> => {
  dispatch(requestStarted(UPDATE_CLIENT_ADDITIONAL_OPTION));

  let cAdditionalOption;
  let payload;

  if (additionalOption.type === 'KAUFDA') {
    cAdditionalOption = additionalOption as AdditionalOptionKaufDa;

    const { enabled, id, price, type, mode, ...rest } = cAdditionalOption;
    const { sortProperty, ...data } = rest;

    payload = { enabled, id, price, type, mode, data: JSON.stringify(data) };
  } else if (additionalOption.type === 'SOCIALMEDIA') {
    cAdditionalOption = additionalOption as AdditionalOptionSocialMedia;

    const { enabled, id, price, type, mode, platform, ...rest } = cAdditionalOption;
    const { sortProperty, ...data } = rest;

    payload = { enabled, id, price, type, mode, platform, data: JSON.stringify(data) };
  } else cAdditionalOption = additionalOption;

  const res = await axiosClient
    .put(PATH_UPDATE_CLIENT_ADDITIONAL_OPTIONS(client.uuid), payload, {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_CLIENT_ADDITIONAL_OPTION));
    return dispatch(udpateClientAdditionalOptionAction(extractAdditionalOption(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_CLIENT_ADDITIONAL_OPTION, {
      title: updateClientAdditionalOptionsErrorTitle,
      content: updateClientAdditionalOptionsErrorContent(res.status)
    })
  );
};

export const getOfferSheetTemplates = (): any =>
  ({ status: 200, data: DUMMY_OFFER_SHEET_TEMPLATES } as any);
// axiosClient
//   .get(, {
//     headers,
//     validateStatus: (status: number) => {
//       return status < 300;
//     }
//   })
//   .then(
//     response => response,
//     error => error
//   )
//   .catch(error => error);

export const getClientModules = () => async (
  dispatch: Dispatch
): Promise<FetchClientModulesAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_ALL_CLIENT_MODULES));

  const res = { status: 200, data: DUMMY_CLIENT_MODULES } as any;

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_CLIENT_MODULES));
    return dispatch(fetchClientModulesAction(extractClientModules(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_ALL_CLIENT_MODULES, {
      title: fetchClientModulsErrorTitle,
      content: fetchClientModulsErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getModules = () => async (
  dispatch: Dispatch
): Promise<FetchAllModulesAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_ALL_MODULES));

  const res = { status: 200, data: DUMMY_MODULES } as any;

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_MODULES));
    return dispatch(fetchAllModulesAction(extractClientModules(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_ALL_MODULES, {
      title: fetchModulsErrorTitle,
      content: fetchModulsErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const updateClientModule = (client: Client, module: Module) => async (
  dispatch: Dispatch
): Promise<UpdateClientModuleAction | RequestFailure> => {
  dispatch(requestStarted(UPDATE_CLIENT_MODULE));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const res = { status: 200, data: module } as any;

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_CLIENT_MODULE));
    return dispatch(updateClientModuleAction(extractClientModule(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_CLIENT_MODULE, {
      title: updateClienModuleErrorTitle,
      content: updateClienModuleErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};
