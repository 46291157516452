import { getDict, getIndicies } from '../../util/utils';
import { FETCH_ALL_MODULES } from '../../constants/actionNames/clients/clientActions';
import { Module, DictState } from '../../@types/Common.d';
import { FetchAllModulesAction } from '../../@types/Actions/Client/ClientModules.d';
import initialState from '../../constants/initialState';

export default function modulesReducer(
  state = initialState.entities.modules,
  action: FetchAllModulesAction
): DictState<Module> {
  const { type } = action;

  if (type === FETCH_ALL_MODULES) {
    const castedAction = action as FetchAllModulesAction;

    const byId = getDict<Module>(castedAction.modules);
    const allIds = getIndicies<Module>(byId);

    return {
      ...state,
      ...{ byId, allIds, selectedItem: undefined }
    } as DictState<Module>;
  }

  return state;
}
