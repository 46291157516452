import { Dispatch } from 'redux';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';
import {
  fetchAllProductsAction,
  createProductAction,
  updateProductAction,
  deleteProductAction
} from '../../actions/productActions';

import { parseProducts, parseProduct } from '../responseUtil/productResponseUtil';

import { axiosClient } from '../../constants/constants';
import {
  PATH_ALL_PRODUCTS,
  PATH_GET_PRODUCT,
  PATH_CREATE_PRODUCT,
  PATH_UPDATE_PRODUCT,
  PATH_DELETE_PRODUCT
} from '../../constants/network';
import {
  fetchProductsErrorTitle,
  fetchProductsErrorContent,
  createProductErrorTitle,
  createProductErrorContent,
  updateProductErrorTitle,
  updateProductErrorContent,
  deleteProductErrorTitle,
  deleteProductErrorContent
} from '../../constants/errorMessages';
import {
  FETCH_ALL_PRODUCTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT
} from '../../constants/actionNames/products';

import { Product } from '../../@types/Model/Product.d';
import {
  CreateProductAction,
  DeleteProductAction,
  FetchAllProductsAction,
  UpdateProductAction
} from '../../@types/Actions/Product.d';
import { RequestFailure } from '../../@types/Actions/UI.d';

export const getAllProducts = () => async (
  dispatch: Dispatch
): Promise<FetchAllProductsAction | RequestFailure> => {
  dispatch(requestStarted(FETCH_ALL_PRODUCTS));

  const res = await axiosClient
    .get(PATH_ALL_PRODUCTS, {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_PRODUCTS));
    return dispatch(fetchAllProductsAction(parseProducts(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_ALL_PRODUCTS, {
      title: fetchProductsErrorTitle,
      content: fetchProductsErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getProduct = (productId: number): Promise<any> =>
  axiosClient
    .get(PATH_GET_PRODUCT(productId), {
      validateStatus: (status: number) => status < 300
    })
    .then(
      (response) => response,
      (error) => error
    )
    .catch((error) => error);

export const createProduct = (product: Product) => async (
  dispatch: Dispatch
): Promise<CreateProductAction | RequestFailure> => {
  dispatch(requestStarted(CREATE_PRODUCT));

  const res = await axiosClient
    .post(PATH_CREATE_PRODUCT, product, {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CREATE_PRODUCT));
    return dispatch(createProductAction(parseProduct(res.data)));
  }

  return dispatch(
    requestFailure(CREATE_PRODUCT, {
      title: createProductErrorTitle,
      content: createProductErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const updateProduct = (product: Product) => async (
  dispatch: Dispatch
): Promise<UpdateProductAction | RequestFailure> => {
  dispatch(requestStarted(UPDATE_PRODUCT));

  const res = await axiosClient
    .put(PATH_UPDATE_PRODUCT, product, {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_PRODUCT));
    return dispatch(updateProductAction(parseProduct(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_PRODUCT, {
      title: updateProductErrorTitle,
      content: updateProductErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteProduct = (product: Product) => async (
  dispatch: Dispatch
): Promise<DeleteProductAction | RequestFailure> => {
  dispatch(requestStarted(DELETE_PRODUCT));

  const res = await axiosClient
    .delete(PATH_DELETE_PRODUCT(product.id), {
      validateStatus: (status: number) => status < 300
    })
    .catch((error) => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_PRODUCT));
    return dispatch(deleteProductAction(product));
  }

  return dispatch(
    requestFailure(DELETE_PRODUCT, {
      title: deleteProductErrorTitle,
      content: deleteProductErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};
