export const fetchClientLocationErrorTitle = 'Fehler bei Anfrage';
export const fetchClientLocationErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Filialen ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const fetchClientLocationsErrorTitle = 'Fehler bei Anfrage';
export const fetchClientLocationsErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Filiale ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const createClientLocationErrorTitle = 'Fehler bei Anfrage';
export const createClientLocationErrorContent = (errorCode: number): string =>
  `Beim Erstelen der Filiale ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const updateClientLocationErrorTitle = 'Fehler bei Anfrage';
export const updateClientLocationErrorContent = (errorCode: number): string =>
  `Beim Aktualisieren der Filiale ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const deleteClientLocationErrorTitle = 'Fehler bei Anfrage';
export const deleteClientLocationErrorContent = (errorCode: number): string =>
  `Beim Löschen der Filiale ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const fetchClientErrorTitle = 'Fehler bei Anfrage';
export const fetchClientErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Kunden ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const fetchClientsErrorTitle = 'Fehler bei Anfrage';
export const fetchClientsErrorContent = (errorCode: number): string =>
  `Beim Anfragen des Kunden ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const createClientErrorTitle = 'Fehler bei Anfrage';
export const createClientErrorContent = (errorCode: number): string =>
  `Beim Erstellen de Kunden ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const updateClientErrorTitle = 'Fehler bei Anfrage';
export const updateClientErrorContent = (errorCode: number): string =>
  `Beim Aktualisieren des Kunden ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const deleteClientErrorTitle = 'Fehler bei Anfrage';
export const deleteClientErrorContent = (errorCode: number): string =>
  `Beim Löschen des Kunden ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const fetchClientModulsErrorTitle = 'Fehler bei Anfrage';
export const fetchClientModulsErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Kundenmodule ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const fetchModulsErrorTitle = 'Fehler bei Anfrage';
export const fetchModulsErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Module ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const updateClienModuleErrorTitle = 'Fehler bei Anfrage';
export const updateClienModuleErrorContent = (errorCode: number): string =>
  `Beim Aktualisieren des Kundenmoduls ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;

export const fetchPrintTemplatesErrorTitle = 'Fehler bei Anfrage';
export const fetchPrintTemplatesErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der verfügbaren Layoutvorlagen ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;

export const fetchAllClientOfferOrderErrorTitle = 'Fehler bei Anfrage';
export const fetchAllClientOfferOrderErrorContent = (errorCode: number, offer: boolean): string =>
  `Beim Anfragen der Liste der ${
    offer ? 'Angebote' : 'Bestellungen'
  } ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const fetchClientOfferOrderErrorTitle = 'Fehler bei Anfrage';
export const fetchClientOfferOrderContent = (errorCode: number, offer: boolean): string =>
  `Beim Anfragen de${
    offer ? 's Angebots' : 'r Bestellung'
  } ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const fetchOfferSheetTemplatesErrorTitle = 'Fehler bei Anfrage';
export const fetchOfferSheetTemplatesErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Liste der Angebotsvorlagen ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;

export const fetchProductErrorTitle = 'Fehler bei Anfrage';
export const fetchProductErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Produkte ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const fetchProductsErrorTitle = 'Fehler bei Anfrage';
export const fetchProductsErrorContent = (errorCode: number): string =>
  `Beim Anfragen des Produkts ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const createProductErrorTitle = 'Fehler bei Anfrage';
export const createProductErrorContent = (errorCode: number): string =>
  `Beim Erstellen des Produkts ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const updateProductErrorTitle = 'Fehler bei Anfrage';
export const updateProductErrorContent = (errorCode: number): string =>
  `Beim Akutalisieren des Produkts ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const deleteProductErrorTitle = 'Fehler bei Anfrage';
export const deleteProductErrorContent = (errorCode: number): string =>
  `Beim Löschen des Produkts ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const loginErrorTitle = 'Fehler beim Anmelden';
export const loginErrorContent = (errorCode: number): string =>
  `Beim Anmelden ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const fetchUserErrorTitle = 'Fehler bei Anfrage';
export const fetchUserErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Benuter ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const fetchUsersErrorTitle = 'Fehler bei Anfrage';
export const fetchUsersErrorContent = (errorCode: number): string =>
  `Beim Anfragen des Benutzers ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const createUserErrorTitle = 'Fehler bei Anfrage';
export const createUserErrorContent = (errorCode: number): string =>
  `Beim Erstellen des Benutzers ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const updateUserErrorTitle = 'Fehler bei Anfrage';
export const updateUserErrorContent = (errorCode: number): string =>
  `Beim Akutalisieren des Benutzers ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const deleteUserErrorTitle = 'Fehler bei Anfrage';
export const deleteUserErrorContent = (errorCode: number): string =>
  `Beim Löschen des Benutzers ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const inviteUserErrorTitle = 'Fehler bei Anfrage';
export const inviteUserErrorContent = (errorCode: number): string =>
  `Beim Einladen des Benutzers ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const changePasswordUserErrorTitle = 'Fehler bei Anfrage';
export const changePasswordUserErrorContent = (errorCode: number): string =>
  `Beim Setzen des Passworts ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const fetchLayersErrorTitle = 'Fehler bei Anfrage';
export const fetchLayersErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Gebiete ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const fetchLayerErrorTitle = 'Fehler bei Anfrage';
export const fetchLayerErrorContent = (errorCode: number): string =>
  `Beim Anfragen des Gebiets ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const createLayerErrorTitle = 'Fehler bei Anfrage';
export const createLayerErrorContent = (errorCode: number): string =>
  `Beim Erstellen des Gebiets ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const updateLayerErrorTitle = 'Fehler bei Anfrage';
export const updateLayerErrorContent = (errorCode: number): string =>
  `Beim Akutalisieren des Gebiets ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const deleteLayerErrorTitle = 'Fehler bei Anfrage';
export const deleteLayerErrorContent = (errorCode: number): string =>
  `Beim Löschen des Gebiets ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const fetchClientUsersErrorTitle = 'Fehler bei Anfrage';
export const fetchClientUsersErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Benutzer ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const addClientUserErrorTitle = 'Fehler bei Anfrage';
export const addClientUserErrorContent = (errorCode: number): string =>
  `Beim Hinzufügen des Benutzers ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const deleteClientUserErrorTitle = 'Fehler bei Anfrage';
export const deleteClientUserErrorContent = (errorCode: number): string =>
  `Beim Entfernen des Benutzers ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const fetchClientLayersErrorTitle = 'Fehler bei Anfrage';
export const fetchClientLayersErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Gebiete ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const addClientLayerErrorTitle = 'Fehler bei Anfrage';
export const addClientLayerErrorContent = (errorCode: number): string =>
  `Beim Hinzufügen des Gebiets ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const deleteClientLayerErrorTitle = 'Fehler bei Anfrage';
export const deleteClientLayerErrorContent = (errorCode: number): string =>
  `Beim Entfernen des Gebiets ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const fetchClientProductsErrorTitle = 'Fehler bei Anfrage';
export const fetchClientProductsErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Produkte ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const addClientProductErrorTitle = 'Fehler bei Anfrage';
export const addClientProductErrorContent = (errorCode: number): string =>
  `Beim Hinzufügen des Produkts ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const deleteClientProductErrorTitle = 'Fehler bei Anfrage';
export const deleteClientProductErrorContent = (errorCode: number): string =>
  `Beim Entfernen des Produkts ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const fetchAllClientAdditionalOptionsErrorTitle = 'Fehler bei Anfrage';
export const fetchAllClientAdditionalOptionsErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der zusätzlichen Optionen ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const updateClientAdditionalOptionsErrorTitle = 'Fehler bei Anfrage';
export const updateClientAdditionalOptionsErrorContent = (errorCode: number): string =>
  `Beim Aktusliesieren der zusätzlichen Option ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;

export const fetchClientDistributionAppointmentErrorTitle = 'Fehler bei Anfrage';
export const fetchClientDistributionAppointmentErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Aktionen ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const fetchClientDistributionAppointmentsErrorTitle = 'Fehler bei Anfrage';
export const fetchClientsDistributionAppointmentErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Kunden ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const createClientDistributionAppointmentErrorTitle = 'Fehler bei Anfrage';
export const createClientDistributionAppointmentErrorContent = (errorCode: number): string =>
  `Beim Erstellen der Aktion ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const updateClientDistributionAppointmentErrorTitle = 'Fehler bei Anfrage';
export const updateClientDistributionAppointmentErrorContent = (errorCode: number): string =>
  `Beim Aktualisieren der Aktion ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const deleteClientDistributionAppointmentErrorTitle = 'Fehler bei Anfrage';
export const deleteClientDistributionAppointmentErrorContent = (errorCode: number): string =>
  `Beim Löschen der Aktion ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;

export const fetchAllPrintLayoutsErrorTitle = 'Fehler bei Anfrage';
export const fetchAllPrintLayoutsErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Liste der Layoutvorlagen ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const updateClientPrintLayoutErrorTitle = 'Fehler bei Anfrage';
export const updateClientPrintLayoutErrorContent = (errorCode: number): string =>
  `Beim Aktualiseren der Layoutvorlage ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const fetchAllClientPrintLayoutsErrorTitle = 'Fehler bei Anfrage';
export const fetchAllClientPrintLayoutsErrorContent = (errorCode: number): string =>
  `Beim Anfragen der Aktionen ist ein Fehler aufgetreten. (Fehlercode: ${errorCode || '404'})`;
export const addClientPrintLayoutErrorTitle = 'Fehler bei Anfrage';
export const addClientPrintLayoutErrorContent = (errorCode: number): string =>
  `Beim Hinzufügen der Layoutvorlage ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
export const removeClientPrintLayoutErrorTitle = 'Fehler bei Anfrage';
export const removeClientPrintLayoutErrorContent = (errorCode: number): string =>
  `Beim Entfernen der Layoutvorlage ist ein Fehler aufgetreten. (Fehlercode: ${
    errorCode || '404'
  })`;
