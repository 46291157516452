import {
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FETCH_USERS,
  FETCH_USER,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  SELECT_USER,
  CHANGE_PASSWORD_USER,
  INVITE_USER
} from '../constants/actionNames/user';

import { User } from '../@types/Model/User.d';
import {
  LoginStartedAction,
  LoginSuccessAction,
  LoginFailureAction,
  LogoutStartedAction,
  LogoutSuccessAction,
  LogoutFailureAction,
  FetchUsersAction,
  FetchUserAction,
  CreateUserAction,
  UpdateUserAction,
  DeleteUserAction,
  SelectUser,
  InviteUserAction,
  ChangePasswordUserAction
} from '../@types/Actions/User.d';

export const loginStartedAction = (payload: string): LoginStartedAction => ({
  type: LOGIN_STARTED,
  payload
});
export const loginSuccessAction = (payload: string, user: User): LoginSuccessAction => ({
  type: LOGIN_SUCCESS,
  payload,
  user
});
export const loginFailureAction = (payload: string): LoginFailureAction => ({
  type: LOGIN_FAILURE,
  payload
});

export const logoutStartedAction = (payload: string): LogoutStartedAction => ({
  type: LOGOUT_STARTED,
  payload
});
export const logoutSuccessAction = (payload: string): LogoutSuccessAction => ({
  type: LOGOUT_SUCCESS,
  payload
});
export const logoutFailureAction = (payload: string): LogoutFailureAction => ({
  type: LOGOUT_FAILURE,
  payload
});

export const fetchUsersAction = (users: User[]): FetchUsersAction => ({
  type: FETCH_USERS,
  users
});

export const fetchUserAction = (user: User): FetchUserAction => ({
  type: FETCH_USER,
  user
});

export const createUserAction = (user: User): CreateUserAction => ({
  type: CREATE_USER,
  user
});

export const updateUserAction = (user: User): UpdateUserAction => ({
  type: UPDATE_USER,
  user
});

export const deleteUserAction = (user: User): DeleteUserAction => ({
  type: DELETE_USER,
  user
});

export const selectUser = (user: User): SelectUser => ({
  type: SELECT_USER,
  user
});

export const inviteUserAction = (payload: string): InviteUserAction => ({
  type: INVITE_USER,
  payload
});

export const changeUserUserAction = (payload: string): ChangePasswordUserAction => ({
  type: CHANGE_PASSWORD_USER,
  payload
});
