import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import UserDetailsUserForm from './UserDetailsUserForm';

import {
  requestStarted,
  requestSuccess,
  requestFailure,
  showConfirmationModal,
  showChangePasswordModal
} from '../../../actions/uiActions';

import { parseUser } from '../../../util/responseUtil/userResponseUtil';
import {
  getUser,
  createUser,
  updateUser,
  deleteUser,
  sendUserInvite
} from '../../../util/api/userApi';

import {
  BUTTON_APPLY,
  BUTTON_CREATE,
  BUTTON_DELETE,
  DELETE_CONFIRMATION_MODAL_TITLE,
  DELETE_CONFIRMATION_MODAL_CONTENT,
  BUTTON_INVITE,
  BUTTON_SET_PASS
} from '../../../constants/labels';

import { fetchUserErrorTitle, fetchUserErrorContent } from '../../../constants/errorMessages';
import { FETCH_USER } from '../../../constants/actionNames/user';

import { UsersDetailsUserProps, UsersDetailsUserState } from '../../../@types/Users.d';
import { GlobalState } from '../../../@types/State.d';
import { Salutation, User } from '../../../@types/Model/User.d';
import { ErrorMessage, ConfirmationMessage } from '../../../@types/Model/Message.d';
import { UserAction } from '../../../@types/Actions/User.d';
import { UIAction } from '../../../@types/Actions/UI.d';

class UsersDetailsUser extends React.Component<UsersDetailsUserProps, UsersDetailsUserState> {
  constructor(props: UsersDetailsUserProps) {
    super(props);

    const { user } = this.props;

    this.state = {
      validated: false,

      email: user?.email ?? '',
      prename: user?.prename ?? '',
      lastname: user?.lastname ?? '',
      salutation: user?.salutation ?? 'Herr',
      internal: user?.internal ?? false,
      admin: user?.admin ?? false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onClickChangePassword = this.onClickChangePassword.bind(this);

    this.changeEmail = this.changeEmail.bind(this);
    this.changePrename = this.changePrename.bind(this);
    this.changeLastname = this.changeLastname.bind(this);
    this.changeSalutation = this.changeSalutation.bind(this);
    this.changeInternal = this.changeInternal.bind(this);
    this.changeAdmin = this.changeAdmin.bind(this);

    this.fetchUser = this.fetchUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.deleteUserCallback = this.deleteUserCallback.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
  }

  componentDidMount(): void {
    const { user } = this.props;

    if (user) this.fetchUser(user?.uuid);
  }

  componentDidUpdate(prevProps: UsersDetailsUserProps): void {
    const { user } = this.props;
    if (prevProps.user?.id !== user?.id) {
      // eslint-disable-next-line
      this.setState({
        validated: false,
        id: user?.id,
        email: user?.email ?? '',
        prename: user?.prename ?? '',
        lastname: user?.lastname ?? '',
        salutation: user?.salutation ?? 'Herr',
        internal: user?.internal ?? false,
        admin: user?.admin ?? false
      });

      if (user) this.fetchUser(user.uuid);
    }
  }

  onClickChangePassword(event: React.MouseEvent): void {
    const { changePasswordModalShow } = this.props;

    event.preventDefault();
    event.stopPropagation();

    changePasswordModalShow('show');
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        const { user, userUpdate, userCreate } = this.props;
        const { id, email, prename, lastname, salutation, internal, admin } = this.state;

        const userNew = { id, email, prename, lastname, salutation, internal, admin } as User;

        if (user) {
          userUpdate(userNew);
        } else {
          userCreate(userNew);
        }
      }
    });
  }

  deleteUser(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();

    const { showConfirmation } = this.props;

    showConfirmation({
      title: DELETE_CONFIRMATION_MODAL_TITLE,
      content: DELETE_CONFIRMATION_MODAL_CONTENT,
      confirmationCallback: this.deleteUserCallback
    });
  }

  deleteUserCallback(): void {
    const { userDelete, user } = this.props;

    userDelete(user);
  }

  async fetchUser(userUUId: string | undefined): Promise<void> {
    if (!userUUId) return;

    const { reqStarted, reqSuccess, reqFailure } = this.props;
    reqStarted(FETCH_USER);
    const res = await getUser(userUUId);

    if (res.status < 300) {
      const user = parseUser(res.data);
      this.setState(
        {
          validated: false,
          id: user?.id,
          email: user?.email ?? '',
          prename: user?.prename ?? '',
          lastname: user?.lastname ?? '',
          salutation: user?.salutation ?? '',
          internal: user?.internal ?? false,
          admin: user?.admin ?? false
        },
        () => reqSuccess(FETCH_USER)
      );
      return;
    }

    reqFailure(FETCH_USER, {
      title: fetchUserErrorTitle,
      content: fetchUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    });
  }

  changeEmail(email: string): void {
    this.setState({ email });
  }

  changePrename(prename: string): void {
    this.setState({ prename });
  }

  changeLastname(lastname: string): void {
    this.setState({ lastname });
  }

  changeSalutation(salutation: Salutation): void {
    this.setState({ salutation });
  }

  changeInternal(internal: boolean): void {
    this.setState({ internal });
  }

  changeAdmin(admin: boolean): void {
    this.setState({ admin });
  }

  inviteUser(): void {
    const { userInvite, user } = this.props;

    userInvite(user);
  }

  render(): JSX.Element {
    const { validated, email, prename, lastname, salutation, internal, admin } = this.state;
    const { user } = this.props;

    return (
      <Form
        id="user-detail-form"
        className="user-detail-form"
        onSubmit={this.onSubmit}
        noValidate
        validated={validated}
      >
        <UserDetailsUserForm
          email={email}
          prename={prename}
          lastname={lastname}
          salutation={salutation}
          internal={internal}
          admin={admin}
          changeEmail={this.changeEmail}
          changePrename={this.changePrename}
          changeLastname={this.changeLastname}
          changeSalutation={this.changeSalutation}
          changeAdmin={this.changeAdmin}
          changeInternal={this.changeInternal}
        />
        <Row className="no-gutters">
          <Col sm={12} xl={4}>
            <Row className="no-gutters">
              <Col sm={4} className="p-1">
                <Button type="submit" variant="success" block>
                  {user ? BUTTON_APPLY : BUTTON_CREATE}
                </Button>
              </Col>
              {user && (
                <>
                  <Col sm={4} className="p-1">
                    <Button variant="danger" block onClick={this.deleteUser}>
                      {BUTTON_DELETE}
                    </Button>
                  </Col>
                  <Col sm={4} className="p-1">
                    <Button block onClick={this.inviteUser}>
                      {BUTTON_INVITE}
                    </Button>
                  </Col>
                </>
              )}
            </Row>
            {user && (
              <Row className="no-gutters">
                <Col sm={4} className="p-1">
                  <Button variant="primary" onClick={this.onClickChangePassword} block>
                    {BUTTON_SET_PASS}
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: GlobalState): Pick<UsersDetailsUserProps, 'user'> => ({
  user: state.entities.users.selectedItem
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, UIAction | UserAction>
): Pick<
  UsersDetailsUserProps,
  | 'reqFailure'
  | 'reqStarted'
  | 'reqSuccess'
  | 'userUpdate'
  | 'userCreate'
  | 'userDelete'
  | 'showConfirmation'
  | 'userInvite'
  | 'changePasswordModalShow'
> => ({
  reqStarted: (payload: string) => dispatch(requestStarted(payload)),
  reqSuccess: (payload: string) => dispatch(requestSuccess(payload)),
  reqFailure: (payload: string, errorMessage: ErrorMessage) =>
    dispatch(requestFailure(payload, errorMessage)),
  userUpdate: (user: User) => dispatch(updateUser(user)),
  userCreate: (user: User) => dispatch(createUser(user)),
  userDelete: (user: User) => dispatch(deleteUser(user)),
  showConfirmation: (confirmationMessage: ConfirmationMessage) =>
    dispatch(showConfirmationModal(confirmationMessage)),
  userInvite: (user: User) => dispatch(sendUserInvite(user)),
  changePasswordModalShow: (payload: string) => dispatch(showChangePasswordModal(payload))
});

const UsersDetailsUserContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersDetailsUser)
);
export default UsersDetailsUserContainer;
